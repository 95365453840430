import React from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardSubtitle from 'components/BasicCard/BasicCardSubtitle/BasicCardSubtitle';
import BasicCardSecondaryContent from 'components/BasicCard/BasicCardContent/BasicCardSecondaryContent/BasicCardSecondaryContent';
import BasicCardCommon from 'components/BasicCard/BasicCardCommon/BasicCardCommon';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useDisclosure from 'shared/hooks/useDisclosure';
import { useDeleteGroup } from 'services/groups/hooks';
import AddNewGroupModal from 'components/AddNewGroupModal/AddNewGroupModal';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import useMixinStyles from 'styles/mixins';
import clsx from 'clsx';
import { makeStyles, Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import BasicCardFloatingAction from 'components/BasicCard/BasicCardFloatingAction/BasicCardFloatingAction';
import Icon from 'components/Icon/Icon';
import { useGroupsActiveTab } from '../../GroupsTabActive/GroupsTabActiveProvider/GroupsTabActiveProvider';

const useStyles = makeStyles((theme) => ({
  cardContainerHover: {
    '&:hover': {
      backgroundColor: ({ isActive }) => !isActive && theme.palette.grey[100],
      transition: 'background-color 0.2s, border-color 0.2s',
    },
  },
  cardContainerActive: {
    borderColor: theme.palette.primary[500],
  },
  cardActive: {
    color: theme.palette.primary[500],
  },
  deleteButton: {
    color: '#7C8DA0',
    '&:hover': {
      color: '#EB4D4B',
    },
  },
}));

export default function GroupListItem({ data }) {
  const { groupsTabActive, setGroupsTabActive, setGroupsTabActiveName } = useGroupsActiveTab();
  const isActive = groupsTabActive === data.id;
  const classes = useStyles({ isActive });
  const mixinStyles = useMixinStyles();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure();
  const [mutate, { isLoading }] = useDeleteGroup({
    onMutate: () => {
      setGroupsTabActive(null);
      onClose();
    },
  });

  const handleRemoveGroup = () => {
    mutate({
      clientGroupRuleId: data.id,
    });
  };

  const handleGroupListItemClick = () => {
    setGroupsTabActive(data.id);
    setGroupsTabActiveName(data.groupName);
  };

  const handleOnDeleteClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <BasicCard
        className={clsx(
          mixinStyles.clickable,
          classes.cardContainerHover,
          isActive && classes.cardContainerActive,
        )}
        onClick={handleGroupListItemClick}
        noPadding
      >
        <BasicCardWrapper>
          <BasicCardContent>
            <Box
              alignSelf="stretch"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                marginBottom="0.5rem"
                borderRadius="3px"
                bgcolor={isActive ? 'rgba(0, 137, 73, 0.1)' : 'common.backgroundLight'}
                padding="3px"
                height="1rem"
              >
                <Text
                  fontSize="0.5625rem"
                  color={isActive ? 'primary.500' : 'common.label'}
                  lineHeight="0.625rem"
                  letterSpacing="0.20px"
                  fontWeight={500}
                >
                  {data.isStatic ? 'STATIC' : 'DYNAMIC'}
                </Text>
              </Box>
              <Box
                onClick={handleOnDeleteClick}
                role="button"
                display="flex"
                className={clsx(classes.deleteButton, mixinStyles.clickable)}
              >
                <Icon name="deleteAlt" />
              </Box>
            </Box>
            <BasicCardSubtitle className={isActive && classes.cardActive}>
              {data.groupName}
            </BasicCardSubtitle>
          </BasicCardContent>
        </BasicCardWrapper>
      </BasicCard>
      <ConfirmationDialog
        title="Are you sure you want to delete this group?"
        description="Note: This group will also be deleted from all the associated rules"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleRemoveGroup}
        loading={isLoading}
      />
      {isEditOpen && (
        <AddNewGroupModal isOpen={isEditOpen} onClose={onEditClose} groupToEditId={data.id} />
      )}
    </>
  );
}
