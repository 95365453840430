import { Box, makeStyles } from '@material-ui/core';
import Text from 'components/Text/Text';
import React from 'react';
import useImagesMixins from 'styles/images-mixins';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  image: {
    transform: 'scale(1.3)',
  },
}));

export default function NotificationsPreviewItem({ title, description, image }) {
  const classes = useStyles();
  const imagesMixins = useImagesMixins();
  return (
    <Box flex={1} minWidth="100%">
      <Box padding="1.25rem 1.5rem 0">
        <Text
          fontSize="0.625rem"
          lineHeight="0.875rem"
          letterSpacing="0.95px"
          fontWeight={600}
          marginBottom="0.25rem"
          color="text.lightHeaderTitle"
        >
          {title}
        </Text>
        <Text
          color="text.lightHeaderDescription"
          fontSize="0.5625rem"
          lineHeight="0.875rem"
          letterSpacing="0.225px"
        >
          {description}
        </Text>
      </Box>
      <Box
        height="223px"
        width="100%"
        className={clsx(imagesMixins.objectFitContain, classes.image)}
        position="relative"
        top="3.5rem"
        src={image}
        component="img"
      />
    </Box>
  );
}
