import React from 'react';
import { CardActions, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardActions: {
    padding: '0.5rem 0',
    justifyContent: 'center',
    marginTop: 'auto',
  },
}));

export default function BasicCardActions({ children }) {
  const classes = useStyles();
  return <CardActions className={classes.cardActions}>{children}</CardActions>;
}
