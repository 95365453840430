import React from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import Text from 'components/Text/Text';
import { Box, useTheme } from '@material-ui/core';
import RulesSelectorItem from './RulesSelectorItem/RulesSelectorItem';

export default function RulesSelector({ title, icon, tabs }) {
  const { borders } = useTheme();
  return (
    <BasicCard noPadding>
      <Box
        borderBottom={borders.containers}
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="1.25rem 1rem"
      >
        <Box color="primary.500" marginRight="0.75rem" height="1.5rem" width="1.5rem">
          {icon}
        </Box>
        <Box>
          <Text letterSpacing="0.15px" fontWeight={600} fontSize="0.875rem" color="textPrimary">
            {title}
          </Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {tabs.map(tab => (
          <RulesSelectorItem key={tab.index} index={tab.index}>
            {tab.title}
          </RulesSelectorItem>
        ))}
      </Box>
    </BasicCard>
  );
}
