import React from 'react';
import BasicTable from 'components/BasicTable/BasicTable';
import TableActions from 'pages/Borrowers/TableActions/TableActions';
import BasicCard from 'components/BasicCard/BasicCard';
import { dateWithSlashes } from 'shared/utils';

const columns = [
  { id: 'loanNumber', label: 'Account Number', minWidth: 100 },
  { id: 'linked', label: 'Account Linked On', minWidth: 100 },
  { id: 'by', label: 'Account Linked By', minWidth: 100 },
  { id: 'borrower', label: 'Borrower' },
  { id: 'daysPastDue', label: 'Days Past Due', minWidth: 100 },
  { id: 'actions', label: 'Actions', minWidth: 100 },
];

function createData(loanNumber, linked, by, borrower, daysPastDue, actions) {
  return { loanNumber, linked, by, borrower, daysPastDue, actions };
}

export default function SignedUpTable({ data }) {
  const rows = data.map((data) => {
    const array = createData(
      data.loan.loanNo,
      dateWithSlashes(data.createdAt.date),
      data.linkAttempt.installAccount.instlFirstName
        ? `${data.linkAttempt.installAccount.instlFirstName} ${data.linkAttempt.installAccount.instlLastName}`
        : 'N/A',
      data.loan.borrower.firstName
        ? `${data.loan.borrower.firstName} ${data.loan.borrower.lastName}`
        : 'N/A',
      data.loan.daysPastDue ? data.loan.daysPastDue : '-',
      <TableActions data={data} />,
    );
    return array;
  });

  return (
    <BasicCard noPadding>
      <BasicTable columns={columns} rows={rows} />
    </BasicCard>
  );
}
