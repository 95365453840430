import React, { useEffect, useMemo } from 'react';
import { Grid, Box, MenuItem } from '@material-ui/core';
import MoneyFormatField from 'components/BasicInputs/BasicTextField/MoneyFormatField/MoneyFormatField';
import { useField, useFormikContext } from 'formik';
import Text from 'components/Text/Text';
import BasicCheckbox from 'components/BasicInputs/BasicCheckbox/BasicCheckbox';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';
import { useClientDetailsContext } from 'services/client/context';
import {
  SCHEDULED_PAYMENT_TYPES,
  PAYMENT_TYPES_TO_PLATFORM_DATA_FLAGS,
} from 'shared/constants/payments';
import { useLoanDetails, useBorrowerDetails } from 'services/loans/hooks';

export default function ConvenienceFee({ name = 'transaction', isSchedule, ...rest }) {
  const { values, setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const { data: borrowerDetails } = useBorrowerDetails();
  const { data: loanDetails } = useLoanDetails(borrowerDetails.loan.loanNo);
  const {
    convFeeWaivedReasons,
    waiveConvFee,
    colConvFeeOneSch,
    colConvFeeRecuSch,
    waiveConvFeeReasons,
    customConvFees,
  } = useClientDetailsContext();

  const shouldHaveConvenienceFee = useMemo(() => {
    if (values.scheFreq && isSchedule) {
      const selectedKey =
        values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME
          ? SCHEDULED_PAYMENT_TYPES.ONE_TIME
          : SCHEDULED_PAYMENT_TYPES.RECURRING;

      return loanDetails?.result?.data?.platformData[0][
        PAYMENT_TYPES_TO_PLATFORM_DATA_FLAGS[selectedKey]
      ];
    }
    return loanDetails?.result?.data?.platformData[0]['conv_fee_one_time'];
  }, [values.scheFreq, isSchedule]);

  useEffect(() => {
    if (values.paymentMethodData && shouldHaveConvenienceFee && !values.waiveConvenienceFee) {
      helpers.setValue(values.paymentMethodData.transactionFeeFinal);
    } else {
      helpers.setValue('0');
    }
  }, [values.paymentMethodData, shouldHaveConvenienceFee, values.waiveConvenienceFee]);

  useEffect(() => {
    if (shouldHaveConvenienceFee) {
      if (values.waiveConvenienceFee || values.refund) {
        helpers.setValue('0');
      } else {
        helpers.setValue(values?.paymentMethodData?.transactionFeeFinal);
      }
    }
  }, [values.waiveConvenienceFee, values.refund, shouldHaveConvenienceFee]);

  useEffect(() => {
    if (values.scheFreq) {
      if (
        (values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME && !colConvFeeOneSch) ||
        (values.scheFreq !== SCHEDULED_PAYMENT_TYPES.ONE_TIME && !colConvFeeRecuSch)
      ) {
        setFieldValue('waiveConvenienceFee', false);
      }
    }
  }, [values.scheFreq]);

  return (
    <Grid item xs={5}>
      <Text fontSize="0.75rem" lineHeight="0.875rem" letterSpacing="-0.12px" marginBottom="0.5rem">
        Convenience Fee
      </Text>
      {values.waiveConvenienceFee ? (
        <BasicSelectInput
          disabled={!waiveConvFeeReasons}
          noShadow
          placeholder="Select Reason"
          name="convFeeWaivedReasonId"
        >
          {convFeeWaivedReasons.map(convFeeWaiveReason => (
            <MenuItem key={convFeeWaiveReason.id} value={convFeeWaiveReason.id}>
              {convFeeWaiveReason.reasonText}
            </MenuItem>
          ))}
        </BasicSelectInput>
      ) : (
        <MoneyFormatField
          name={name}
          disabled={!customConvFees}
          noShadow
          placeholder="$0.00"
          {...rest}
        />
      )}
      {
        (!!waiveConvFee &&!isSchedule ||
          (values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME && colConvFeeOneSch) ||
          (values.scheFreq !== SCHEDULED_PAYMENT_TYPES.ONE_TIME && colConvFeeRecuSch)) && (
          <Box>
            <BasicCheckbox name="waiveConvenienceFee" label="Waive Convenience Fee" />
          </Box>
        )}
    </Grid>
  );
}
