import React from 'react';
import ListRenderer from 'components/ListRenderer/ListRenderer';
import { useGroups } from 'services/groups/hooks';
import { Box, Grid, makeStyles } from '@material-ui/core';
import EmptyState from 'components/EmptyState/EmptyState';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import useMixinStyles from 'styles/mixins';
import GroupsListItem from './GroupsListItem/GroupsListItem';

const useStyles = makeStyles(() => ({
  listItem: {
    marginBottom: '1.5rem',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

export default function GroupsList() {
  const mixinClasses = useMixinStyles();
  const classes = useStyles();
  const { data, isLoading } = useGroups();
  return (
    <Box className={mixinClasses.scrollBar} marginRight="-0.75rem" overflow="scroll">
      {data && data?.status !== API_RESPONSE_STATUS.FAILED && (
        <Box paddingRight="0.5rem">
          {data.result.data.map(item => (
            <Box className={classes.listItem} key={item.id} marginBottom="1.5rem">
              <GroupsListItem data={item} />
            </Box>
          ))}
        </Box>
      )}
      {data && (data?.status === API_RESPONSE_STATUS.FAILED || !data.result.data.length) && (
        <EmptyState variant="groups" />
      )}
      {!data && <SpinnerWrapped size={64} />}
    </Box>
  );
}
