import React from 'react';

export default function FileIcon({ width = '12px', height = '12px' }) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.772 1h4.546c.25 0 .454.204.454.455v.454h.44c.726 0 1.32.568 1.36 1.284l.003.08v6.363c0 .727-.568 1.32-1.283 1.362l-.08.002H2.863c-.727 0-1.32-.568-1.362-1.284l-.002-.08V3.273c0-.726.568-1.32 1.283-1.362l.08-.002h.455v-.454c0-.251.203-.455.454-.455zm.455 1.384a.467.467 0 0 0 0-.04v-.435h3.636v.91H4.227v-.435zm-.909.434h-.454a.455.455 0 0 0-.452.402l-.003.053v6.363c0 .233.175.426.402.452l.053.003h6.348a.455.455 0 0 0 .45-.402l.004-.053V3.273a.455.455 0 0 0-.402-.452l-.053-.003h-.439v.455a.455.455 0 0 1-.454.454H3.773a.455.455 0 0 1-.455-.454v-.455zm4.545 2.727a.455.455 0 0 1 .053.906l-.053.004H4.227a.455.455 0 0 1-.053-.906l.053-.004h3.636zM6.5 7.818a.455.455 0 0 0-.455-.454H4.227l-.053.003a.455.455 0 0 0 .053.906h1.818l.053-.003a.455.455 0 0 0 .402-.452z"
        fill="#F5F6F8"
      />
    </svg>
  );
}
