import React from 'react';
import NumberFormat from 'react-number-format';

export default function MoneyFormat({ value, ...rest }) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      prefix="$"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      defaultValue={0}
      min="0.00"
      {...rest}
    />
  );
}
