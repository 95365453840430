import React from 'react';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import RulesFormSectionError from './RulesFormSectionError/RulesFormSectionError';

export default function RulesFormSection({ children, title, inputName }) {
  return (
    <Box marginBottom="1.5rem">
      <Box marginBottom="1rem">
        <Text fontSize="0.875rem" color="textPrimary" fontWeight={600} letterSpacing="-0.12px">
          {title}
        </Text>
        {inputName && <RulesFormSectionError inputName={inputName} />}
      </Box>
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  );
}
