import React from 'react';

export default function ClockwiseIcon({ height, width }) {
  return (
    <svg
      height={height || 24}
      viewBox="0 0 512 512"
      width={width || 24}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M497.547 249.887a14.983 14.983 0 0 0-15.54 14.441c-2.093 57.188-25.554 110.985-66.062 151.477C373.242 458.492 316.47 482 256.082 482c-60.39 0-117.168-23.508-159.867-66.195C53.515 373.12 30 316.367 30 256.004c0-60.367 23.516-117.121 66.215-159.805 42.703-42.687 99.476-66.195 159.867-66.195 46.961 0 91.738 14.215 129.41 40.613l-33.355.02c-8.285.008-14.996 6.726-14.992 15.011.007 8.282 6.722 14.989 15 14.989h.011l74.324-.047c3.864.02 7.73-1.445 10.68-4.395 3.79-3.789 5.125-9.105 4.012-13.968l-15.004-70.352c-1.727-8.102-9.695-13.266-17.797-11.543-8.101 1.727-13.27 9.695-11.543 17.797l5.965 27.973C360.093 16.14 309.328.004 256.082.004 187.68.004 123.375 26.629 75.008 74.98 26.637 123.332 0 187.621 0 256c0 68.383 26.637 132.672 75.008 181.023C123.375 485.371 187.68 512 256.082 512c68.398 0 132.707-26.629 181.074-74.977 45.883-45.87 72.461-106.808 74.832-171.597.305-8.278-6.16-15.235-14.441-15.54zm0 0" />
      <path d="M104.758 256c0 83.371 67.847 151.195 151.242 151.195S407.238 339.371 407.238 256c0-83.367-67.843-151.191-151.238-151.191S104.758 172.633 104.758 256zm272.48 0c0 66.828-54.386 121.195-121.238 121.195S134.758 322.828 134.758 256c0-66.824 54.39-121.191 121.242-121.191S377.238 189.176 377.238 256zm0 0" />
      <path d="M241 188.21v67.794c0 8.281 6.715 15 15 15h39.145c8.285 0 15-6.719 15-15 0-8.285-6.715-15-15-15H271V188.21c0-8.281-6.715-15-15-15s-15 6.719-15 15zm0 0" />
    </svg>
  );
}
