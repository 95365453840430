import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardSecondaryContainer: {
    marginTop: 'auto',
    opacity: 0.5,
    alignSelf: 'stretch',
    backgroundColor: theme.palette.common.backgroundCardHighlight,
    borderRadius: '4px',
    padding: '0.375rem',
  },
  cardSecondaryTitle: {
    fontWeight: 500,
    fontSize: '0.6875rem',
    letterSpacing: '0.2px',
    lineHeight: 1,
    marginBottom: '0.25rem',
    textTransform: 'capitalize',
  },
  cardSecondarySubtitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 1,
  },
  cardSecondaryTitleSmall: {
    fontWeight: 500,
    fontSize: '0.6875rem',
    lineHeight: 1,
    marginRight: '0.25rem',
    textTransform: 'capitalize',
  },
  cardSecondarySubtitleSmall: {
    fontWeight: 600,
    fontSize: '0.6875rem',
    lineHeight: 1,
  },
}));

export default function BasicCardHighlight({ attribute, value, icon }) {
  const classes = useStyles();

  if (icon) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.cardSecondaryContainer}
      >
        <Box marginRight="0.5rem">{icon}</Box>
        <Typography className={classes.cardSecondaryTitleSmall} color="textPrimary">
          {attribute}
        </Typography>
        <Typography className={classes.cardSecondarySubtitleSmall} color="textPrimary">
          {value}
        </Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="space-between" className={classes.cardSecondaryContainer}>
      <Box
        padding="0.5rem"
        flex="1 1 0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography className={classes.cardSecondaryTitle} color="textPrimary">
          {attribute}
        </Typography>
        <Typography className={classes.cardSecondarySubtitle} color="textPrimary">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
