import { Box, makeStyles } from '@material-ui/core';
import BasicButton from 'components/BasicButton/BasicButton';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import React from 'react';
import { ENV, ENDPOINTS } from '../../../../constants';

const useStyles = makeStyles(() => ({
  button: {
    background: 'rgba(0, 137, 73, 0.1)',
    padding: '0.5rem 0.75rem',
    height: '3rem',
  },
}));

export default function BorrowersExportList() {
  const classes = useStyles();
  const handleDoc = () => {
    window.open(
      `${ENV.WEBHOST + ENDPOINTS.getExportBorrowers}?accessToken=${sessionStorage.getItem(
        'accessToken',
      )}&type=csv`,
    );
  };

  return (
    <BasicButton className={classes.button} onClick={handleDoc}>
      <FlexRow>
        <Box color="primary.500" marginRight="0.375rem">
          <Icon name="download" />
        </Box>
        <Text
          color="primary.500"
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="0.875rem"
          letterSpacing="0.015em"
        >
          Export Borrower List
        </Text>
      </FlexRow>
    </BasicButton>
  );
}
