import { Box } from '@material-ui/core';
import React from 'react';
import useMixinStyles from 'styles/mixins';

export default function NotificationsPreviewCarouselButton({ selected, onClick }) {
  const mixinStyles = useMixinStyles();
  return (
    <Box
      className={mixinStyles.clickable}
      marginRight="0.25rem"
      height="8px"
      width="8px"
      border={`1px solid ${selected ? 'transparent' : 'rgba(255, 255, 255, 0.88)'}`}
      bgcolor={selected ? 'rgba(255, 255, 255, 0.88)' : 'transparent'}
      borderRadius="50%"
      onClick={onClick}
    />
  );
}
