import React, { useState, useContext, useMemo, createContext } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const BorrowersFilters = createContext();

const SORT_BY_KEYS_DECODE = {
  latest: '0',
  days_past_due: '1',
};

const SORT_BY_KEYS_ENCODE = {
  0: 'latest',
  1: 'days_past_due',
};

const SortByParam = {
  encode(value) {
    return SORT_BY_KEYS_ENCODE[value];
  },

  decode(strValue) {
    return SORT_BY_KEYS_DECODE[strValue];
  },
};

const BorrowersFiltersProvider = ({ children }) => {
  const [query, setQuery] = useQueryParams({
    query: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    sort_by: withDefault(SortByParam, '0'),
    date: withDefault(StringParam, ''),
  });

  const [isListOfCards, setIsListOfCards] = useState(true);

  const value = useMemo(
    () => ({
      currentPage: query.page,
      sortBy: query.sort_by,
      isListOfCards,
      setIsListOfCards,
      paymentDate: query.date,
      searchString: query.query,
      setQuery,
    }),
    [query, setQuery, isListOfCards],
  );

  return <BorrowersFilters.Provider value={value}>{children}</BorrowersFilters.Provider>;
};

export default BorrowersFiltersProvider;

export function useBorrowersFilters() {
  const context = useContext(BorrowersFilters);
  if (!context) {
    throw new Error(`useBorrowersFilters must be used within a BorrowersFiltersProvider`);
  }
  return context;
}
