import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMixinStyles from 'styles/mixins';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  modal: {
    maxHeight: 'calc(100vh - 1rem)',
  },
  dialogTitle: {
    padding: '1rem 1rem 0',
  },
}));

export default function BasicModal({ isOpen, onClose, children, className }) {
  const classes = useStyles();
  const mixinClasses = useMixinStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ className: clsx(mixinClasses.full, classes.modal, className) }}
    >
      {children}
    </Dialog>
  );
}
