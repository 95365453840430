import React from 'react';

export default function DeleteIcon({ width = '25px', height = '25px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="11.292" fill="#fff" stroke="#EB4D4B" strokeWidth="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.042 9.667c.39 0 .708.317.708.708v7.083a2.125 2.125 0 0 1-2.125 2.125h-4.25a2.125 2.125 0 0 1-2.125-2.125v-7.083c0-.391.317-.708.708-.708h7.084zm-.709 1.416H9.667v6.375c0 .392.317.709.708.709h4.25a.708.708 0 0 0 .708-.709v-6.375zm-4.958-4.958c0-.391.317-.708.708-.708h2.834c.39 0 .708.317.708.708v.708h2.833a.708.708 0 1 1 0 1.417H7.542a.708.708 0 1 1 0-1.417h2.833v-.708z"
        fill="#EB4D4B"
      />
    </svg>
  );
}
