import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Text from 'components/Text/Text';

export default function PaymentDetailsItem({ label, input }) {
  return (
    <Grid item xs={5}>
      <Text fontSize="0.75rem" lineHeight="0.875rem" letterSpacing="-0.12px" marginBottom="0.5rem">
        {label}
      </Text>
      {input}
    </Grid>
  );
}
