import React from 'react';

export default function BackIcon({ width, height }) {
  return (
    <svg
      width={width || 10}
      height={height || 16}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.205 1.205a1.124 1.124 0 1 1 1.59 1.59L3.591 8l5.204 5.205a1.124 1.124 0 1 1-1.59 1.59l-6-6a1.124 1.124 0 0 1 0-1.59l6-6z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
    </svg>
  );
}
