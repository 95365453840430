import React from 'react';
import { Box } from '@material-ui/core';
import Spinner from '../Spinner';

export default function SpinnerWrapped({ margin = '2rem', size = 80, ...spinnerProps }) {
  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center" padding={margin}>
      <Spinner size={size} {...spinnerProps} />
    </Box>
  );
}
