import { Box } from '@material-ui/core';
import Container from 'components/Container/Container';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import Form from 'components/Form/Form';
import PageHeader from 'components/PageHeader/PageHeader';
import Pagination from 'components/Pagination/Pagination';
import PaginationProvider from 'components/Pagination/PaginationProvider/PaginationProvider';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useClientDetailsContext } from 'services/client/context';
import TransactionsClearFilters from './TransactionsClearFilters/TransactionsClearFilters';
import TransactionsTable from './TransactionsTable/TransactionsTable';

const initialValues = {
  loanNo: null,
  transDate: null,
  amt: null,
  authId: null,
  orderId: null,
  firstName: null,
  lastName: null,
  lastFour: null,
  type: null,
  pmtType: null,
};

export default function Transactions() {
  const { showTransactionsPageInCap } = useClientDetailsContext();
  if (!showTransactionsPageInCap) return <Redirect to="/borrowers" />;
  return (
    <Container>
      <PaginationProvider>
        <Form initialValues={initialValues}>
          <FlexColumn>
            <FlexRow marginBottom="2rem">
              <PageHeader
                title="Transactions"
                description="Find any information using the filters above"
                marginBottom="0"
                marginRight="1rem"
              />
              <TransactionsClearFilters />
            </FlexRow>
            <TransactionsTable />
          </FlexColumn>
        </Form>
      </PaginationProvider>
    </Container>
  );
}
