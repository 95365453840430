import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import BasicInput from 'components/BasicInputs/BasicInput';
import { useSearchBar } from './SearchBarProvider/SearchBarProvider';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    position: 'relative',
    maxWidth: '24.375rem',
  },
  searchIcon: {
    zIndex: 1,
    position: 'absolute',
    left: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.common.borderInput,
    height: '1.2rem',
    width: '1.5rem',
  },
}));

export default function SearchBar({ placeholder, isLoading, ...rest }) {
  const classes = useStyles();
  const { handleSearchStringChange, searchString } = useSearchBar();

  return (
    <div className={classes.grow}>
      <BasicInput
        value={searchString}
        startIcon={<SearchIcon className={classes.searchIcon} />}
        placeholder={placeholder}
        onChange={handleSearchStringChange}
        loading={isLoading}
        {...rest}
      />
    </div>
  );
}
