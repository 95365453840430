import React from 'react';

export default function MobileIcon({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height || '24px'}
      width={width || '24px'}
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M271.484 436.645h-30.968c-13.091 0-23.742 10.651-23.742 23.742s10.651 23.742 23.742 23.742h30.968c13.091 0 23.742-10.651 23.742-23.742s-10.651-23.742-23.742-23.742zm0 26.839h-30.968c-1.707 0-3.097-1.389-3.097-3.097s1.389-3.097 3.097-3.097h30.968c1.707 0 3.097 1.389 3.097 3.097s-1.39 3.097-3.097 3.097z" />
      <path d="M361.548 0H150.452c-21.486 0-38.968 17.481-38.968 38.968v434.065c0 21.486 17.481 38.968 38.968 38.968h211.097c21.486 0 38.968-17.481 38.968-38.968V38.968C400.516 17.481 383.035 0 361.548 0zm18.323 473.032c0 10.104-8.219 18.323-18.323 18.323H150.452c-10.104 0-18.323-8.219-18.323-18.323v-42.581h247.742v42.581zm0-63.226h-10.839V143.484c0-5.7-4.621-10.323-10.323-10.323s-10.323 4.621-10.323 10.323v266.323H132.129V72.258h247.742v337.548zm0-358.193H132.129V38.968c0-10.104 8.219-18.323 18.323-18.323h211.097c10.104 0 18.323 8.219 18.323 18.323v12.645z" />
      <path d="M341.161 25.806h-2.065c-5.701 0-10.323 4.621-10.323 10.323s4.621 10.323 10.323 10.323h2.065c5.701 0 10.323-4.621 10.323-10.323s-4.622-10.323-10.323-10.323zM358.71 89.806c-5.701 0-10.323 4.621-10.323 10.323v7.226c0 5.701 4.621 10.323 10.323 10.323s10.323-4.621 10.323-10.323v-7.226c-.001-5.701-4.622-10.323-10.323-10.323z" />
    </svg>
  );
}
