import React, { useMemo } from 'react';
import BasicTable from 'components/BasicTable/BasicTable';
import { useScheduleList } from 'services/payments/hooks';
import { useParams } from 'react-router-dom';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import BasicChip from 'components/BasicChip/BasicChip';
import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import useMixinStyles from 'styles/mixins';
import { useClientDetailsContext } from 'services/client/context';
import EmptyState from 'components/EmptyState/EmptyState';
import { useBorrowerDetails } from 'services/loans/hooks';
import SpinnerBoxed from 'components/Spinner/SpinnerBoxed/SpinnerBoxed';
import ScheduleListItem from './ScheduleListItem/ScheduleListItem';
import {
  useWebPopSwitch,
  WEBPOP_COMPONENTS,
} from '../../WebPopSwitchProvider/WebPopSwitchProvider';
import { useScheduleListFilterContext } from './ScheduleListFilterProvider/ScheduleListFilterProvider';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import PERMISSIONS from 'shared/constants/permissions';

function createData(amount, frequency, method, date, status, actions) {
  return { amount, frequency, method, date, status, actions };
}

const STATUS_ID = {
  SCHEDULED: 0,
  SUCCESS: 1,
  FAILED: 2,
  DELETED: 3,
};

const STATUS = {
  [STATUS_ID.SCHEDULED]: 'Scheduled',
  [STATUS_ID.SUCCESS]: 'Success',
  [STATUS_ID.FAILED]: 'Failed',
  [STATUS_ID.DELETED]: 'Deleted',
};

const STATUS_FIlTERS_ID = {
  ALL: '',
  SCHEDULED: 0,
  SUCCESS: 1,
  FAILED: 2,
  DELETED: 3,
};

const STATUS_FILTERS = [
  {
    id: STATUS_FIlTERS_ID.ALL,
    label: 'All',
  },
  {
    id: STATUS_FIlTERS_ID.SCHEDULED,
    label: 'Scheduled',
  },
  {
    id: STATUS_FIlTERS_ID.SUCCESS,
    label: 'Success',
  },
  {
    id: STATUS_FIlTERS_ID.FAILED,
    label: 'Failed',
  },
  {
    id: STATUS_FIlTERS_ID.DELETED,
    label: 'Deleted',
  },
];

export default function ScheduleList() {
  const { canUserAccessTo } = useCurrentUser()
  const { data: borrowerDetails } = useBorrowerDetails();
  const mixinStyles = useMixinStyles();
  const { selectedStatusFilter, setSelectedStatusFilter } = useScheduleListFilterContext();
  const { isLoading, data } = useScheduleList(
    0,
    2000,
    borrowerDetails.loanId,
    selectedStatusFilter,
  );
  const { pushHistory, setActiveComponentInfo } = useWebPopSwitch();
  const { collectorEditSchedule } = useClientDetailsContext();

  const handleEditSchedulePayment = (clickedScheduledPaymentData) => {
    pushHistory(WEBPOP_COMPONENTS.EDIT_SCHEDULED_PAYMENT);
    setActiveComponentInfo(clickedScheduledPaymentData);
  };

  const columns = useMemo(() => {
    const array = [
      {
        id: 'amount',
        label: 'Amount',
        format: (value) => value.toFixed(2),
      },
      { id: 'frequency', label: 'Frequency' },
      { id: 'method', label: 'Method' },
      { id: 'date', label: 'Date' },
      { id: 'status', label: 'Status' },
    ];
    if (canUserAccessTo(PERMISSIONS.PAYMENTS.EDIT_SCHEDULES) || canUserAccessTo(PERMISSIONS.PAYMENTS.DELETE_SCHEDULES)) {
      array.push( { id: 'actions', label: 'Actions' })
    }
    return array;
  }, [canUserAccessTo]);

  const rows = data
    ? data.data.map((data) => {
        const array = createData(
          `$${data.pmtAmt.toFixed(2)}`,
          data.repeatingType,
          data.achMethodId
            ? `ACH *${data?.achMethod?.lastFour}`
            : `CC *${data.cardMethod.lastFour}`,
          data.nextExecutionDate.date.substring(0, 10),
          STATUS[data.status],
        );
        if (canUserAccessTo(PERMISSIONS.PAYMENTS.EDIT_SCHEDULES) || canUserAccessTo(PERMISSIONS.PAYMENTS.DELETE_SCHEDULES)) {
          array.actions = (Number(data.status) === STATUS_ID.SCHEDULED && (
            <Box display="flex" justifyContent="center" alignItems="center">
              {collectorEditSchedule && canUserAccessTo(PERMISSIONS.PAYMENTS.EDIT_SCHEDULES) && (
                <Box
                  onClick={() => handleEditSchedulePayment(data)}
                  className={mixinStyles.clickable}
                  marginRight="0.5rem"
                  display="flex"
                >
                  <Icon name="pen" height={18} width={18} />
                </Box>
              )}
              {canUserAccessTo(PERMISSIONS.PAYMENTS.DELETE_SCHEDULES) && <ScheduleListItem type={selectedStatusFilter} data={data} />}
            </Box>
          ))
        }
        return array;
      })
    : null;
  return (
    <>
      <Box
        width="100%"
        padding="1rem 1rem 0.25rem"
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          {STATUS_FILTERS.map((statusFilter) => (
            <BasicChip
              key={statusFilter.id}
              label={statusFilter.label}
              onClick={() => setSelectedStatusFilter(statusFilter.id)}
              color="primary"
              clickable={false}
              variant={selectedStatusFilter === statusFilter.id ? 'default' : 'outlined'}
            />
          ))}
        </Box>
      </Box>
      {isLoading ? (
        <SpinnerBoxed />
      ) : (
        <>
          {rows?.length ? (
            <BasicTable horizontalPadding="1rem" rows={rows} columns={columns} />
          ) : (
            <Box padding="2rem">
              <EmptyState />
            </Box>
          )}
        </>
      )}
    </>
  );
}
