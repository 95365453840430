import React from 'react';
import Container from 'components/Container/Container';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { useClientDetailsContext } from 'services/client/context';
import { Redirect } from 'react-router-dom';
import PMS from 'components/MicroFrontEnd/PMS/PMS';
import { BOOLEAN_NUMBERS_STRING } from 'shared/constants/common';

export default function Messages() {
  const { hideChat, enablePms } = useClientDetailsContext();
  if (hideChat || enablePms === BOOLEAN_NUMBERS_STRING.FALSE) return <Redirect to="/" />;
  return (
    <AccessControl perform={PERMISSIONS.CHAT.USE} redirectOnNoAccessTo="/">
      <Container padding="3rem 0 0" flex="0 1 auto" height="80vh">
        <PMS />
      </Container>
    </AccessControl>
  );
}
