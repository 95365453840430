import React from 'react';

export default function PowerIcon({ width, height }) {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      fill="currentColor"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.005 1a.498.498 0 0 1 .496.508v4.02A.504.504 0 0 1 6 6.03a.5.5 0 0 1-.5-.503V1.508A.504.504 0 0 1 6.004 1zM3.088 2.007l.049.001a.499.499 0 0 1 .435.35.505.505 0 0 1-.158.537A4.022 4.022 0 0 0 2 5.97c0 2.23 1.783 4.026 3.998 4.026 2.214 0 4-1.797 4-4.026 0-1.228-.54-2.32-1.398-3.06a.502.502 0 0 1 .65-.763A5.04 5.04 0 0 1 11 5.969C11 8.74 8.754 11 5.998 11 3.24 11 1 8.74 1 5.969c0-1.535.686-2.916 1.768-3.838a.499.499 0 0 1 .32-.124z"
        fill="currentColor"
      />
      <path
        d="M6.465 1.315l.092-.037A.602.602 0 0 0 6.007.9m.458.415l-.966 4.212A.504.504 0 0 0 6 6.03a.5.5 0 0 0 .5-.503V1.508m-.035-.193a.504.504 0 0 1 .036.193m-.036-.193l.092-.037c.03.073.044.152.044.23M6.006.9a.598.598 0 0 0-.607.609M6.006.9V1 .9zm-.607.609h.1-.1zm0 0v4.018A.604.604 0 0 0 6 6.13a.6.6 0 0 0 .6-.603V1.51m0 0h-.1m.1 0h-.1M3.48 2.971a.603.603 0 0 0-.334-1.063h-.001l.335 1.063zm0 0A3.922 3.922 0 0 0 2.1 5.969c0 2.175 1.739 3.926 3.898 3.926s3.9-1.751 3.9-3.926a3.918 3.918 0 0 0-1.363-2.983.602.602 0 0 1 .78-.916A5.14 5.14 0 0 1 11.1 5.969c0 2.826-2.29 5.131-5.102 5.131C3.185 11.1.9 8.795.9 5.969c0-1.566.7-2.973 1.802-3.914a.599.599 0 0 1 .384-.148l.393 1.065z"
        stroke="currentColor"
        strokeWidth=".2"
      />
    </svg>
  );
}
