import React from 'react';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

const getStylesBySize = size => {
  switch (size) {
    case 'sm':
      return {
        minHeight: '2.25rem',
        borderRadius: '8px',
      };
    case 'md': {
      return {
        minWidth: '10rem',
        minHeight: '3rem',
        borderRadius: '12px',
      };
    }
    default:
      return {
        minHeight: '3rem',
        borderRadius: '12px',
      };
  }
};

const useStyles = makeStyles(theme => ({
  containedButton: ({ size }) => ({
    padding: '0rem 0.875rem',
    boxShadow: 'none',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontSize: '13px',
    fontWeight: 500,
    '&:hover': {
      boxShadow: 'none',
    },
    ...getStylesBySize(size),
  }),
}));

export default function ContainedButton({
  children,
  isLoading,
  disabled,
  variant = 'contained',
  size,
  color = 'primary',
  className,
  ...rest
}) {
  const classes = useStyles({ size });
  return (
    <Button
      disabled={isLoading || disabled}
      className={clsx(classes.containedButton, className)}
      variant={variant}
      color={color}
      {...rest}
    >
      {isLoading ? <CircularProgress size={18} /> : children}
    </Button>
  );
}
