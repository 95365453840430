import { useQuery, useMutation, usePaginatedQuery, useQueryCache } from 'react-query';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';
import {
  deleteFailedLink,
  getFailedLinked,
  addBorrower,
  getSignedUp,
  getPaymentMade,
  getOnApp,
  getAppDetails,
} from './api';

export const useDeleteFailedLink = (options = {}) => {
  return useMutation(deleteFailedLink, {
    ...options,
  });
};

export default function useFailedLink() {
  const { currentPage, searchString } = useBorrowersFilters();
  return usePaginatedQuery(
    ['failed-notify-lenders', { page: currentPage, limit: 12, searchString }],
    getFailedLinked,
    {
      staleTime: 0,
    },
  );
}

export const useAddBorrower = (options = {}) => {
  return useMutation(addBorrower, {
    ...options,
  });
};

export function useSignedUp() {
  const { currentPage, searchString, sortBy } = useBorrowersFilters();
  return usePaginatedQuery(
    ['signed-up', { page: currentPage, limit: 12, searchString, sortBy }],
    getSignedUp,
    {
      staleTime: 0,
    },
  );
}
export function usePaymentMade() {
  const { currentPage, searchString, paymentDate } = useBorrowersFilters();
  return usePaginatedQuery(
    ['payment-made', { page: currentPage, limit: 12, searchString, paymentDate }],
    getPaymentMade,
    {
      staleTime: 0,
    },
  );
}
export function useOnApp() {
  const { currentPage, searchString, paymentDate } = useBorrowersFilters();
  return usePaginatedQuery(
    ['on-app', { page: currentPage, limit: 12, searchString, paymentDate }],
    getOnApp,
    {
      staleTime: 0,
    },
  );
}

export const useAppDetails = () => {
  return useQuery(['app-details'], getAppDetails);
};

// const BORROWERS_TILE_TYPE = {
//   SIGNED_UP: 1,
//   ON_APP: 2,
//   PAYMENT_MADE: 3,
//   FAILED_LINK: 4,
// };
// const getBorrowersFunctions = {
//   [BORROWERS_TILE_TYPE.SIGNED_UP]: useSignedUp,
//   [BORROWERS_TILE_TYPE.ON_APP]: useSignedUp,
//   [BORROWERS_TILE_TYPE.PAYMENT_MADE]: useSignedUp,
//   [BORROWERS_TILE_TYPE.FAILED_LINK]: useSignedUp,
// };

// export function useBorrowers(params) {
//   return getBorrowersFunctions[]
// }
