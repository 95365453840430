import React from 'react';
import { Box, Button } from '@material-ui/core';
import BasicChip from 'components/BasicChip/BasicChip';
import { FieldArray, useField, useFormikContext } from 'formik';
import BasicButton from 'components/BasicButton/BasicButton';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import { useRules } from 'services/rules/hooks';
import useDisclosure from 'shared/hooks/useDisclosure';
import AddNewGroupModal from 'components/AddNewGroupModal/AddNewGroupModal';
import { useQueryCache } from 'react-query';

const listOfGroups = [
  {
    groupName: '30DSD',
    id: 1,
  },
  {
    groupName: '30DSD',
    id: 2,
  },
];

export default function RulesFormsGroups() {
  const { activeTab } = useActiveTab();
  const { values } = useFormikContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { refetch, isLoading, data } = useRules(activeTab);
  const [field] = useField({ name: 'allGroup' });
  const queryCache = useQueryCache();
  const checkIfIsSelected = value => !!field.value.find(item => item.id === value.id);

  const handleGroupToggle = (clickedGroup, { push, remove }) => {
    const clickedGroupIndex = field.value.findIndex(group => group.id === clickedGroup.id);

    if (clickedGroupIndex >= 0) {
      remove(clickedGroupIndex);
      return;
    }
    push({ id: clickedGroup.id });
  };

  const handleCreateGroupSuccess = (response) => {
    queryCache.invalidateQueries('rules-details');
    onClose();
  }

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <FieldArray
        name="allGroup"
        render={arrayHelpers => (
          <>
            {data.result?.data?.groupList?.map(group => (
              <Box marginBottom="0.75rem" marginRight="0.75rem" key={group.id}>
                <BasicChip
                  disabled={!values.offHide}
                  label={group.groupName}
                  onClick={() => handleGroupToggle(group, arrayHelpers)}
                  color="primary"
                  clickable={false}
                  variant={checkIfIsSelected(group) ? 'default' : 'outlined'}
                />
              </Box>
            ))}
            <Box marginBottom="0.75rem">
              <BasicButton disabled={!values.offHide} onClick={onOpen} variant="link">+ New Group</BasicButton>
            </Box>
          </>
        )}
      />
       <AddNewGroupModal isOpen={isOpen} onClose={onClose} onSuccess={handleCreateGroupSuccess} />
    </Box>
  );
}
