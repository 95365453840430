import React, { useState, useContext, useMemo, createContext, useCallback } from 'react';

const GroupsTabActiveContext = createContext();

const GroupsTabActiveContextProvider = ({ children }) => {
  const [groupsTabActive, setGroupsTabActive] = useState(null);
  const [groupsTabActiveName, setGroupsTabActiveName] = useState('');

  const reset = useCallback(() => {
    setGroupsTabActive(null);
    setGroupsTabActiveName(null);
  }, []);

  const value = useMemo(
    () => ({
      setGroupsTabActive,
      groupsTabActive,
      setGroupsTabActiveName,
      groupsTabActiveName,
      reset,
    }),
    [groupsTabActive, groupsTabActiveName],
  );

  return (
    <GroupsTabActiveContext.Provider value={value}>{children}</GroupsTabActiveContext.Provider>
  );
};

export default GroupsTabActiveContextProvider;

export function useGroupsActiveTab() {
  const context = useContext(GroupsTabActiveContext);
  if (!context) {
    throw new Error(`useGroupsActiveTab must be used within a GroupsTabActiveContext`);
  }
  return context;
}
