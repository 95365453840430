import React from 'react';

export default function PersonFilledIcon({ height = '12px', width = '12px' }) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 3.632C8.5 2.178 7.38 1 6 1S3.5 2.178 3.5 3.632c0 1.453 1.12 2.631 2.5 2.631s2.5-1.178 2.5-2.631zM3.392 7.845l.108-.003h5c1.38 0 2.5 1.178 2.5 2.632 0 .29-.224.526-.5.526h-9a.514.514 0 0 1-.5-.526c0-1.415 1.061-2.57 2.392-2.63z"
        fill="#F5F6F8"
      />
    </svg>
  );
}
