import React from 'react';
import { Box } from '@material-ui/core';
import SearchBar from 'components/SearchBar/SearchBar';
import SearchBarProvider from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import Text from 'components/Text/Text';
import Form from 'components/Form/Form';
import ChipCheckbox from 'components/ChipCheckbox/ChipCheckbox';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import { useGroupDetail } from 'services/groups/hooks';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { useGroupsActiveTab } from '../GroupsTabActiveProvider/GroupsTabActiveProvider';
import GroupAssociatedAccountsItem from './GroupAssociatedAccountsItem/GroupAssociatedAccountsItem';
import GroupAssociatedAccountsList from './GroupAssociatedAccountsList/GroupAssociatedAccountsList';

export default function GroupAssociatedAccounts() {
  const { groupsTabActive } = useGroupsActiveTab();
  const { data: groupDetailData, isLoading: isGroupDetailLoading } = useGroupDetail(
    groupsTabActive,
  );

  return (
    <FlexColumn height="100%" padding="1rem 2.5rem">
      <SearchBarProvider>
        <GroupAssociatedAccountsList />
      </SearchBarProvider>
    </FlexColumn>
  );
}
