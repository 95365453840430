import React from 'react';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';

export default function WebPopSection({ title, rightSideOfTitle, children }) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="1.5rem">
        <Text fontWeight={500} letterSpacing="0.21px" fontSize="0.875rem" lineHeight="0.875rem">
          {title}
        </Text>
        {rightSideOfTitle}
      </Box>
      {children}
    </Box>
  );
}
