import React from 'react';
import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import useMixinStyles from 'styles/mixins';

export default function BasicModalGoBack({ onClick }) {
  const mixinStyles = useMixinStyles();
  return (
    <Box
      className={mixinStyles.clickable}
      onClick={onClick}
      display="inline-flex"
      marginRight="0.5rem"
    >
      <Icon height="1rem" width="1rem" name="back" />
    </Box>
  );
}
