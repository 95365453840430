import React from 'react';
import { Box } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';

export default function WebPopContent({ children }) {
  const mixinStyles = useMixinStyles();
  return (
    <Box
      height="100%"
      width="100%"
      padding="1rem 2rem 1.5rem"
      display="flex"
      flexDirection="column"
      maxHeight="85vh"
      overflow="scroll"
      className={mixinStyles.scrollBar}
    >
      {children}
    </Box>
  );
}
