import { subDays, format, formatISO, addDays } from 'date-fns';
import { path, split } from 'ramda';

export const getPreviousDateFromToday = (numberOfDaysToGoBack) =>
  format(subDays(new Date(), numberOfDaysToGoBack), 'mm/dd/yyyy');

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function pathSplit(item, pathStr, char = '.') {
  const str = pathStr.replace('[', '.').replace(']', '');
  return path(split(char, str), item);
}

export function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

export function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data;
    if (value !== null && value !== undefined) {
      formData.append(parentKey, value);
    }
  }
}

function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

function parseDate(date) {
  return isValidDate(date) ? date : new Date(replaceHyphenWithSlashes(getDateBySubstring(date)));
}

export function dateToISO(date) {
  return formatISO(date);
}
export function dateWithDashes(date) {
  return format(date, 'yyyy-MM-dd');
}

export function dateWithSlashes(date) {
  return date ? format(parseDate(date), 'MM/dd/yyyy') : format(new Date(), 'MM/dd/yyyy');
}

export function dateWithSlashesTimezoned(date) {
  return date
    ? format(new Date(`${replaceSpaceWithTimezoneIndicator(date)}Z`), 'MM/dd/yyyy')
    : format(new Date(), 'MM/dd/yyyy');
}

export function getFollowingDaysFromToday(numberOfDaysToGoForward, dateFormat = 'yyyy-MM-dd') {
  return format(addDays(new Date(), numberOfDaysToGoForward), dateFormat);
}

/**
 * Async functions handler based on awai-to-js package
 */
export function to(promise, errorExt) {
  return promise
    .then((data) => [null, data])
    .catch((err) => {
      if (errorExt) {
        Object.assign(err, errorExt);
      }
      return [err, undefined];
    });
}

export function replaceKeysOfString(string, keys = {}) {
  let newString = string;
  Object.keys(keys).forEach(function (key) {
    newString = newString.replace(new RegExp(`{${key}}`, 'g'), keys[key]);
  });
  return newString;
}

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

export const replaceSpaceWithTimezoneIndicator = (string) => string.replace(' ', 'T');
export const replaceHyphenWithSlashes = (string) =>
  typeof string === 'string' && string?.replace(/-/g, '/');
export const getDateBySubstring = (date) => typeof date === 'string' && date.substring(0, 10);
