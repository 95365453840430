import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import BasicInput from 'components/BasicInputs/BasicInput';
import IconButton from 'components/Icon/IconButton/IconButton';
import FlexRow from 'components/FlexRow/FlexRow';

export default function SubmitInput({ value, onSubmit, textAlign }) {
  const [inputValue, setInputValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit(inputValue, setIsEdit);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setInputValue(value);
    }
  }, [isEdit]);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Box minWidth="0" alignItems="center" display={isEdit ? 'flex' : 'none'}>
        <Box minWidth="0" overflow="hidden" marginRight="0.75rem">
          <Box position="relative">
            <FlexRow overflow="hidden" height="2.25rem" minWidth="100px" padding="0 1rem">
              {inputValue}
              <BasicInput
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={inputValue}
                size="sm"
                autoGrow
                textAlign={textAlign}
              />
            </FlexRow>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => onSubmit(inputValue, setIsEdit)}
            name="check"
            marginRight="0.75rem"
          />
          <IconButton name="close" onClick={() => setIsEdit(false)} />
        </Box>
      </Box>
      <Box display={isEdit ? 'none' : 'flex'} alignItems="center" minWidth="0">
        <IconButton marginRight="0.5rem" onClick={() => setIsEdit(true)} name="penFilled" />
        <Box component="span" textOverflow="ellipsis" overflow="hidden" title={value || 'N/A'}>
          {value || 'N/A'}
        </Box>
      </Box>
    </Box>
  );
}
