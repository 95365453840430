import { Box } from '@material-ui/core';
import BasicButton from 'components/BasicButton/BasicButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Refund from 'containers/borrowers/disclaimers/Refund';
import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { useVoidPayment } from 'services/payments/hooks';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import useDisclosure from 'shared/hooks/useDisclosure';
import useMixinStyles from 'styles/mixins';

function checkForAmount(amount) {
  if (amount) {
    return amount;
  }
  return 0;
}

export default function PaymentHistoryActions({ payment }) {
  const mixinClasses = useMixinStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isVoidOpen, onOpen: onVoidOpen, onClose: onVoidClose } = useDisclosure();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const [mutate, { isLoading }] = useVoidPayment({
    onSuccess: (res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCESS) {
        queryCache.invalidateQueries('payment-history', {
          refetchInactive: true,
          refetchActive: true,
        });
        setAlertMessage('Void successful!');
        onVoidClose();
      } else {
        setErrorAlertMessage(res.data);
      }
    },
  });

  const amount =
    checkForAmount(payment.pmtAmt) +
    checkForAmount(payment.cardTransactionAmount) +
    checkForAmount(payment.achTransactionAmount) +
    checkForAmount(payment.platformTransactionFees);

  const handleRefundClick = () => {
    onOpen();
  };

  const handleVoidConfirm = () => {
    mutate({
      paymentMadeId: payment.id,
    });
  };

  return (
    <Box>
      {payment.paymentType === 2 && !payment.paymentStatus.showRefund && (
        <>
          {payment.paymentStatus.achStatus === 1 ? (
            <BasicButton onClick={onVoidOpen} variant="error">
              {payment.paymentStatus.description}
            </BasicButton>
          ) : (
            <Box>{payment.paymentStatus.description}</Box>
          )}
        </>
      )}
      {!payment.isRefunded && !!payment.paymentStatus.showRefund && (
        <BasicButton size="small" onClick={handleRefundClick}>
          Refund
        </BasicButton>
      )}
      {!!payment.isRefunded && !!payment.paymentStatus.showRefund && amount > 0 && (
        <Box>Refunded</Box>
      )}
      {!!payment.isRefunded &&
        !!payment.paymentStatus.showRefund &&
        amount <= 0 &&
        payment.refundedAmt < 0 && <Box color="primary.500">Credit</Box>}

      <ConfirmationDialog
        title="Void"
        description="Are you sure you want to void payment?"
        isOpen={isVoidOpen}
        onClose={onVoidClose}
        onConfirm={handleVoidConfirm}
        loading={isLoading}
        mainActionText="Void"
      />
      <Refund
        openRefund={isOpen}
        val={(amount - payment.refundedAmt).toFixed(2)}
        handleClosePayment={onClose}
        pmtId={payment.id}
      />
    </Box>
  );
}
