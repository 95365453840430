import React from 'react';
import Router from 'config/Router';

const App = () => (
  <>
    <Router />
  </>
);

export default App;
