import { Box } from '@material-ui/core';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import React from 'react';
import BasicUpload from '../BasicUpload';

export default function UploadNew({ name, accept }) {
  return (
    <BasicUpload name={name} accept={accept}>
      <Box display="flex">
        <FlexRow marginRight="0.25rem">
          <Icon width="0.75rem" height="0.75rem" name="upload" />
        </FlexRow>
        <Text
          color="#BBC3D0"
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="0.875rem"
          letterSpacing="0.3px"
        >
          Upload New
        </Text>
      </Box>
    </BasicUpload>
  );
}
