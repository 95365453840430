import React from 'react';

export default function LockIcon({ width, height }) {
  return (
    <svg
      width={width || 15}
      height={height || 19}
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5.792a4.75 4.75 0 0 1 4.75 4.75v2.375h.238c1.18 0 2.137 1.063 2.137 2.375v5.541c0 1.312-.957 2.375-2.137 2.375H2.511c-1.18 0-2.137-1.063-2.137-2.375v-5.541c0-1.312.957-2.375 2.138-2.375h.237V5.542A4.75 4.75 0 0 1 7.322.795L7.5.792zm0 1.979c-1.749 0-2.77 1.418-2.77 3.167v1.979h5.54v-1.98c0-1.695-.936-3.08-2.612-3.162L7.5 2.77z"
        fill="#8C9BAB"
      />
    </svg>
  );
}
