import { Box } from '@material-ui/core';
import UploadNew from 'components/BasicInputs/BasicUpload/UploadNew/UploadNew';
import Center from 'components/Center/Center';
import DeleteAction from 'components/DeleteAction/DeleteAction';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import { useField } from 'formik';
import React from 'react';
import useImagesMixins from 'styles/images-mixins';
import useMixinStyles from 'styles/mixins';

export default function SignInImageOption({ src, option, name }) {
  const mixinStyles = useMixinStyles();
  const imagesMixins = useImagesMixins();
  const [field, meta, helpers] = useField('selectedBackgroundImage');
  const [fieldOption] = useField(name);

  const handleOnDelete = () => {
    helpers.setValue('');
  };

  const handleOnClick = () => {
    helpers.setValue(option);
  };

  const isActive = field.value === option;

  const getPreviewImage = () => {
    if (typeof fieldOption.value === 'string') {
      return fieldOption.value;
    }
    if (fieldOption.value instanceof File) {
      return URL.createObjectURL(fieldOption.value);
    }
  };

  return (
    <FlexColumn>
      <FlexColumn
        className={mixinStyles.clickable}
        position="relative"
        borderRadius="8px"
        overflow="hidden"
        height="9.1875rem"
        marginBottom="0.5rem"
        onClick={handleOnClick}
        border={`2px solid ${isActive ? '#008949' : 'transparent'}`}
      >
        <Center
          width="0.875rem"
          height="0.875rem"
          bgcolor="white"
          borderRadius="50%"
          position="absolute"
          top="0.375rem"
          right="0.375rem"
          color="primary.500"
        >
          {isActive && '✓'}
        </Center>
        <Box
          height="100%"
          width="100%"
          className={imagesMixins.objectFitCover}
          component="img"
          src={getPreviewImage()}
        />
      </FlexColumn>
      <FlexColumn alignItems="center">
        <Box lineHeight="1" marginBottom="0.25rem">
          <UploadNew name={name} />
        </Box>
      </FlexColumn>
    </FlexColumn>
  );
}
