import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PayixLogoWhite from 'assets/img/payix-logo-white.svg';
import HomeBackground from 'assets/img/home-background.jpg';

const useStyles = makeStyles(() => ({
  container: {
    background: `url(${HomeBackground})`,
    backgroundSize: 'cover',
  },
  header: {
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(190, 190, 190, 0) 96.87%, rgba(196, 196, 196, 0) 100%)',
  },
}));

export default function HomeContainer({ children }) {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      width="100vw"
      minHeight="37.5rem"
      height="100vh"
      overflow="auto"
    >
      <Box className={classes.header}>
        <Box
          justifyContent="flex-start"
          display="flex"
          width="100%"
          maxWidth="1200px"
          margin="0 auto"
          paddingTop="2.5rem"
        >
          <Box component="img" src={PayixLogoWhite} marginBottom="2.5rem" />
        </Box>
      </Box>
      <Box
        alignSelf="flex-end"
        display="flex"
        width="100%"
        maxWidth="1200px"
        margin="0 auto"
        justifyContent="flex-end"
        padding="1.5rem 1.5rem"
      >
        <Box flexBasis="50%" width="100%" maxWidth="480px">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
