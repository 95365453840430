import React from 'react';

export default function SMSIcon({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height || '24px'}
      width={width || '24px'}
      viewBox="0 0 496 496"
    >
      <path
        fill="currentColor"
        d="M472 112H288V40c0-22.056-17.944-40-40-40H40C17.944 0 0 17.944 0 40v416c0 22.056 17.944 40 40 40h208c22.056 0 40-17.944 40-40V336h-16v80H16V80h256v32h-72c-13.232 0-24 10.768-24 24v116.688L108.688 320H472c13.232 0 24-10.768 24-24V136c0-13.232-10.768-24-24-24zM272 432v24c0 13.232-10.768 24-24 24H40c-13.232 0-24-10.768-24-24v-24h256zM16 64V40c0-13.232 10.768-24 24-24h208c13.232 0 24 10.768 24 24v24H16zm464 232c0 4.416-3.584 8-8 8H147.312L192 259.312V136c0-4.416 3.584-8 8-8h272c4.416 0 8 3.584 8 8v160z"
      />
      <path
        fill="currentColor"
        d="M416 208c-8.824 0-16-7.176-16-16s7.176-16 16-16 16 7.176 16 16h16c0-17.648-14.352-32-32-32s-32 14.352-32 32 14.352 32 32 32c8.824 0 16 7.176 16 16s-7.176 16-16 16-16-7.176-16-16h-16c0 17.648 14.352 32 32 32s32-14.352 32-32-14.352-32-32-32zM256 208c-8.824 0-16-7.176-16-16s7.176-16 16-16 16 7.176 16 16h16c0-17.648-14.352-32-32-32s-32 14.352-32 32 14.352 32 32 32c8.824 0 16 7.176 16 16s-7.176 16-16 16-16-7.176-16-16h-16c0 17.648 14.352 32 32 32s32-14.352 32-32-14.352-32-32-32zM345.752 160L336 199.016 326.248 160H304v104h16v-63.016l16 64 16-64V264h16V160zM112 448h64v16h-64zM240 336h16v16h-16zM208 336h16v16h-16zM176 336h16v16h-16zM144 336h16v16h-16z"
      />
    </svg>
  );
}
