import { webAPI, API } from 'shared/utils/http';
import qs from 'querystring';
import { ENV, ENDPOINTS } from '../constants';

const accessToken = sessionStorage.getItem('accessToken');

export const getAppDetailsBorrowerPage = () => {
  return webAPI.get(`${ENV.WEBHOST}${ENDPOINTS.getAppDetails}`, {
    headers: {
      accessToken: sessionStorage.getItem('accessToken'),
    },
  });
};

export const getBorrowerDetails = (id) => {
  return webAPI.get(`${ENV.WEBHOST}${ENDPOINTS.getBorrowerDetails}${id}`, {
    headers: {
      accessToken: sessionStorage.getItem('accessToken'),
    },
  });
};

export const getLoanDetails = (account, channel, username, password) => {
  const data = {
    account,
    channel,
  };
  return API({
    method: 'post',
    url: `${ENV.HOST}${ENDPOINTS.getLoanDetails}`,
    headers: {
      username: 'abhishek',
      password: '9542bbee06af3fe5e0e3bfbfff11f9d9',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data),
  });
};

export const getScheduleList = (offset, limit, loanId, type, channel) => {
  const data = {
    offset,
    limit,
    loanId,
    type,
    channel,
  };
  return API({
    method: 'post',
    url: `${ENV.HOST}${ENDPOINTS.getScheduleList}`,
    headers: {
      username: 'abhishek',
      password: '9542bbee06af3fe5e0e3bfbfff11f9d9',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data),
  });
};

export const getPaymentFrequency = (id) => {
  return API.get(`${ENV.HOST}${ENDPOINTS.getFrecuency}`, {
    headers: {
      accessToken,
    },
  });
};

export const getOnAppLenders = (page, limit, searchString) => {
  return webAPI({
    method: 'get',
    url: `${ENV.WEBHOST}${ENDPOINTS.getOnApp}?page=${page}&limit=${limit}&searchString=${searchString}`,
    headers: {
      accessToken,
    },
  });
};

export const getPaymentMadeLenders = (
  limit,
  page,
  paymentDate,
  portfolioId,
  searchString,
  sortBy,
) => {
  const data = {
    limit,
    page,
    paymentDate,
    portfolioId,
    searchString,
    sortBy,
  };
  return webAPI({
    method: 'post',
    url: `${ENV.WEBHOST}${ENDPOINTS.getPaymentMade}`,
    headers: {
      accessToken,
    },
    data: qs.stringify(data),
  });
};

export const getFailedLinkedLenders = (page, limit) => {
  return webAPI({
    method: 'post',
    url: `${ENV.WEBHOST}${ENDPOINTS.getFailedLenders}`,
    headers: {
      accessToken,
    },
    data: qs.stringify({ page, limit }),
  });
};

export const getLenderReceipts = (limit, page, loanNumber) => {
  return webAPI({
    method: 'get',
    url: `${ENV.WEBHOST}${ENDPOINTS.getReceiptsByLoan}?limit=${limit}&loanNumber=${loanNumber}&page=${page}`,
    headers: {
      accessToken,
    },
  });
};

export const getLenderSchedule = (loanId) => {
  const data = {
    offset: 0,
    limit: 2000,
    loanId,
    sortBy: '',
    type: 0,
    channel: 'CAP',
  };
  return webAPI({
    method: 'post',
    url: `${ENV.WEBHOST}${ENDPOINTS.getScheduleByLoan}`,
    headers: {
      accessToken,
    },
    data: qs.stringify(data),
  });
};

export const getExportBorrowers = () => {
  return webAPI.request({
    method: 'GET',
    responseType: 'blob',
    headers: {
      accessToken,
      'Content-Type': 'application/csv',
    },
    url: `${ENV.WEBHOST}${ENDPOINTS.getExportBorrowers}?accessToken=${accessToken}&type=csv`,
  });
};

export const postMakePayment = (
  username,
  password,
  loanNumber,
  amount_in,
  transaction,
  paymentMethodId,
  paymentType,
  paymentDate,
  effectiveDate,
  poolId,
  portfolioId,
  userno,
  user_name,
  channel,
  principal,
  convFeeWaivedReasonId,
) => {
  return webAPI({
    method: 'post',
    headers: {
      username,
      password,
    },
    url: `${ENV.WEBHOST}${ENDPOINTS.postPayment}`,
    data: {
      account: loanNumber,
      amount: amount_in,
      transaction,
      token: paymentMethodId,
      paymentType,
      paymentDate,
      postPayment: 1,
      postComment: 1,
      effectiveDate,
      poolId,
      portfolioId,
      userno,
      username: user_name,
      channel,
      principalOnly: principal,
      convFeeWaivedReasonId,
      type: 1,
    },
  });
};

export const postRefund = (paymentMadeId, ifFullRefund, amount) => {
  const data = {
    paymentMadeId,
    ifFullRefund: ifFullRefund ? 1 : 0,
  };
  if (amount && !ifFullRefund) {
    data.amount = amount;
  }
  return webAPI({
    method: 'POST',
    url: `${ENV.WEBHOST}${ENDPOINTS.postRefund}`,
    headers: {
      accessToken,
    },
    data: qs.stringify(data),
  });
};

export const postPassword = (pass, conPass, id) => {
  const data = {
    confirm_password: conPass,
    password: pass,
    id,
  };
  return webAPI({
    method: 'POST',
    url: `${ENV.WEBHOST}${ENDPOINTS.postResetPassword}`,
    headers: {
      accessToken,
    },
    data: qs.stringify(data),
  });
};

export const postDeleteForUser = (installAccountId, loanNo) => {
  const data = {
    install_account_id: installAccountId,
    loan_no: loanNo,
  };

  return webAPI({
    method: 'POST',
    url: `${ENV.WEBHOST}${ENDPOINTS.postDeleteloan}`,
    headers: {
      accessToken,
    },
    data: qs.stringify(data),
  });
};

export const postDeleteForAll = (loanNo) => {
  const data = {
    loan_no: loanNo,
  };

  return webAPI({
    method: 'POST',
    url: `${ENV.WEBHOST}${ENDPOINTS.postDeleteLoans}`,
    headers: {
      accessToken,
    },
    data: qs.stringify(data),
  });
};

export const postAddBorrower = (dob, id, loanId, loanNumber, zipCode) => {
  const data = {
    branchNo: null,
    dob,
    id,
    loanId,
    loanNumber,
    name: null,
    phoneNo: null,
    ssn: '',
    ssn4: null,
    zipCode,
  };

  return webAPI({
    method: 'POST',
    url: `${ENV.WEBHOST}${ENDPOINTS.postAddBorrower}`,
    headers: {
      accessToken,
    },
    data,
  });
};

export const getRules = (id) => {
  return webAPI({
    method: 'GET',
    url: `${ENV.WEBHOST}${ENDPOINTS.getRulesDetails}${id}`,
    headers: {
      accessToken,
    },
  });
};
