import React from 'react';
import BasicTabs from 'components/BasicTabs/BasicTabs';
import { Box } from '@material-ui/core';
import BasicTab from 'components/BasicTabs/BasicTab/BasicTab';
import BasicTabPanel from 'components/BasicTabs/BasicTabPanel/BasicTabPanel';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import { useBorrowerDetails } from 'services/loans/hooks';
import useMixinStyles from 'styles/mixins';
import { useClientDetailsContext } from 'services/client/context';
import clsx from 'clsx';
import MakePayment from './MakePayment/MakePayment';
import { WEBPOP_TABS } from '../../../constants';
import SchedulePayment from './SchedulePayment/SchedulePayment';
import ScheduleList from './ScheduleList/ScheduleList';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';

export default function PaymentTabs() {
  const mixinClasses = useMixinStyles();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { hideSchedule } = useClientDetailsContext();
  return (
    <>
      <BasicModalTitle>{`Account #${borrowerDetails?.loan.loanNo}`}</BasicModalTitle>
      <BasicTabs>
        <Box display="flex" padding="0.5rem 2rem 0">
          <AccessControl perform={PERMISSIONS.PAYMENTS.MAKE}>
            <Box marginRight="3.5rem">
              <BasicTab index={WEBPOP_TABS.MAKE_PAYMENT}>Make Payment</BasicTab>
            </Box>
          </AccessControl>
          {!hideSchedule && (
            <>
              <AccessControl perform={PERMISSIONS.PAYMENTS.SCHEDULE}>
                <Box marginRight="3.5rem">
                  <BasicTab index={WEBPOP_TABS.SCHEDULE_PAYMENT}>Schedule Payment</BasicTab>
                </Box>
              </AccessControl>
              <AccessControl perform={PERMISSIONS.PAYMENTS.SCHEDULE_LIST}>
                <Box marginRight="3.5rem">
                  <BasicTab index={WEBPOP_TABS.SCHEDULE_LIST}>Schedule List</BasicTab>
                </Box>
              </AccessControl>
            </>
          )}
        </Box>
      </BasicTabs>
      <AccessControl perform={PERMISSIONS.PAYMENTS.MAKE}>
        <BasicTabPanel index={WEBPOP_TABS.MAKE_PAYMENT}>
          <Box height="100%" flex={1}>
            <MakePayment />
          </Box>
        </BasicTabPanel>
      </AccessControl>
      <AccessControl perform={PERMISSIONS.PAYMENTS.SCHEDULE}>
        {!hideSchedule && (
          <>
            <BasicTabPanel index={WEBPOP_TABS.SCHEDULE_PAYMENT}>
              <Box height="100%" flex={1}>
                <SchedulePayment />
              </Box>
            </BasicTabPanel>
            
          </>
        )}
      </AccessControl>
      <AccessControl perform={PERMISSIONS.PAYMENTS.SCHEDULE_LIST}>
        {!hideSchedule && (
          <>
            <BasicTabPanel
              className={clsx(mixinClasses.scroll, mixinClasses.scrollBar)}
              index={WEBPOP_TABS.SCHEDULE_LIST}
            >
              <Box flex={1}>
                <ScheduleList />
              </Box>
            </BasicTabPanel>
            
          </>
        )}
      </AccessControl>
    </>
  );
}
