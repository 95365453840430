import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Text from 'components/Text/Text';

export default function PaymentSummaryItem({ attribute, value }) {
  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text fontSize="0.75rem" color="text.primary" letterSpacing="-0.12px">
          {attribute}
        </Text>
        <Text fontSize="0.875rem" color="text.primary" fontWeight={500} letterSpacing="-0.14px">
          {value}
        </Text>
      </Box>
    </Grid>
  );
}
