import React from 'react';

export default function PenFilledIcon({ width, height }) {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.942 8.161L8.161.942a.656.656 0 0 1 .866-.054l.062.054 1.969 1.969a.657.657 0 0 1 .054.866l-.054.062-7.219 7.219a.656.656 0 0 1-.378.186l-.086.006H1.406a.656.656 0 0 1-.652-.58l-.004-.076V8.625c0-.145.048-.285.135-.399l.057-.065L8.161.942.942 8.161z"
        fill="#008949"
      />
    </svg>
  );
}
