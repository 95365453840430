import React from 'react';
import { DialogTitle, makeStyles } from '@material-ui/core';
import Text from 'components/Text/Text';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  modal: {
    maxHeight: '40rem',
  },
  dialogTitle: {
    padding: '2rem 2rem 0',
  },
}));

export default function BasicModalTitle({ children, className }) {
  const classes = useStyles();
  return (
    <DialogTitle
      className={clsx(classes.dialogTitle, className)}
      disableTypography
      id="alert-dialog-title"
    >
      <Text
        display="flex"
        alignItems="center"
        fontSize="1rem"
        color="text.primary"
        fontWeight={600}
        letterSpacing="0.015em"
        lineHeight="1rem"
      >
        {children}
      </Text>
    </DialogTitle>
  );
}
