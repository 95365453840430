import React from 'react';
import Container from 'components/Container/Container';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import SecondNavBar from 'containers/navigation/SecondNavBar';

const SETTINGS_TABS = [{ name: 'Settings', path: '/profile' }];

export default function Profile({ routes }) {
  return (
    <>
      <SecondNavBar justifyContent="flex-start" counts={SETTINGS_TABS} />
      <Container>
        <Switch>
          {routes.map((route) => (
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
        </Switch>
      </Container>
    </>
  );
}
