import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { Grid, Icon } from '@material-ui/core';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { useDetails, useUpdatePhone, useUpdateWebsite } from 'services/client/hooks';
import * as yup from 'yup';
import FormattedTextField from 'components/BasicInputs/BasicTextField/FormattedTextField/FormattedTextField';
import SettingsSection from '../../Shared/SettingsSection/SettingsSection';
import InformationInput from './InformationInput/InformationInput';
import SettingsSectionUpdate from '../../Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import useBTC from '../useBTC';

const validationSchema = yup.object({
  website: yup.string().trim().required('Website is required'),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d+$/, 'Phone number not valid')
    .test('len', 'Phone number should be 10 digits', (val) => val.length === 10),
});

export default function Information() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateWebsite({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Information updated successfully!' });
    },
  });
  const [mutatePhone] = useUpdatePhone({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Information updated successfully!' });
    },
  });

  const initialValues = useMemo(
    () => ({
      website: data.website,
      websiteStatus: data.websiteStatus,
      phone: data.phone,
      PhoneStatus: data.PhoneStatus || data.phoneStatus,
    }),
    [data],
  );

  const handleOnSubmit = async (values) => {
    const promisesToSubmit = [];
    if (values.website !== initialValues.website) {
      promisesToSubmit.push(
        mutate({
          website: values.website,
        }),
      );
    }
    if (values.phone !== initialValues.phone) {
      promisesToSubmit.push(
        mutatePhone({
          phone: values.phone,
        }),
      );
    }
    await Promise.allSettled(promisesToSubmit);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      <SettingsSection title="Information" description="Information for receipts and portal">
        <FlexColumn height="8rem">
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <InformationInput
                name="website"
                label="Website"
                inputPlaceholder="www.website.com"
                switchName="websiteStatus"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InformationInput
                name="phone"
                switchName="PhoneStatus"
                inputComponent={
                  <FormattedTextField
                    placeholder="5033005415"
                    name="phone"
                    label="Phone Number"
                    formatProps={{
                      format: '(###) ###-####',
                      allowEmptyFormatting: true,
                      mask: '_',
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
          <SettingsSectionUpdate />
        </FlexColumn>
      </SettingsSection>
    </Form>
  );
}
