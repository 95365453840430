import { createStyles, makeStyles } from '@material-ui/core/styles';
import { COLOR } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles(theme =>
  createStyles({
    /** WIDGETS BAR STYLE */
    widgetsRoot: {
      flexGrow: 1,
      marginTop: '1%',
      marginRight: '2%',
      marginBottom: '0%',
    },
    widgetList: {
      margin: 'auto !important',
    },
    /** TABLE VIEW STYLES */
    Tcontainer: {
      marginTop: '0.5rem',
      borderRadius: theme.radius.containers,
      border: `1px solid ${theme.palette.common.border}`,
    },
    theader: {
      backgroundColor: 'green !important',
      color: 'white !important',
      fontWeight: 'bold !important',
      padding: '5px !important',
    },
    theight: {
      height: '15px !important',
    },
    tcell: {
      height: 'auto !important',
      padding: '2px !important',
    },
    Button: {
      textTransform: 'initial',
      width: '100%',
    },
    button: {
      backgroundColor: COLOR.GREENBUTTON,
      color: 'white',
      width: '100%',
      textTransform: 'capitalize',
      ' &:hover': {
        backgroundColor: COLOR.GREENBUTTON_HOVER,
      },
    },
    smallButton: {
      backgroundColor: COLOR.GREENBUTTON,
      color: `${COLOR.WHITE} !important`,
      textTransform: 'capitalize !important',
      ' &:hover': {
        backgroundColor: COLOR.GREENBUTTON_HOVER,
      },
      margin: '1%',
      width: '5rem',
    },
    TableIcons: {
      marginRight: 2,
      color: 'green',
    },
    /** CARD VIEW STYLES */
    CardRoot: {
      flexGrow: 1,
      margin: '1rem !important',
    },
    card: {
      maxWidth: 300,
      '& .MuiCardContent-root': {
        height: 150,
      },
      borderRadius: '3 rem',
    },
    cardTitle: {
      fontSize: '0.6rem',
      textAlign: 'left',
    },
    cardSpan: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    cardPos: {
      marginBottom: '0.6rem',
      textTransform: 'capitalize',
    },
    pos_l: {
      width: '50%',
      textAlign: 'left',
      display: 'inline-block',
      textTransform: 'capitalize',
    },
    pos_r: {
      width: '50%',
      textAlign: 'right',
      display: 'inline-block',
      textTransform: 'capitalize',
    },
    link_l: {
      width: '50%',
      textAlign: 'left',
      display: 'inline-block',
      fontSize: '0.6rem',
    },
    title: {
      fontSize: '0.875rem',
      lineHeight: 1,
    },
    link_r: {
      width: '50%',
      textAlign: 'right',
      display: 'inline-block',
      fontSize: '0.6rem',
    },
    borrowerData: {
      marginBottom: '1.25rem',
    },
    field: {
      '& .MuiInputBase-formControl': {
        marginTop: '0.5rem',
        '& .MuiOutlinedInput-input': {
          height: '0.1876rem',
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '0.5rem',
          width: '13.7rem',
          [theme.breakpoints.up('lg')]: {
            width: '15.7rem',
          },
        },
      },
    },
    bottom: {
      marginBottom: '0.5rem !important',
    },
    SmallDialog: {
      maxWidth: '400px !important',
    },
    detRoot: {
      borderRadius: 0,
      border: 'none',
      minWidth: '20rem',
    },
    media: {
      height: '6rem',
      margin: '0 1.5rem',
      borderRadius: '0.5rem',
      marginBottom: '1.25rem',
    },
    detTitle: {
      fontSize: '0.875rem ',
      color: 'rgba(34, 47, 62, 0.87)',
      letterSpacing: '-0.12px',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    shadow: {
      fontSize: '0.875rem ',
      lineHeight: '0.875rem',
      color: 'rgba(124, 141, 160, 0.88)',
      letterSpacing: '-0.12px',
    },
    thumbnail: {
      borderRadius: '5rem',
      width: '3rem',
      marginTop: '-8rem',
      border: `0.2rem solid ${COLOR.ORANGE}`,
    },
    button_danger: {
      backgroundColor: 'transparent !important',
      color: `${COLOR.GREENBUTTON} !important`,
      width: '100%',
      fontWeight: 600,
      textTransform: 'capitalize !important',
      cursor: 'pointer',
    },
    cardIcons: {
      color: theme.palette.primary[500],
      marginRight: '0.25rem',
    },
    alertTitle: {
      color: 'red',
    },
    loanDetailsIcons: {
      height: '16px',
      width: '16px',
    },
  }),
);
