import React from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import TableChartIcon from '@material-ui/icons/TableChart';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';

const useStyles = makeStyles((theme) => ({
  viewType: {
    backgroundColor: theme.palette.primary[100],
    borderRadius: theme.radius.containers,
    padding: '6px 12px 6px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: '127px',
    color: theme.palette.primary[500],
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
    height: '3rem',
  },
  viewTypeIcon: {
    height: '24px',
    width: '24px',
    marginRight: '0.25rem',
  },
  viewTypeText: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
}));

export default function BorrowersListItemToggle() {
  const { setIsListOfCards, isListOfCards } = useBorrowersFilters();
  const classes = useStyles();
  const SelectedViewTypeIcon = isListOfCards ? AppsIcon : TableChartIcon;
  return (
    <ButtonBase className={classes.viewType} onClick={() => setIsListOfCards((prev) => !prev)}>
      <SelectedViewTypeIcon className={classes.viewTypeIcon} />
      <Typography className={classes.viewTypeText}>
        {isListOfCards ? 'Grid View' : 'Table View'}
      </Typography>
    </ButtonBase>
  );
}
