import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import IconButton from 'components/Icon/IconButton/IconButton';
import React, { useState } from 'react';
import useMixinStyles from 'styles/mixins';
import BasicTextField from '../BasicTextField';

export default function PasswordField({ name, ...rest }) {
  const mixinStyles = useMixinStyles();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <Box position="relative">
      <BasicTextField
        endAdornment={
          <Box color="#8C9BAB" className={mixinStyles.absoluteCenter} right="0.5rem">
            <IconButton
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              name={isPasswordVisible ? 'invisible' : 'visible'}
            />
          </Box>
        }
        startIcon={<Icon name="lock" />}
        name={name}
        {...rest}
        type={isPasswordVisible ? 'text' : 'password'}
        placeholder="••••••"
      />
    </Box>
  );
}
