import { parse } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { path } from 'ramda';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Box, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { isValidDate, pathSplit } from 'shared/utils';
import useMixinStyles from 'styles/mixins';
import Icon from 'components/Icon/Icon';
import useInputStyles from '../useInputStyles';
import BasicInput from '../BasicInput';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.border,
    },
    '&:hover': {
      borderColor: theme.palette.common.border,
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
  inputBase: {
    '& > .MuiInputBase-root': {
      '&:focus': {
        borderRadius: '8px',
        background: 'white',
      },
      '&::before, &::after': {
        content: 'none',
      },
    },
  },
  dialog: {
    '& .MuiDialogContent-root .MuiButton-root': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& .MuiDialogContent-root .MuiButton-root:first-of-type': {
      pointerEvents: 'all',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[700],
      padding: '0 0.5rem',
    },
    '& .MuiDialogContent-root .MuiButton-root:first-of-type:hover': {
      backgroundColor: theme.palette.primary[800],
    },
    '& h6.MuiPickersToolbarText-toolbarTxt': {
      fontWeight: '600',
      fontSize: '0.875rem',
      color: 'white',
    },
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
  positionEnd: {
    marginLeft: 0,
  },
  calendarButton: {
    padding: 0,
  },
}));

const SelectedDatePicker = {
  KEYBOARD: KeyboardDatePicker,
  DEFAULT: DatePicker,
};

export const BasicDateField = ({
  name,
  label,
  hideErrors,
  disabled,
  noShadow,
  format = 'yyyy-MM-dd',
  size,
  noKeyboard,
  withDateIcon,
  ...rest
}) => {
  const inputClasses = useInputStyles({ isDisabled: disabled, hasLabel: !!label, noShadow, size });
  const classes = useStyles({ isDisabled: disabled, hasLabel: !!label, noShadow });
  const { values, setFieldValue, errors } = useFormikContext();
  const fieldValue = pathSplit(values, name);
  const [value, setValue] = useState(fieldValue);

  const SelectedKeyboard = noKeyboard ? DatePicker : KeyboardDatePicker;

  useEffect(() => {
    if (typeof fieldValue === 'string') {
      setFieldValue(name, parse(fieldValue, format, new Date()));
    } else if (fieldValue !== value) {
      setValue(typeof fieldValue === 'string' ? parse(fieldValue, format, new Date()) : fieldValue);
    }
  }, [fieldValue]);

  const onChange = (date, text) => {
    setValue(text);
    if (date && isValidDate(date)) {
      setFieldValue(name, date);
    } else {
      setFieldValue(name, null);
    }
  };

  const error = path(name.split('.'), errors);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div></div>
      <FormControl
        className={clsx(classes.formControl, {
          [classes.error]: Boolean(error && !disabled && !hideErrors),
        })}
        classes={{ root: inputClasses.root }}
        error={Boolean(error && !disabled)}
      >
        <SelectedKeyboard
          clearable
          label={label}
          name={name}
          DialogProps={{ className: classes.dialog }}
          format="MM/dd/yyyy"
          value={value === undefined ? null : value}
          onChange={onChange}
          disabled={disabled || false}
          InputLabelProps={{ className: inputClasses.label }}
          inputProps={{ className: inputClasses.inputInput }}
          className={classes.inputBase}
          KeyboardButtonProps={{ className: clsx(classes.calendarButton, inputClasses.icon) }}
          InputAdornmentProps={{ classes: { positionEnd: classes.positionEnd } }}
          minDateMessage={null}
          {...rest}
        />
        {withDateIcon && (
          <Box color="#7C8DA0" className={inputClasses.startIcon}>
            <Icon name="calendar" />
          </Box>
        )}
        {!hideErrors && error && !disabled && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

export const DateInput = ({ name, label, currentValue, onChange: onChangeCb, disabled }) => {
  const classes = useStyles();
  const [value, setValue] = useState();

  useEffect(() => {
    const parsedDate = parse(currentValue, 'yyyy-MM-dd', new Date());
    if (typeof currentValue === 'string') {
      onChange(parsedDate);
    } else if (currentValue !== value) {
      setValue(typeof currentValue === 'string' ? parsedDate : currentValue);
    }
  }, [currentValue]);

  const onChange = (date, text) => {
    setValue(text);
    if (date && isValidDate(date)) {
      onChangeCb(date);
    } else {
      onChangeCb(null);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl className={clsx(classes.formControl)}>
        <KeyboardDatePicker
          label={label}
          name={name}
          format="MM/dd/yyyy"
          value={value}
          onChange={onChange}
          inputVariant="outlined"
          disabled={disabled || false}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};
