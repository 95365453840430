import React from 'react';
import Form from 'components/Form/Form';
import { Box } from '@material-ui/core';
import BasicModalContent from 'components/BasicModal/BasicModalContent/BasicModalContent';
import { useCreateGroup, useEditGroup, useGroupDetail } from 'services/groups/hooks';
import * as yup from 'yup';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import AddNewGroupFooter from '../AddNewGroupFooter/AddNewGroupFooter';
import GroupDetailsForm from './GroupDetailsForm/GroupDetailsForm';

const initialValues = {
  bpHide: false,
  bpOperator: '<',
  brokenPromise: 0,
  cdmHide: false,
  cdmOperator: '=',
  ceaHide: false,
  ceaOperator: '=',
  clientEnteredAmount: 0,
  collectionCodeId: null,
  currentDateMinus: 0,
  daysPastDue: 0,
  dpdHide: false,
  dpdOperator: '=',
  groupName: '',
  id: 1,
  isStatic: false,
  loanStatusCode: [],
  lsHide: false,
  lsOperator: '=',
  nsfCount: 0,
  nsfHide: false,
  nsfOperator: '=',
  pbHide: false,
  pbOperator: '>',
  potentialDaysPastDue: 0,
  potentialDpdHide: false,
  potentialDpdOperator: '=',
  principalBalance: '0',
  regularPaymentAmount: '0',
  rpaHide: false,
  rpaOperator: '=',
  tmdHide: false,
  tmdOperator: '=',
  totalAmountDue: '0.00',
};

const requiredIfSwitchOn = requiredText => ({
  is: true,
  then: yup.string().required(requiredText),
  otherwise: yup.string(),
});
const requiredIfSwitchOnArray = requiredText => ({
  is: true,
  then: yup.array().required(requiredText),
  otherwise: yup.array(),
});

const validationSchema = yup.object({
  groupName: yup.string().required('Group name is required'),
  daysPastDue: yup.string().when('dpdHide', requiredIfSwitchOn('Days past due is required')),
  potentialDaysPastDue: yup
    .string()
    .when('potentialDpdHide', requiredIfSwitchOn('Potential days past due is required')),
  nsfCount: yup.string().when('nsfHide', requiredIfSwitchOn('NFS count is required')),
  totalAmountDue: yup.string().when('tmdHide', requiredIfSwitchOn('Total amount due is required')),
  regularPaymentAmount: yup
    .string()
    .when('rpaHide', requiredIfSwitchOn('Regular payment amount is required')),
  brokenPromise: yup.string().when('bpHide', requiredIfSwitchOn('Broken promise to pay is required')),
  principalBalance: yup.string().when('pbHide', requiredIfSwitchOn('Principal balance is required')),
  loanStatusCode: yup.array().when('lsHide', requiredIfSwitchOnArray('Loan status code is required')).nullable(),
});

export default function GroupDetails({ groupToEditId, onSuccess = () => {}, maxHeight }) {
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const isEdit = !!groupToEditId;
  const { data: groupDetailData, isLoading: isGroupDetailLoading } = useGroupDetail(groupToEditId);
  const queryCache = useQueryCache();

  const [mutate] = useCreateGroup({
    onSuccess: response => {
      if (response?.result) {
        queryCache.invalidateQueries('groups');
        setAlertMessage('Group created successfully!');
        onSuccess(response);
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });
  const [mutateEdit] = useEditGroup({
    onSuccess: response => {
      if (response?.result) {
        queryCache.invalidateQueries('groups');
        queryCache.invalidateQueries('group-detail');
        setAlertMessage('Group updated successfully!');
        onSuccess();
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleOnSubmit = async values => {
    if (isEdit) {
      delete values.clientUserId;
      delete values.createdAt;
      delete values.updatedAt;
      await mutateEdit({...values, loanStatusCode: values.loanStatusCode || []});
      return;
    }
    await mutate({...values, loanStatusCode: values.loanStatusCode || []});
  };

  const parsedGroupDetailData = groupDetailData?.result?.data && ({
    ...groupDetailData?.result?.data,
    loanStatusCode: groupDetailData?.result?.data?.loanStatusCode ? 
                    groupDetailData?.result?.data?.loanStatusCode?.split(',') :
                    []
  })

  return (
    <Box>
      <Box flex={1}>
        {isGroupDetailLoading || groupDetailData?.result?.data.id !== groupToEditId ? (
          <SpinnerWrapped />
        ) : (
          <Form
            initialValues={parsedGroupDetailData || initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
          >
            <BasicModalContent>
              <GroupDetailsForm maxHeight={maxHeight} />
              <AddNewGroupFooter isEdit={isEdit} />
            </BasicModalContent>
          </Form>
        )}
      </Box>
    </Box>
  );
}
