import React, { useEffect } from 'react';
import { useScheduleFrequency } from 'services/payments/hooks';
import { useFormikContext, useField } from 'formik';
import { useBorrowerDetails } from 'services/loans/hooks';
import Text from 'components/Text/Text';
import { Box } from '@material-ui/core';
import Spinner from 'components/Spinner/Spinner';
import BasicCheckbox from 'components/BasicInputs/BasicCheckbox/BasicCheckbox';
import { useClientDetailsContext } from 'services/client/context';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';

export default function ScheduleCount() {
  const { values } = useFormikContext();
  const { allowCustSchedCount } = useClientDetailsContext();
  const { data: borrowerDetails } = useBorrowerDetails();
  const [field, meta, helpers] = useField('scheduleCount');
  const { data, isLoading } = useScheduleFrequency(
    values.loanAmount,
    values.scheFreq,
    borrowerDetails.loanId,
  );

  useEffect(() => {
    if (data?.frequency && !values.overrideSchCount) {
      helpers.setValue(data.frequency);
    }
  }, [data, values.overrideSchCount]);

  return (
    <Box>
      <Text
        textAlign="left"
        fontSize="0.875rem"
        letterSpacing="-0.14px"
        lineHeight="0.875rem"
        color="common.label"
        marginBottom="0.5rem"
      >
        Schedule Count
      </Text>
      <Text
        textAlign="left"
        color="rgba(34, 47, 62, 0.87);"
        fontWeight={500}
        fontSize="1.125rem"
        lineHeight="0.875rem"
        letterSpacing="-0.18px"
        display="flex"
        alignItems="center"
        height="1.5rem"
      >
        {values.overrideSchCount ? <BasicTextField size="xxs" type="number" name="scheduleCount" /> : (
          <>
            {field.value || 0}
            {isLoading && (
              <Box marginLeft="0.5rem">
                <Spinner size={16} />
              </Box>
            )}
          </>
        )}
      </Text>
      {allowCustSchedCount && (
        <Box>
          <BasicCheckbox name="overrideSchCount" label="Override schedule count" />
        </Box>
      )}
    </Box>
  );
}
