import React from 'react';
import { useFormikContext, yupToFormErrors } from 'formik';
import { to } from 'shared/utils';

export default function useFormikHelpers() {
  const { setErrors, values, isValid, dirty } = useFormikContext();

  const validateForm = async (validationSchema) => {
    const [validationErrors] = await to(
      validationSchema.validate(values, {
        abortEarly: false,
      }),
    );
    if (validationErrors) {
      setErrors(yupToFormErrors(validationErrors));
    }
    return validationErrors;
  };
  return { validateForm, isFormInvalid: !(isValid && dirty) };
}
