import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { useDetails, useUploadLogoPlaceholder } from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import SettingsSectionUploadImage from '../../Shared/SettingsSection/SettingsSectionUploadImage/SettingsSectionUploadImage';
import useBTC from '../useBTC';

export default function LogoPlaceholder() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUploadLogoPlaceholder({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Logo placeholder updated successfully!' });
    },
  });

  const initialValues = useMemo(
    () => ({
      secImageUrl: data.secImageUrl,
    }),
    [data],
  );

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      image: values.secImageUrl,
    });
    await mutate(formData);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSectionUploadImage
        title="Logo Placeholder"
        description="This is a default logo placeholder for photo replacement"
        name="secImageUrl"
      />
    </Form>
  );
}
