import React from 'react';

export default function DeleteAltIcon({ width, height }) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8a1 1 0 0 1 1 1v10a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V9a1 1 0 0 1 1-1h10zm-1 2H8v9a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-9zM9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h4a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2h4V3z"
        fillOpacity=".88"
      />
    </svg>
  );
}
