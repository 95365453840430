import React from 'react';
import { Box, Grid } from '@material-ui/core';

export default function BasicCardSecondaryContent({ children, ...rest }) {
  return (
    <Box width="100%" marginTop="0.25rem" marginBottom="0.75rem">
      <Grid container spacing={2} {...rest}>
        {children}
      </Grid>
    </Box>
  );
}
