import React from 'react';

export default function MobileAltIcon({ width, height }) {
  return (
    <svg
      width={width || 11}
      height={height || 16}
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 12.487v1.295c0 .872-.682 1.582-1.52 1.582H2.434c-.838 0-1.52-.71-1.52-1.582v-1.295h9.675zm-4.285 1.007H5.199a.423.423 0 0 0-.414.431c0 .239.185.432.414.432h1.106a.423.423 0 0 0 .415-.431.423.423 0 0 0-.415-.432zM9.069.636c.839 0 1.52.71 1.52 1.583v9.405H.916V2.22c0-.873.682-1.583 1.52-1.583h6.634z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
    </svg>
  );
}
