import React from 'react';

export default function EyeIcon({ height = '12px', width = '13px' }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.68 6.38a8.512 8.512 0 0 0 1.063 1.46c.798.874 1.9 1.673 3.257 1.673 1.357 0 2.46-.799 3.256-1.673a8.517 8.517 0 0 0 1.064-1.46 8.517 8.517 0 0 0-1.063-1.46C8.458 4.044 7.356 3.245 6 3.245c-1.357 0-2.46.799-3.256 1.673A8.512 8.512 0 0 0 1.68 6.38zm9.27 0l.491-.257V6.12l-.003-.003-.005-.011a5.415 5.415 0 0 0-.088-.167 9.647 9.647 0 0 0-1.289-1.803C9.166 3.16 7.793 2.107 6 2.107c-1.793 0-3.166 1.053-4.056 2.03A9.646 9.646 0 0 0 .586 6.07a3.618 3.618 0 0 0-.02.037l-.005.01-.001.004v.001H.558l.491.257-.491-.256a.588.588 0 0 0 0 .513l.491-.257-.491.257v.002l.002.003.006.011a3.6 3.6 0 0 0 .088.167 9.646 9.646 0 0 0 1.289 1.803c.89.977 2.263 2.03 4.056 2.03 1.793 0 3.166-1.053 4.056-2.03a9.647 9.647 0 0 0 1.358-1.933l.02-.037.005-.01.001-.004v-.001l.001-.001-.491-.257zm0 0l.491.256a.587.587 0 0 0 0-.513l-.491.256zm-9.41-.257zM6 5.56a.81.81 0 0 0-.8.818.81.81 0 0 0 .8.82c.445 0 .8-.371.8-.82a.81.81 0 0 0-.8-.818zm-1.9.818c0-1.085.854-1.958 1.9-1.958 1.046 0 1.9.873 1.9 1.958 0 1.086-.854 1.959-1.9 1.959-1.046 0-1.9-.873-1.9-1.959z"
        fill="#008949"
      />
    </svg>
  );
}
