import React from 'react';
import { Box } from '@material-ui/core';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import FlexRow from 'components/FlexRow/FlexRow';
import { useUpdateVisibleOnApp } from 'services/client/hooks';
import { useQueryCache } from 'react-query';
import produce from 'immer';
import { useAlertContext } from 'shared/contexts/useAlertContext';

export default function InformationInput({
  name,
  label,
  inputPlaceholder,
  switchName,
  inputComponent,
}) {
  const [mutate] = useUpdateVisibleOnApp({
    statusName: `${name}Status`,
  });

  const handleUpdateVisible = (e) => {
    mutate({
      [name]: 1,
      status: e.target.checked,
    });
  };

  return (
    <FlexRow alignItems="flex-start" marginBottom="0.75rem">
      <Box flex={1} marginRight="1.25rem">
        {inputComponent || (
          <BasicTextField name={name} label={label} placeholder={inputPlaceholder} />
        )}
      </Box>
      <Box paddingTop="0.2rem">
        <BasicSwitch onChange={handleUpdateVisible} name={switchName} label="VISIBLE ON APP" />
      </Box>
    </FlexRow>
  );
}
