import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import {
  useBackgroundImage,
  useBackgroundImages,
  useSetSignInImage,
  useUploadBackgroundImage,
} from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import SettingsSection from '../../Shared/SettingsSection/SettingsSection';
import SignInImageForm from './SignInImageForm/SignInImageForm';
import useBTC from '../useBTC';

export default function SignInImage() {
  const { data: selectedBackgroundImage } = useBackgroundImage();
  const { data } = useBackgroundImages();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUploadBackgroundImage({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Sign In Image updated successfully!' });
    },
  });
  const [mutateSetClientImage] = useSetSignInImage({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Sign In Image updated successfully!' });
    },
  });

  const initialValues = useMemo(
    () => ({
      selectedBackgroundImage: selectedBackgroundImage.background_set_image,
      background_image1: data.background_image1,
      background_image2: data.background_image2,
      background_image3: data.background_image3,
    }),
    [selectedBackgroundImage, data],
  );

  const updateImageSubmit = async (type, image) => {
    if (initialValues[`background_image${type}`] === image) return;
    const formData = new FormData();
    buildFormData(formData, {
      type,
      image,
    });
    await mutate(formData);
  };

  const setClientImage = async (imageType) => {
    if (imageType === initialValues.selectedBackgroundImage) return;
    await mutateSetClientImage({
      imageType,
    });
  };

  const handleOnSubmit = async (values) => {
    await Promise.allSettled([
      updateImageSubmit(1, values.background_image1),
      updateImageSubmit(2, values.background_image2),
      updateImageSubmit(3, values.background_image3),
      setClientImage(values.selectedBackgroundImage),
    ]);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSection title="Sign In Image" description="Sets the Sign In Image for the Portal">
        <SignInImageForm />
      </SettingsSection>
    </Form>
  );
}
