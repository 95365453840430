import React from 'react';
import { Box } from '@material-ui/core';

export default function BasicCardWrapper({ children, ...rest }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="0 1 auto"
      padding="1rem 0.875rem 0.5rem"
      {...rest}
    >
      {children}
    </Box>
  );
}
