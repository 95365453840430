import React from 'react';

export default function UploadIcon({ width, height }) {
  return (
    <svg
      width={width || '43'}
      height={height || '42'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 42"
    >
      <path
        d="M39.59 31.5v5.25a2.635 2.635 0 0 1-2.639 2.625H5.28a2.635 2.635 0 0 1-2.64-2.625V31.5c0-.724.59-1.313 1.32-1.313.73 0 1.32.588 1.32 1.313v5.25H36.95V31.5c0-.724.59-1.313 1.32-1.313.73 0 1.32.588 1.32 1.313z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
      <path
        d="M10.944 14.053a1.306 1.306 0 0 1 0-1.856l9.237-9.186a1.33 1.33 0 0 1 1.868 0l9.237 9.186a1.306 1.306 0 0 1 0 1.856 1.32 1.32 0 0 1-.933.384 1.32 1.32 0 0 1-.933-.384l-6.985-6.947v23.082c0 .724-.59 1.312-1.32 1.312-.73 0-1.32-.588-1.32-1.313V7.107l-6.985 6.947a1.323 1.323 0 0 1-1.866 0z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
    </svg>
  );
}
