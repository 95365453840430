import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getLoanDetails,
  getBorrowerDetails,
  removeLoan,
  removeLoans,
  resetInstallPassword,
  addLoan,
} from './api';

// eslint-disable-next-line import/prefer-default-export
export function useLoanDetails(account) {
  return useQuery(['loan-details', { account, channel: 'LMS' }], getLoanDetails, {
    staleTime: 0,
  });
}

export function useBorrowerDetails(loanId) {
  const { id } = useParams();

  return useQuery(['borrower-details', { loanId: loanId || id }], getBorrowerDetails, {
    staleTime: 1000 * 60 * 5,
  });
}

export const useRemoveLoan = (options = {}) => {
  return useMutation(removeLoan, {
    ...options,
  });
};

export const useRemoveLoans = (options = {}) => {
  return useMutation(removeLoans, {
    ...options,
  });
};

export const useResetInstallPassword = (options = {}) => {
  return useMutation(resetInstallPassword, {
    ...options,
  });
};

export const useAddLoan = (options = {}) => {
  return useMutation(addLoan, {
    ...options,
  });
};
