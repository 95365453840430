import { Box } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicTable from 'components/BasicTable/BasicTable';
import Center from 'components/Center/Center';
import EmptyState from 'components/EmptyState/EmptyState';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import MoneyFormat from 'components/Formatters/MoneyFormat/MoneyFormat';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useImportNACHA } from 'services/client/hooks';
import { dateWithSlashes } from 'shared/utils';

const columns = [
  { id: 'fileName', label: 'File Name', minWidth: 100 },
  { id: 'reference', label: 'Reference', minWidth: 100 },
  {
    id: 'upload',
    label: 'Upload',
    minWidth: 100,
  },
  { id: 'processDate', label: 'Process Date', minWidth: 100 },
  { id: 'count', label: 'Count', minWidth: 100 },
  { id: 'batchName', label: 'Batch Name', minWidth: 100 },
  { id: 'batchTotal', label: 'Batch Total', minWidth: 100 },
  { id: 'resultsDate', label: 'Results Date', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
];

function createData(
  fileName,
  reference,
  upload,
  processDate,
  count,
  batchName,
  batchTotal,
  resultsDate,
  status,
) {
  return {
    fileName,
    reference,
    upload,
    processDate,
    count,
    batchName,
    batchTotal,
    resultsDate,
    status,
  };
}

export default function NACHAUploadTable() {
  const { isLoading, data } = useImportNACHA();
  const rows = useMemo(() => {
    if (data) {
      return data.listAch.map((item) => {
        const array = createData(
          item.achImport.customName || item.achImport.filename,
          item.achImport.control,
          format(new Date(item.achImport.createdAt.date), 'MM/dd/yyyy p'),
          dateWithSlashes(item.processDate.date),
          item.nachaCnt,
          item.eftFileName || 'N/A',
          <MoneyFormat value={item.totalAmount} />,
          item.resultAvailableDate?.date || 'N/A',
          item.achImport.status === 1 ? 'Pending' : 'Completed',
        );
        return array;
      });
    }
    return [];
  }, [data]);

  return (
    <BasicCard noPadding overflow="auto" height="20rem">
      <FlexColumn height="100%">
        {isLoading ? (
          <SpinnerWrapped />
        ) : (
          <>
            {data.listAch.length ? (
              <BasicTable rows={rows} columns={columns} />
            ) : (
              <EmptyState variant="nachaFiles" />
            )}
          </>
        )}
      </FlexColumn>
    </BasicCard>
  );
}
