/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import BasicTable from 'components/BasicTable/BasicTable';
import { usePaymentHistory } from 'services/payments/hooks';
import Spinner from 'components/Spinner/Spinner';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import EmptyState from 'components/EmptyState/EmptyState';
import { useQueryCache } from 'react-query';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import SpinnerBoxed from 'components/Spinner/SpinnerBoxed/SpinnerBoxed';
import { PLATFORMS } from 'shared/constants/platforms';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import PERMISSIONS from 'shared/constants/permissions';
import Form from 'components/Form/Form';
import { useStyles } from '../borrowerStyles';
import Refund from '../disclaimers/Refund';
import { postRefund } from '../../../api/Borrowers';
import PaymentHistoryActions from './PaymentHistoryActions/PaymentHistoryActions';

function createData(amount, refunded, authCd, confNumber, pmtMethod, date, madeBy, refund) {
  return { amount, refunded, authCd, confNumber, pmtMethod, date, madeBy, refund };
}

function checkForAmount(amount) {
  if (amount) {
    return amount;
  }
  return 0;
}

export default function PaymentHistory() {
  const { canUserAccessTo } = useCurrentUser();
  const classes = useStyles();
  const [showDisc, setShowDisc] = React.useState(false);
  const [paymentAmt, setPmtAmt] = React.useState(0);
  const [pmtId, setPmtId] = React.useState(null);
  const [pmtError, setPmtError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();

  const columns = useMemo(() => {
    const array = [
      { id: 'amount', label: 'Amount', minWidth: 60, align: 'right' },
      { id: 'refunded', label: 'Refunded', minWidth: 60, align: 'right' },
      { id: 'authCd', label: 'Auth Cd', minWidth: 60 },
      { id: 'confNumber', label: 'Conf #', minWidth: 60 },
      { id: 'pmtMethod', label: 'PMT Method', minWidth: 90 },
      { id: 'date', label: 'Date', minWidth: 80 },
      { id: 'madeBy', label: 'Made By', minWidth: 60, maxWidth: 100, withTitle: true },
    ];
    if (
      canUserAccessTo(PERMISSIONS.PAYMENTS.REFUND_CARD) ||
      canUserAccessTo(PERMISSIONS.PAYMENTS.VOID_ACH)
    ) {
      array.push({ id: 'refund', label: 'Refund', minWidth: 60 });
    }
    return array;
  }, []);

  const { data, isLoading } = usePaymentHistory();

  function handleRefund(val, id) {
    setPmtAmt(val);
    setShowDisc(true);
    setPmtId(id);
  }

  function handleClosePayment() {
    setShowDisc(false);
    setPmtAmt(0);
    setPmtError(null);
  }

  function handleSubmit(values) {
    setLoading(true);
    postRefund(pmtId, values.option === '0', values.amount)
      .then((res) => {
        if (res.data.status === 1) {
          setShowDisc(false);
          setPmtId(null);
          queryCache.invalidateQueries('payment-history', {
            refetchInactive: true,
            refetchActive: true,
          });
          setAlertMessage('Refund successful!');
        } else {
          setErrorAlertMessage(res.data.message);
        }
      })
      .catch((e) => {
        setErrorAlertMessage('Refund error please try again');
        setShowDisc(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (isLoading) {
    return <SpinnerBoxed />;
  }

  const rows = data.receipts.map((data) => {
    const array = createData(
      data.paymentsMade
        ? `$${(
            checkForAmount(data.paymentsMade.pmtAmt) +
            checkForAmount(data.paymentsMade.cardTransactionAmount) +
            checkForAmount(data.paymentsMade.achTransactionAmount) +
            checkForAmount(data.paymentsMade.platformTransactionFees)
          ).toFixed(2)}`
        : `$${data.paymentAmount.toFixed(2)}`,
      data.paymentsMade ? `$${data.paymentsMade.refundedAmt.toFixed(2)}` : '$0.00',
      data.paymentsMade?.authId ? data.paymentsMade?.authId : <span>N/A</span>,
      data.paymentsMade?.orderId ? data.paymentsMade?.orderId : <span>N/A</span>,
      data.paymentsMade ? (
        data.paymentsMade.cardMethod ? (
          '**' +
          data.paymentsMade.cardMethod.lastFour +
          `(${PLATFORMS[data?.paymentsMade?.platformId]})`
        ) : (
          '**' +
          data.paymentsMade.achMethod.lastFour +
          `(${PLATFORMS[data?.paymentsMade?.platformId]})`
        )
      ) : (
        <span className={classes.alertTitle}>LMS</span>
      ),
      data.datePaid?.date ? data.datePaid.date.substring(0, 10) : '-',
      data.paymentsMade && data.paymentsMade.madeBy ? data.paymentsMade.madeBy : 'N/A',
      data.paymentsMade ? <PaymentHistoryActions payment={data.paymentsMade} /> : 'N/A',
    );
    return array;
  });

  return (
    <Box overflow="scroll">
      {rows.length ? (
        <BasicTable rows={rows} columns={columns} />
      ) : (
        <Box padding="2rem">
          <EmptyState variant="payments" />
        </Box>
      )}

      {showDisc && (
        <Refund
          openRefund={showDisc}
          val={paymentAmt}
          handleClosePayment={handleClosePayment}
          error={pmtError}
          loading={loading}
          pmtId={pmtId}
        />
      )}
    </Box>
  );
}
