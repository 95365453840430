import React from 'react';

export default function PersonIcon({ width = '10px', height = '11px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 7.536h5c1.38 0 2.5 1.159 2.5 2.589a.51.51 0 0 1-.5.518.507.507 0 0 1-.497-.458l-.006-.151c-.043-.786-.65-1.414-1.409-1.46L7.5 8.571h-5c-.828 0-1.5.696-1.5 1.554a.51.51 0 0 1-.5.518.51.51 0 0 1-.5-.518c0-1.392 1.061-2.528 2.392-2.587l.108-.002h5-5zM5 .286c1.657 0 3 1.39 3 3.107C8 5.109 6.657 6.5 5 6.5S2 5.109 2 3.393C2 1.677 3.343.286 5 .286zM5 1.32c-1.105 0-2 .928-2 2.072 0 1.144.895 2.071 2 2.071s2-.927 2-2.071C7 2.249 6.105 1.32 5 1.32z"
        fill="#008949"
      />
    </svg>
  );
}
