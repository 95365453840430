import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import BasicInput from 'components/BasicInputs/BasicInput';
import BasicChip from 'components/BasicChip/BasicChip';
import { FieldArray, useField } from 'formik';

export default function RulesFormLoansList() {
  const [inputValue, setInputValue] = useState('');
  const [field, meta, helpers] = useField('loanNumber');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleKeyDown = (event, arrayHelpers) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValue.trimEnd() !== '') {
        setInputValue('');
        arrayHelpers.push(inputValue);
      }
    }
  };

  return (
    <Box>
      <FieldArray
        name="loanNumber"
        render={(arrayHelpers) => (
          <>
            <Box marginBottom="1rem" maxWidth="400px">
              <BasicInput
                onChange={handleChange}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e, arrayHelpers)}
                placeholder="Type an account number and press ENTER"
                error={meta.error}
                touched
              />
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {field.value?.map((loan, index) => (
                <BasicChip
                  color="primary"
                  label={loan}
                  key={loan}
                  onDelete={() => arrayHelpers.remove(index)}
                />
              ))}
            </Box>
          </>
        )}
      />
    </Box>
  );
}
