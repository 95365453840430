import { makeStyles } from '@material-ui/core';

const useImagesMixins = makeStyles((theme) => ({
  objectFitCover: {
    objectFit: 'cover',
  },
  objectFitContain: {
    objectFit: 'contain',
  },
}));

export default useImagesMixins;
