import React, {
  useContext,
  useMemo,
  createContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import produce from 'immer';
import WebPop from '../WebPop';

const WebPopSwitchContext = createContext();

export const WEBPOP_COMPONENTS = {
  PAYMENTS_TABS: 'PAYMENTS_TABS',
  EDIT_PAYMENTS: 'EDIT_PAYMENTS',
  ADD_PAYMENTS: 'ADD_PAYMENTS',
  PAYMENT_RESPONSE: 'PAYMENT_RESPONSE',
  DISCLAIMER: 'DISCLAIMER',
  EDIT_SCHEDULED_PAYMENT: 'EDIT_SCHEDULED_PAYMENT',
};

export function WebPopSwitchProvider({ children }) {
  const [active, setActive] = useState(null);
  const [activeComponentInfo, setActiveComponentInfo] = useState(null);
  const [initialTab, setInitialTab] = useState(null);
  const [history, setHistory] = useState([]);

  const goBack = useCallback(() => {
    const updatedHistory = produce(history, draft => {
      const lastItem = draft.pop();
      setActive(lastItem);
    });
    setHistory(updatedHistory);
  }, [setActive, history]);
  const onClose = useCallback(() => {
    setActive(null);
    setHistory([]);
  }, [setActive]);

  const pushHistory = useCallback(
    component => {
      setHistory(
        produce(history, draft => {
          if (active) {
            draft.push(active);
          }
        }),
      );
      setActive(component);
    },
    [history, active],
  );

  const hasBack = useMemo(() => !!history.length, [history?.length]);

  const value = useMemo(
    () => ({
      active,
      setActive,
      goBack,
      setActiveComponentInfo,
      activeComponentInfo,
      onClose,
      initialTab,
      setInitialTab,
      history,
      pushHistory,
      hasBack,
    }),

    [
      active,
      goBack,
      activeComponentInfo,
      setActiveComponentInfo,
      onClose,
      history,
      pushHistory,
      hasBack,
    ],
  );

  return (
    <WebPopSwitchContext.Provider value={value}>
      {children}
      {active && <WebPop initialTab={initialTab} isOpen={!!active} onClose={onClose} />}
    </WebPopSwitchContext.Provider>
  );
}

export function useWebPopSwitch() {
  const context = useContext(WebPopSwitchContext);
  if (!context) {
    throw new Error(`useWebPopSwitch must be used within a WebPopSwitchProvider`);
  }
  return context;
}
