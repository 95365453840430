import React from 'react';

export default function PenIcon({ width, height, fill = '#7C8DA0' }) {
  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.497 11.778l9.281-9.28a.844.844 0 0 1 1.114-.071l.08.07 2.53 2.531a.844.844 0 0 1 .071 1.114l-.07.08-9.281 9.28a.844.844 0 0 1-.486.24l-.111.008H3.094a.844.844 0 0 1-.838-.745l-.006-.099v-2.531c0-.187.062-.367.174-.513l.073-.084 9.281-9.28-9.28 9.28zm9.878-7.491l-8.438 8.438v1.338h1.339l8.437-8.438-1.338-1.338z"
        fill={fill}
      />
    </svg>
  );
}
