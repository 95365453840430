import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import { Grid } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import {
  useWebPopSwitch,
  WEBPOP_COMPONENTS,
} from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { useClientDetailsContext } from 'services/client/context';
import { WEBPOP_TABS } from '../../../../constants';

export default function DetailButtons() {
  const { setActive, setInitialTab } = useWebPopSwitch();
  const { hideSchedule } = useClientDetailsContext();

  const handleSetActiveTab = (initialTab) => {
    setActive(WEBPOP_COMPONENTS.PAYMENTS_TABS);
    setInitialTab(initialTab);
  };

  return (
    <BasicCardWrapper padding="1.5rem 1.125rem 1.5rem">
      <CardContent>
        <Grid container spacing={3}>
          {!hideSchedule && (
            <AccessControl perform={PERMISSIONS.PAYMENTS.SCHEDULE}>
              <Grid item xs>
                <ContainedButton
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSetActiveTab(WEBPOP_TABS.SCHEDULE_PAYMENT)}
                >
                  Schedule
                </ContainedButton>
              </Grid>
            </AccessControl>
          )}
          <AccessControl perform={PERMISSIONS.PAYMENTS.MAKE}>
            <Grid item xs>
              <ContainedButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleSetActiveTab(WEBPOP_TABS.MAKE_PAYMENT)}
              >
                Make Payment
              </ContainedButton>
            </Grid>
          </AccessControl>
        </Grid>
      </CardContent>
    </BasicCardWrapper>
  );
}
