import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import useMixinStyles from 'styles/mixins';
import clsx from 'clsx';

export default function Form({
  children,
  initialValues,
  onSubmit,
  validationSchema,
  className,
  ...rest
}) {
  const mixinClasses = useMixinStyles();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      {...rest}
    >
      <FormikForm className={clsx(className, mixinClasses.full)}>{children}</FormikForm>
    </Formik>
  );
}
