import React from 'react';
import { Route, Router as ReactRouter } from 'react-router-dom';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import { QueryParamProvider } from 'use-query-params';
import history from './history';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import AuthenticatedRoutes from './AuthenticatedRoutes';

export default function Router() {
  const { currentUser } = useCurrentUser();
  return (
    <ReactRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        {currentUser ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
      </QueryParamProvider>
    </ReactRouter>
  );
}
