import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import React from 'react';

export default function SettingsSectionHeader({ title, description }) {
  return (
    <Box marginBottom="1.5rem">
      <Text
        fontSize="0.8125rem"
        fontWeight={500}
        lineHeight="0.875rem"
        marginBottom="0.25rem"
        letterSpacing="-0.13px"
      >
        {title}
      </Text>
      {description && (
        <Text
          fontSize="0.625rem"
          lineHeight="0.875rem"
          letterSpacing="0.25px"
          color="rgba(34, 47, 62, 0.5)"
        >
          {description}
        </Text>
      )}
    </Box>
  );
}
