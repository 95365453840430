import React from 'react';

export default function MessagesIcon({ height = '12px', width = '12px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.678 1H2.322C1.587 1 1 1.601 1 2.333v5.934l.002.078c.04.704.612 1.322 1.32 1.322H4.45l1.228 1.203.043.037a.462.462 0 0 0 .6-.037L7.55 9.667h2.129c.735 0 1.322-.664 1.322-1.4V2.333C11 1.601 10.413 1 9.678 1zm-7.356.889h7.356c.223 0 .413.194.413.444v5.934c0 .271-.212.51-.413.51H7.36l-.06.005a.459.459 0 0 0-.262.126L6 9.926 4.962 8.908a.46.46 0 0 0-.322-.13H2.322c-.2 0-.413-.24-.413-.511V2.333c0-.25.19-.444.413-.444zm5.496 2a.45.45 0 0 1 .455.444.448.448 0 0 1-.402.442l-.053.003H4.182a.45.45 0 0 1-.455-.445c0-.228.176-.415.402-.441l.053-.003h3.636zM6.455 6.11A.45.45 0 0 0 6 5.667H4.182l-.053.003a.448.448 0 0 0-.402.441.45.45 0 0 0 .455.445H6l.053-.003a.448.448 0 0 0 .402-.442z"
        fill="currentColor"
      />
    </svg>
  );
}
