import React from 'react';
import UnauthenticatedLayout from 'components/UnauthenticatedLayout/UnauthenticatedLayout';
import { Redirect, Switch } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import Home from 'pages/Home/Home';
import Login from 'pages/Home/Login/Login';
import ForgotPassword from 'pages/Home/ForgotPassword/ForgotPassword';
import ResetPassword from 'pages/Home/ResetPassword/ResetPassword';

const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    component: UnauthenticatedLayout,
    routes: [
      {
        path: '/',
        key: 'HOME',
        component: Home,
        routes: [
          {
            path: '/',
            key: 'LOGIN',
            exact: true,
            component: Login,
          },
          {
            path: '/forgot-password',
            key: 'FORGOT_PASSWORD',
            exact: true,
            component: ForgotPassword,
          },
          {
            path: '/reset-password/:resetCode',
            key: 'RESET_PASSWORD',
            exact: true,
            component: ResetPassword,
          },
          {
            path: '*',
            key: 'REDIRECT',
            exact: true,
            component: () => <Redirect to="/" />,
          },
        ],
      },
      {
        path: '*',
        key: 'REDIRECT',
        exact: true,
        component: () => <Redirect to="/" />,
      },
    ],
  },
];

export default function UnauthenticatedRoutes() {
  return (
    <Switch>
      {ROUTES.map((route) => (
        <RouteWithSubRoutes key={route.key} {...route} />
      ))}
    </Switch>
  );
}
