import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useClientDetailsContext } from 'services/client/context';
import { isACH } from 'shared/utils/webpop';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import WebPopActions from 'components/WebPop/WebPopActions/WebPopActions';
import WebPopSection from 'components/WebPop/WebPopSection/WebPopSection';
import PaymentDetailsContent from 'components/WebPop/WebPopForm/PaymentDetails/PaymentDetailsContent/PaymentDetailsContent';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import PaymentMethods from '../../../WebPopForm/PaymentMethods/PaymentMethods';
import PaymentAmount from '../../../WebPopForm/PaymentAmount/PaymentAmount';
import PaymentDate from '../../../WebPopForm/PaymentDate/PaymentDate';
import ConvenienceFee from '../../../WebPopForm/ConvenienceFee/ConvenienceFee';
import WebPopContent from '../../../WebPopContent/WebPopContent';

export default function MakePaymentForm({ onDisclaimerOpen }) {
  const { submitForm, values } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  const { onClose } = useActiveTab();
  const {
    achDisclosure,
    cardDisclosure,
    hideDateSelectOnMakePmt,
    webpopRefund,
  } = useClientDetailsContext();

  const handleMakePaymentClick = async () => {
    const isACHPayment = isACH(values.paymentMethodData.paymentType);
    if ((isACHPayment && achDisclosure) || (!isACHPayment && cardDisclosure)) {
      onDisclaimerOpen();
      return;
    }
    await submitForm();
  };

  return (
    <WebPopContent>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PaymentMethods />
        </Grid>
        <Grid item xs={12}>
          <WebPopSection title="Payment Details">
            <PaymentDetailsContent>
              <PaymentAmount
                showRefund={
                  values.paymentMethodData?.paymentType &&
                  !isACH(values.paymentMethodData.paymentType) &&
                  webpopRefund
                }
              />
              <ConvenienceFee />
            </PaymentDetailsContent>
          </WebPopSection>
        </Grid>
      </Grid>
      <WebPopActions
        primaryButtonText={values.refund ? 'Credit Payment' : 'Make Payment'}
        disabled={isFormInvalid}
        onPrimaryButtonClick={handleMakePaymentClick}
        onSecondaryButtonClick={onClose}
      />
    </WebPopContent>
  );
}
