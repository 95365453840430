import React from 'react';

export default function AddAltIcon({ width, height }) {
  return (
    <svg
      width={width || 29}
      height={height || 27}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.501.5c.944 0 1.708.728 1.708 1.625v9.75H26.46c.944 0 1.708.727 1.708 1.625s-.764 1.625-1.708 1.625H16.21v9.75c0 .898-.765 1.625-1.709 1.625-.943 0-1.708-.727-1.708-1.625v-9.75H2.543c-.944 0-1.71-.727-1.71-1.625s.766-1.625 1.71-1.625h10.25v-9.75c0-.897.765-1.625 1.708-1.625z"
        fill="#008949"
      />
    </svg>
  );
}
