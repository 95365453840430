import React from 'react';

export default function InstallationIcon({ width, height }) {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Line expand"
      width={width || 512}
      height={height || 512}
      viewBox="0 0 512 512"
    >
      <path d="M80 72h52.687l5.656 5.657A8 8 0 0 0 144 80h96a8 8 0 0 0 5.657-2.343L251.313 72H304v136h16V44a28.032 28.032 0 0 0-28-28H92a28.032 28.032 0 0 0-28 28v76h16zm12-40h200a12.013 12.013 0 0 1 12 12v12h-56a8 8 0 0 0-5.657 2.343L236.687 64h-89.374l-5.656-5.657A8 8 0 0 0 136 56H80V44a12.013 12.013 0 0 1 12-12zM80 468v-20h104v-16H80V136H64v332a28.032 28.032 0 0 0 28 28h172v-16H92a12.013 12.013 0 0 1-12-12z" />
      <path d="M168 40h48v16h-48zM104 352h16v16h-16zM136 352h16v16h-16zM256 192a8 8 0 0 0-8 8v32h16v-24h16a8 8 0 0 0 8-8v-16a8 8 0 0 0-2.934-6.191l-88-72a8 8 0 0 0-10.132 0l-88 72A8 8 0 0 0 96 184v16a8 8 0 0 0 8 8h16v88a8 8 0 0 0 8 8h40a8 8 0 0 0 8-8v-64a16 16 0 0 1 32 0h16a32 32 0 0 0-64 0v56h-24v-88a8 8 0 0 0-8-8h-16v-4.209l80-65.454 80 65.454V192z" />
      <path d="M440 328h-29.018a103.446 103.446 0 0 0-6.368-15.359l20.523-20.523a8 8 0 0 0 0-11.314L391.2 246.862a8 8 0 0 0-11.314 0l-20.523 20.524A103.446 103.446 0 0 0 344 261.018V232a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v29.018a103.446 103.446 0 0 0-15.359 6.368l-20.523-20.524a8 8 0 0 0-11.314 0L198.863 280.8a8 8 0 0 0 0 11.314l20.523 20.523A103.446 103.446 0 0 0 213.018 328H184a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h29.018a103.446 103.446 0 0 0 6.368 15.359l-20.523 20.523a8 8 0 0 0 0 11.314l33.937 33.942a8 8 0 0 0 11.314 0l20.523-20.524A103.446 103.446 0 0 0 280 458.982V488a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-29.018a103.446 103.446 0 0 0 15.359-6.368l20.523 20.524a8 8 0 0 0 11.314 0l33.941-33.938a8 8 0 0 0 0-11.314l-20.523-20.523A103.446 103.446 0 0 0 410.982 392H440a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm-8 48h-27.024a8 8 0 0 0-7.748 6.006 87.439 87.439 0 0 1-9.407 22.692 8 8 0 0 0 1.231 9.727l19.115 19.114-22.628 22.628-19.115-19.116a8 8 0 0 0-9.727-1.23 87.377 87.377 0 0 1-22.691 9.407 8 8 0 0 0-6.006 7.748V480h-32v-27.024a8 8 0 0 0-6.006-7.748 87.377 87.377 0 0 1-22.691-9.407 8 8 0 0 0-9.727 1.23l-19.115 19.116-22.628-22.628 19.115-19.114a8 8 0 0 0 1.231-9.727 87.439 87.439 0 0 1-9.407-22.692 8 8 0 0 0-7.748-6.006H192v-32h27.024a8 8 0 0 0 7.748-6.006 87.439 87.439 0 0 1 9.407-22.692 8 8 0 0 0-1.231-9.727l-19.115-19.114 22.628-22.628 19.115 19.116a8 8 0 0 0 9.727 1.23 87.377 87.377 0 0 1 22.691-9.407 8 8 0 0 0 6.006-7.748V240h32v27.024a8 8 0 0 0 6.006 7.748 87.377 87.377 0 0 1 22.691 9.407 8 8 0 0 0 9.727-1.23l19.115-19.116 22.628 22.628-19.115 19.114a8 8 0 0 0-1.231 9.727 87.439 87.439 0 0 1 9.407 22.692 8 8 0 0 0 7.748 6.006H432z" />
      <path d="M312 304a56 56 0 1 0 52.812 74.666l-15.086-5.332A40.034 40.034 0 1 1 352 360h16a56.063 56.063 0 0 0-56-56z" />
    </svg>
  );
}
