// eslint-disable-next-line import/prefer-default-export
export const PAYMENT_TYPES = {
  CREDIT_CARD: 1,
  ACH: 2,
  PAD: 3,
};

export const DEBIT_CARD_API_KEYS = [
  'name',
  'cardNumber',
  'cvvNumber',
  'billingZip',
  'channel',
  'doNotSavePaymentMethods',
  'expiryDate',
];
export const ACH_API_KEYS = [
  'name',
  'routingNumber',
  'accountNumber',
  'bankName',
  'channel',
  'accountType',
  'doNotSavePaymentMethods',
];

export const PAD_API_KEYS = [
  'account',
  'name',
  'accountNumber',
  'bankName',
  'channel',
  'accountType',
  'bankNumber',
  'branchNumber',
];

export const PAYMENT_METHODS = {
  [PAYMENT_TYPES.CREDIT_CARD]: DEBIT_CARD_API_KEYS,
  [PAYMENT_TYPES.ACH]: ACH_API_KEYS,
  [PAYMENT_TYPES.PAD]: PAD_API_KEYS,
};

export const PAYMENT_CHANNELS = {
  LMS: 'LMS',
  CAP: 'CAP',
};

export const SCHEDULED_PAYMENT_TYPES = {
  ONE_TIME: 0,
  RECURRING: 1,
};

export const SCHEDULED_PAYMENT_FREQUENCIES = {
  MONTHLY: 'MO',
  SEMIMONTHLY: 'SM',
  BIWEEKLY: 'BW',
  WEEKLY: 'WE',
  ONETIME: 'onlyonce'
};

export const PAYMENT_TYPES_TO_PLATFORM_DATA_FLAGS = {
  [SCHEDULED_PAYMENT_TYPES.ONE_TIME]: 'conv_fee_one_time_schd',
  [SCHEDULED_PAYMENT_TYPES.RECURRING]: 'conv_fee_recurring',
};
