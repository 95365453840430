import React from 'react';

export default function CalendarIcon({ height = '13px', width = '12px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.917.423c.322 0 .583.27.583.604v.604h.583c.967 0 1.75.811 1.75 1.812v7.25c0 1.002-.783 1.813-1.75 1.813H1.917c-.967 0-1.75-.812-1.75-1.813v-7.25c0-1 .783-1.812 1.75-1.812H2.5v-.604c0-.334.261-.604.583-.604.322 0 .584.27.584.604v.604h4.666v-.604c0-.334.261-.604.584-.604zM1.333 6.464v4.23c0 .333.261.604.584.604h8.166c.322 0 .584-.27.584-.604v-4.23H1.333zm0-1.208h9.334V3.443a.594.594 0 0 0-.584-.604H9.5v.604a.594.594 0 0 1-.583.605.594.594 0 0 1-.584-.605V2.84H3.667v.604a.594.594 0 0 1-.584.605.594.594 0 0 1-.583-.605V2.84h-.583a.594.594 0 0 0-.584.604v1.813z"
        fill="currentColor"
      />
    </svg>
  );
}
