import React from 'react';
import Form from 'components/Form/Form';
import { Box } from '@material-ui/core';

export default function WebPopForm({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  ...rest
}) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      {...rest}
    >
      <Box height="100%" flexDirection="column" display="flex">
        {children}
      </Box>
    </Form>
  );
}
