import React from 'react';
import BasicModal from 'components/BasicModal/BasicModal';
import BasicModalCloseButton from 'components/BasicModal/BasicModalCloseButton/BasicModalCloseButton';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import Form from 'components/Form/Form';
import { Box, makeStyles } from '@material-ui/core';
import BasicModalContent from 'components/BasicModal/BasicModalContent/BasicModalContent';
import Text from 'components/Text/Text';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import { useCreateGroup, useEditGroup, useGroupDetail } from 'services/groups/hooks';
import * as yup from 'yup';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import useMixinStyles from 'styles/mixins';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import AddNewGroupInput from './AddNewGroupInput/AddNewGroupInput';
import AddNewGroupFooter from './AddNewGroupFooter/AddNewGroupFooter';
import StaticGroupSwitch from './AddNewGroupInput/StaticGroupSwitch/StaticGroupSwitch';
import LoanStatusCodeInput from './AddNewGroupInput/LoanStatusCodeInput/LoanStatusCodeInput';
import GroupDetails from './GroupDetails/GroupDetails';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '700px',
    padding: '0 2rem',
  },
}));

export default function AddNewGroupModal({ isOpen, onClose, onSuccess, groupToEditId }) {
  const classes = useStyles();

  return (
    <BasicModal className={classes.modal} isOpen={isOpen} onClose={onClose}>
      <BasicModalCloseButton onClick={onClose} />
      <Box marginBottom="2.5rem">
        <BasicModalTitle>{groupToEditId ? 'Edit Group' : 'Create new group'}</BasicModalTitle>
      </Box>
      <GroupDetails groupToEditId={groupToEditId} onSuccess={onSuccess} />
    </BasicModal>
  );
}
