import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '75rem',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default function Container({ children, ...rest }) {
  const classes = useStyles();
  return (
    <Box padding="0 1rem" margin="0 auto" className={classes.container} flex={1} {...rest}>
      {children}
    </Box>
  );
}
