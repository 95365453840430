import React from 'react';

export default function PhoneIcon({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height || '24px'}
      width={width || '24px'}
      viewBox="0 0 511.999 511.999"
      fill="currentColor"
    >
      <path d="M498.827 377.633l-63.649-63.649c-17.548-17.547-46.102-17.547-63.649 0l-28.931 28.931c-13.294 13.294-34.926 13.29-48.215.005l-125.4-125.507c-13.325-13.325-13.327-34.892 0-48.219l28.931-28.931c17.471-17.47 17.715-45.935-.017-63.665l-63.632-63.432C116.717-4.381 88.164-4.381 70.663 13.12L47.471 36.111c-63.28 63.279-63.28 166.242-.003 229.519L246.16 464.426c63.428 63.429 166.088 63.434 229.521 0l23.146-23.145c17.548-17.548 17.548-46.1 0-63.648zM91.833 34.382c5.849-5.849 15.365-5.85 21.233.016l63.632 63.432c5.863 5.863 5.863 15.352 0 21.216l-10.609 10.608-84.81-84.81 10.554-10.462zM267.38 443.213L68.687 244.415c-48.958-48.958-51.649-125.833-8.276-178.006l84.564 84.564c-22.22 25.189-21.294 63.572 2.787 87.653l125.396 125.501.004.004c24.055 24.056 62.436 25.042 87.656 2.792l84.566 84.566c-52.007 43.298-128.709 41.019-178.004-8.276zm210.232-23.148l-10.609 10.609-84.865-84.866 10.607-10.608c5.85-5.849 15.367-5.85 21.217 0l63.649 63.649c5.85 5.85 5.85 15.368.001 21.216z" />
    </svg>
  );
}
