import { Box, makeStyles } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import { useFormikContext } from 'formik';
import React from 'react';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '8.75rem',
  },
}));

export default function SettingsSectionUpdate(props) {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  return (
    <Box alignSelf="center" marginTop="auto">
      <ContainedButton
        disabled={isFormInvalid}
        className={classes.button}
        type="submit"
        size="sm"
        isLoading={isSubmitting}
        {...props}
      >
        Update
      </ContainedButton>
    </Box>
  );
}
