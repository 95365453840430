import React from 'react';
import { Box } from '@material-ui/core';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import { postRefund } from 'api/Borrowers';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useFormikContext } from 'formik';
import RefundForm from './RefundForm';

export const REFUND_OPTIONS = {
  FULL_REFUND: '0',
  PARTIAL_REFUND: '1',
};

export default function RefundDialog({ openRefund, val, handleClosePayment, pmtId }) {
  const { values } = useFormikContext();
  const [loading, setLoading] = React.useState(false);
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();

  function handleSubmit() {
    setLoading(true);
    postRefund(pmtId, values.option === REFUND_OPTIONS.FULL_REFUND, values.amount)
      .then((res) => {
        if (res.data.status === API_RESPONSE_STATUS.SUCCESS) {
          queryCache.invalidateQueries('payment-history', {
            refetchInactive: true,
            refetchActive: true,
          });
          setAlertMessage('Refund successful!');
          handleClosePayment();
        } else {
          setErrorAlertMessage(res.data.message);
        }
      })
      .catch((e) => {
        setErrorAlertMessage('Refund error please try again');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <ConfirmationDialog
      title="Refund"
      isOpen={openRefund}
      onClose={handleClosePayment}
      loading={loading}
      mainActionText="Refund"
      mainActionColor="primary.500"
      onConfirm={handleSubmit}
    >
      <Box width="21.875rem">
        <RefundForm val={val} />
      </Box>
    </ConfirmationDialog>
  );
}
