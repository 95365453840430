import React from 'react';

export default function DownloadIcon({ width, height }) {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 477.867 477.867"
      fill="currentColor"
    >
      <path d="M443.733 307.2c-9.426 0-17.067 7.641-17.067 17.067v102.4c0 9.426-7.641 17.067-17.067 17.067H68.267c-9.426 0-17.067-7.641-17.067-17.067v-102.4c0-9.426-7.641-17.067-17.067-17.067s-17.067 7.641-17.067 17.067v102.4c0 28.277 22.923 51.2 51.2 51.2H409.6c28.277 0 51.2-22.923 51.2-51.2v-102.4c0-9.426-7.641-17.067-17.067-17.067z" />
      <path d="M335.947 295.134c-6.614-6.387-17.099-6.387-23.712 0L256 351.334V17.067C256 7.641 248.359 0 238.933 0s-17.067 7.641-17.067 17.067v334.268l-56.201-56.201c-6.78-6.548-17.584-6.36-24.132.419-6.388 6.614-6.388 17.099 0 23.713l85.333 85.333c6.657 6.673 17.463 6.687 24.136.031l.031-.031 85.333-85.333c6.549-6.78 6.361-17.584-.419-24.132z" />
    </svg>
  );
}
