import React from 'react';

export default function MasterCardIcon({ width, height }) {
  return (
    <svg
      width={width || 54}
      height={height || 34}
      viewBox="0 0 54 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 31.112A2.883 2.883 0 0 0 2.88 34h48.24c1.59 0 2.88-1.293 2.88-2.888V2.888A2.883 2.883 0 0 0 51.12 0H2.88A2.884 2.884 0 0 0 0 2.888v28.224z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.67 26.602h10.574V7.525H21.671v19.077z"
        fill="#FF5F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.342 17.064c0-3.87 1.805-7.318 4.616-9.539a12 12 0 0 0-7.468-2.594c-6.674 0-12.083 5.432-12.083 12.133 0 6.7 5.41 12.132 12.083 12.132a12 12 0 0 0 7.468-2.594 12.129 12.129 0 0 1-4.616-9.538z"
        fill="#EB001B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.131 24.581v-.47h-.123l-.14.323-.141-.324h-.123v.471h.087v-.355l.132.306h.09l.132-.307v.356h.086zm-.775 0v-.39h.156v-.08h-.4v.08h.158v.39h.086zm1.152-7.517c0 6.7-5.41 12.132-12.083 12.132a12 12 0 0 1-7.467-2.594 12.129 12.129 0 0 0 4.615-9.538c0-3.87-1.805-7.318-4.616-9.539a12 12 0 0 1 7.468-2.594c6.674 0 12.083 5.432 12.083 12.133z"
        fill="#F79E1B"
      />
    </svg>
  );
}
