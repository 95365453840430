import { useQuery, useMutation } from 'react-query';
import { getAPIUser, editEmail, editMobile, changePassword } from './api';
// eslint-disable-next-line import/prefer-default-export
export function useAPIUser() {
  return useQuery(['api-user'], getAPIUser, { staleTime: 0 });
}

export const useEditEmail = (options = {}) => {
  return useMutation(editEmail, {
    ...options,
  });
};

export const useEditMobile = (options = {}) => {
  return useMutation(editMobile, {
    ...options,
  });
};

export const useChangePassword = (options = {}) => {
  return useMutation(changePassword, {
    ...options,
  });
};
