import React from 'react';
import MoneyFormatField from 'components/BasicInputs/BasicTextField/MoneyFormatField/MoneyFormatField';
import { Box } from '@material-ui/core';
import BasicCheckbox from 'components/BasicInputs/BasicCheckbox/BasicCheckbox';
import { useClientDetailsContext } from 'services/client/context';
import PaymentDetailsItem from '../PaymentDetails/PaymentDetailsContent/PaymentDetailsItem/PaymentDetailsItem';

export default function PaymentAmount({ showRefund, isEdit, ...rest }) {
  const { customTransCode } = useClientDetailsContext();

  return (
    <PaymentDetailsItem
      label="Payment Amount"
      input={
        <Box>
          <MoneyFormatField name="amount" noShadow placeholder="$0.00" {...rest} />
          {showRefund && (
            <Box>
              <BasicCheckbox name="refund" label="Send Money to Customer" />
            </Box>
          )}
          {!!customTransCode && !isEdit && (
            <Box>
              <BasicCheckbox name="principalOnly" label="Principal Only" />
            </Box>
          )}
        </Box>
      }
    />
  );
}
