import React from 'react';
import { useBorrowerDetails } from 'services/loans/hooks';
import SubmitInput from 'components/SubmitInput/SubmitInput';
import { useEditEmail, useEditMobile } from 'services/users/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import * as yup from 'yup';
import { to } from 'shared/utils';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import produce from 'immer';
import LoanDetailsRow from '../../LoanDetailsCommon/LoanDetailsRow/LoanDetailsRow';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import PERMISSIONS from 'shared/constants/permissions';

const validationSchema = yup
  .string()
  .required('Mobile number is required')
  .matches(/^\d+$/, 'Phone number not valid')
  .test('len', 'Mobile number should be 10 digits', val => val.length === 10);

export default function LoanDetailsMobile() {
  const { canUserAccessTo } = useCurrentUser();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { id } = useParams();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const [mutate] = useEditMobile({
    onMutate: req => {
      const previousItem = queryCache.getQueryData(['borrower-details', { loanId: id }]);
      queryCache.setQueryData(['borrower-details', { loanId: id }], old => {
        const updatedBorrowerDetails = produce(old, draft => {
          draft.linkAttempt.installAccount.installMobilePhone.mobilePhoneNo = req.mobile_phone_no;
        });
        return updatedBorrowerDetails;
      });
      setAlertMessage('Mobile updated successfully!');
      return () => queryCache.setQueryData(['borrower-details', { loanId: id }], previousItem);
    },
    onSettled: () => {
      queryCache.invalidateQueries(['borrower-details', { loanId: id }]);
    },
  });
  const handleSubmit = async (value, setIsEdit) => {
    const [validationError] = await to(
      validationSchema.validate(value, {
        abortEarly: false,
      }),
    );
    if (validationError) {
      setErrorAlertMessage(validationError.message);
      return;
    }
    setIsEdit(false);
    await mutate({
      install_account_id: borrowerDetails?.linkAttempt?.installAccountId,
      mobile_phone_no: value,
    });
  };
  const value = borrowerDetails.linkAttempt.installAccount
  ? borrowerDetails.linkAttempt.installAccount.installMobilePhone.mobilePhoneNo
  : 'N/A'
  return (
    <LoanDetailsRow
      attribute="Mobile"
      value={
        canUserAccessTo(PERMISSIONS.BORROWERS.EDIT_INFORMATION) ?
        <SubmitInput
          value={value}
          onSubmit={handleSubmit}
          textAlign="center"
        /> : value
      }
    />
  );
}
