import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import Icon from 'components/Icon/Icon';
import BasicTabPanel from 'components/BasicTabs/BasicTabPanel/BasicTabPanel';
import Form from 'components/Form/Form';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import { useRules, useUpdateRule } from 'services/rules/hooks';
import { pick } from 'shared/utils';
import {
  PLATFORMS_API_KEYS,
  MAP_PLATFORMS_API_KEYS_TO_APPLY_ON,
  RULES_FORM_KEYS,
  RULES_APPLY_TO,
  RULES_ALL_LOANS_KEYS,
  RULES_SPECIFIC_LOANS_KEYS,
  RULES_GROUPS_KEYS,
} from 'shared/constants/rules';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { isPast, parseISO, isToday } from 'date-fns';
import { parse } from 'date-fns/esm';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import BasicCard from 'components/BasicCard/BasicCard';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import RulesSelector from '../RulesSelector/RulesSelector';
import RulesForm from '../RulesForm/RulesForm';

const initialValues = {
  allGroup: [],
  applyOn: [],
  applyTo: 0,
  loanNumber: '',
  groups: [],
  clientRuleSubCategoryId: '',
  startDate: '',
  endDate: '',
  offHide: false,
};

const RULES_KEYS_BY_APPLY_TO = {
  [RULES_APPLY_TO.ALL_LOANS]: RULES_ALL_LOANS_KEYS,
  [RULES_APPLY_TO.SPECIFIC_LOANS]: RULES_SPECIFIC_LOANS_KEYS,
  [RULES_APPLY_TO.GROUPS]: RULES_GROUPS_KEYS,
};

const mapRulesBackendToForm = (ruleDetails) => {
  const selectedRuleDetails = pick(ruleDetails, RULES_FORM_KEYS);

  const getPlatforms = pick(ruleDetails, PLATFORMS_API_KEYS);
  const mappedApplyOnPlatforms = Object.keys(getPlatforms).reduce((applyOnPlatforms, key) => {
    if (getPlatforms[key]) {
      applyOnPlatforms.push(MAP_PLATFORMS_API_KEYS_TO_APPLY_ON[key]);
    }
    return applyOnPlatforms;
  }, []);
  const mappedClientGroups = ruleDetails.clientRuleGroups.reduce((allGroup, group) => {
    allGroup.push({ id: group.clientGroupRuleId });
    return allGroup;
  }, []);
  const mappedLoans = ruleDetails.clientRuleLoans.reduce((allLoans, loan) => {
    allLoans.push(loan.loan.loanNo);
    return allLoans;
  }, []);

  return {
    ...selectedRuleDetails,
    startDate: parseISO(ruleDetails.startDate),
    endDate: parseISO(ruleDetails.endDate),
    applyOn: ruleDetails.applyOn || mappedApplyOnPlatforms,
    allGroup: ruleDetails.allGroup || mappedClientGroups,
    loanNumber: mappedLoans,
  };
};

const validationSchema = yup.object({
  startDate: yup.string().required('Start date is required').nullable(),
  endDate: yup
    .string()
    .required('End date is required')
    .test('endDate', 'Stop date is past today. Please update it.', (val) => {
      const parsedDate = parse(val, 'MM/dd/yyyy', new Date());
      return !isPast(parsedDate) || isToday(parsedDate);
    })
    .nullable(),
  applyOn: yup.array().required('At least one platform is required'),
  loanNumber: yup.array().when('applyTo', {
    is: (applyTo) => Number(applyTo) === RULES_APPLY_TO.SPECIFIC_LOANS,
    then: yup.array().required('At least one loan is required'),
    otherwise: yup.array(),
  }),
  allGroup: yup.array().when('applyTo', {
    is: (applyTo) => Number(applyTo) === RULES_APPLY_TO.GROUPS,
    then: yup.array().required('At least one group is required'),
    otherwise: yup.array(),
  }),
});

export default function RulesCommonLayout({ title, icon, tabs, platforms }) {
  const { activeTab } = useActiveTab();
  const { refetch, isLoading, data } = useRules(activeTab);

  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const [
    mutate,
    { isLoading: isUpdateRuleLoading, isError, isSuccess, data: responseOfUpdate },
  ] = useUpdateRule();

  const initialFormValues = useMemo(
    () => (data ? mapRulesBackendToForm(data.result.data.rule) : initialValues),
    [data],
  );

  const handleSubmit = async (values) => {
    const selectedValues = pick(values, RULES_KEYS_BY_APPLY_TO[values.applyTo]);
    await mutate({
      ...selectedValues,
      clientRuleSubCategoryId: activeTab,
      ...(Number(values.applyTo) === RULES_APPLY_TO.SPECIFIC_LOANS && {
        loanNumber: values.loanNumber.join(','),
      }),
    });
  };
  useEffect(() => {
    if (isError) {
      setErrorAlertMessage('There was a problem updating the rule');
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      if (responseOfUpdate.status === API_RESPONSE_STATUS.FAILED) {
        setErrorAlertMessage(responseOfUpdate.message);
      } else {
        setAlertMessage('Success!');
      }
    }
  }, [isSuccess]);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <RulesSelector
          tabs={tabs}
          icon={<Icon height="100%" width="100%" name={icon} />}
          title={title}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <BasicCard minHeight="550px" noPadding>
          {isLoading ? (
            <SpinnerWrapped />
          ) : (
            <Form
              initialValues={initialFormValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {tabs.map((tab) => (
                <BasicTabPanel key={tab.index} index={tab.index}>
                  <RulesForm platforms={platforms} isLoading={isLoading} title={tab.title} />
                </BasicTabPanel>
              ))}
            </Form>
          )}
        </BasicCard>
      </Grid>
    </Grid>
  );
}
