import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import PaymentMethodsInput from 'components/WebPop/PaymentMethodsInput/PaymentMethodsInput';
import { useBorrowerDetails, useLoanDetails } from 'services/loans/hooks';
import Icon from 'components/Icon/Icon';
import useMixinStyles from 'styles/mixins';
import {
  useWebPopSwitch,
  WEBPOP_COMPONENTS,
} from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import WebPopSection from 'components/WebPop/WebPopSection/WebPopSection';
import BasicButton from 'components/BasicButton/BasicButton';
import IconButton from 'components/Icon/IconButton/IconButton';

const useStyles = makeStyles({
  addPaymentMethodButton: {
    height: '3rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    justifyContent: 'flex-start',
    borderRadius: '10px',
  },
  rightSideOfTitleButton: {
    borderRadius: '10px',
  },
});

export default function PaymentMethods({ inputValueKey, name, isSchedule }) {
  const classes = useStyles();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { data } = useLoanDetails(borrowerDetails.loan.loanNo);
  const { pushHistory } = useWebPopSwitch();
  const { paymentMethods } = data.result.data;
  return (
    <WebPopSection
      title="Payment Method"
      rightSideOfTitle={
        <>
          {!!paymentMethods.length && (
            <BasicButton
              onClick={() => pushHistory(WEBPOP_COMPONENTS.ADD_PAYMENTS)}
              className={classes.rightSideOfTitleButton}
            >
              + Add new
            </BasicButton>
          )}
        </>
      }
    >
      {paymentMethods.length ? (
        <Box display="flex" alignItems="center">
          <Box flex={1} marginRight="1.25rem">
            <PaymentMethodsInput
              inputValueKey={inputValueKey}
              name={name}
              paymentMethods={paymentMethods}
              isSchedule={isSchedule}
            />
          </Box>
          <IconButton onClick={() => pushHistory(WEBPOP_COMPONENTS.EDIT_PAYMENTS)} name="list" />
        </Box>
      ) : (
        <BasicButton
          onClick={() => pushHistory(WEBPOP_COMPONENTS.ADD_PAYMENTS)}
          className={classes.addPaymentMethodButton}
        >
          + Add new payment method
        </BasicButton>
      )}
    </WebPopSection>
  );
}
