import { Box, makeStyles } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    color: '#7C8DA0',
    '&:focus': {
      color: 'rgba(26, 26, 32, 1)',
    },
  },
}));

export default function SearchableColumnHeader({ column }) {
  const classes = useStyles();
  return (
    <Box marginTop="0.375rem" paddingRight="0.5rem" paddingBottom="0.625rem">
      <BasicTextField
        isDebounced
        startIcon={
          <FlexRow className={classes.searchIcon}>
            <Icon name="search" />
          </FlexRow>
        }
        size="xs"
        name={column.id}
      />
    </Box>
  );
}
