import React from 'react';
import { Box } from '@material-ui/core';
import Spinner from '../Spinner';

export default function SpinnerBoxed({ size = 56, ...props }) {
  return (
    <Box height="100%" display="flex" justifyContent="center" padding="2rem" {...props}>
      <Spinner size={size} />
    </Box>
  );
}
