import React from 'react';
import { useQueryCache } from 'react-query';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useAlertContext } from 'shared/contexts/useAlertContext';

export default function useBTC() {
  const queryCache = useQueryCache();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const onSettingsUpdateSuccess = (
    response,
    options = { alertMessage: '', errorMessage: '', keyToInvalidate: 'details' },
  ) => {
    if (response?.status === API_RESPONSE_STATUS.SUCCESS || !response?.status) {
      queryCache.invalidateQueries(options.keyToInvalidate, { exact: true });
      setAlertMessage(options.alertMessage);
      return;
    }
    setErrorAlertMessage(options.errorMessage || response.message);
  };
  return {
    onSettingsUpdateSuccess,
  };
}
