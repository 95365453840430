import { APIv1, API, webAPI } from 'shared/utils/http';
import { buildFormData } from 'shared/utils';
import { PAYMENT_CHANNELS, SCHEDULED_PAYMENT_TYPES } from 'shared/constants/payments';
import { ENDPOINTS } from '../../constants';

export const makePayment = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/make-payment', payload);
  return data;
};
export const schedulePayment = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/schedule-create', payload);
  return data;
};
export const editScheduledPayment = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/schedule-edit', payload);
  return data;
};
export const addCard = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/add-card', payload);
  return data;
};
export const addACH = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/add-ach', payload);
  return data;
};
export const deletePaymentMethod = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/delete-payment-method', payload);
  return data;
};

export const getPaymentFrequencies = async () => {
  const { data } = await API.get('/client/payment-frequencies');
  return data.result;
};
export const getScheduleList = async (keyword, options = {}) => {
  const formData = new FormData();
  buildFormData(formData, {
    ...options,
    channel: PAYMENT_CHANNELS.CAP,
  });
  const { data } = await APIv1.post('/v1/web-service/schedule-list', formData);
  return data.result;
};
export const getScheduleFrequency = async (keyword, payload = {}) => {
  const formData = new FormData();
  buildFormData(formData, {
    ...payload,
    channel: PAYMENT_CHANNELS.CAP,
  });
  const { data } = await APIv1.post('/v1/web-service/schedule-frequency', formData);
  return data;
};

export const deleteScheduledPayment = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/schedule-delete', payload);
  return data;
};

export const deleteRecurringScheduledPayment = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/schedule-delete-all', payload);
  return data;
};

const SCHEDULED_PAYMENTS_ENDPOINTS = {
  [SCHEDULED_PAYMENT_TYPES.ONE_TIME]: deleteScheduledPayment,
  [SCHEDULED_PAYMENT_TYPES.RECURRING]: deleteRecurringScheduledPayment,
};

export const deleteAnyScheduledPayment = async (payload) => {
  const typeOfScheduledPayment = payload.get('typeOfScheduledPayment');
  const selectedScheduledPaymentEnpoint = SCHEDULED_PAYMENTS_ENDPOINTS[typeOfScheduledPayment];
  payload.delete('typeOfScheduledPayment');
  const data = await selectedScheduledPaymentEnpoint(payload);
  return data;
};
export const sendPaymentReceipt = async (payload) => {
  const { data } = await APIv1.post('/v1/web-service/send-payment-receipt', payload);
  return data;
};

export const getPaymentHistory = async (keyword, { loanNumber, page }) => {
  const { data } = await webAPI.get(
    `${ENDPOINTS.getReceiptsByLoan}?limit=100&loanNumber=${loanNumber}&page=1`,
  );
  return data.result;
};

export const voidPayment = async (payload) => {
  const { data } = await webAPI.post('/ach/void-payment', payload);
  return data;
};
