import React from 'react';

export default function MailIcon({ width, height }) {
  return (
    <svg
      width={width || 16}
      height={height || 12}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.125 3.627v5.998c0 1.16-.94 2.102-2.095 2.102H2.707A2.101 2.101 0 0 1 .612 9.625V3.627L5.82 6.523a4.21 4.21 0 0 0 2.05.535 4.21 4.21 0 0 0 2.05-.535l5.206-2.896zM13.03.273a2.1 2.1 0 0 1 2.068 1.767L9.24 5.297a2.807 2.807 0 0 1-2.745 0L.639 2.04A2.1 2.1 0 0 1 2.707.273H13.03z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
    </svg>
  );
}
