import { Box, Grid } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import PasswordField from 'components/BasicInputs/BasicTextField/PasswordField/PasswordField';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { useFormikContext } from 'formik';
import React from 'react';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';

export default function ProfilePasswordForm() {
  const { isSubmitting } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  return (
    <FlexColumn height="100%" padding="2.5rem 2.25rem">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <PasswordField label="Current Password" name="old_password" />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <PasswordField label="New Password" name="password" />
        </Grid>
        <Grid item xs={6}>
          <PasswordField label="Confirm New Password" name="password_confirmation" />
        </Grid>
      </Grid>
      <Box width="100%" margin="0 auto" marginTop="auto" maxWidth="15.875rem">
        <ContainedButton
          type="submit"
          disabled={isFormInvalid}
          isLoading={isSubmitting}
          fullWidth
          size="md"
        >
          Update Password
        </ContainedButton>
      </Box>
    </FlexColumn>
  );
}
