import React, { useState } from 'react';
import { Box, Grid, Popover, makeStyles } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import useMixinStyles from 'styles/mixins';
import ConfirmationPopover from 'components/ConfirmationPopover/ConfirmationPopover';
import { PAYMENT_TYPES, PAYMENT_CHANNELS } from 'shared/constants/payments';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useBorrowerDetails } from 'services/loans/hooks';
import { buildFormData } from 'shared/utils';
import { useDeletePaymentMethod } from 'services/payments/hooks';
import clsx from 'clsx';
import { format, parse } from 'date-fns';
import { useQueryCache } from 'react-query';

const CARD_BRAND_ICONS = {
  MasterCard: 'mastercard',
  Visa: 'visa',
  Discover: 'discover',
  Amex: 'amex'
};

const useStyles = makeStyles(({ palette }) => ({
  deleteButton: {
    color: '#7C8DA0',
    '&:hover': {
      color: '#EB4D4B',
    },
  },
}));

export default function PaymentMethodsListItem({
  id,
  brand,
  nameOnCard,
  nameOnPad,
  lastFourDigits,
  paymentType,
  nameOnAch,
  bankName,
  readOnly,
  expDate,
  isSchedule
}) {
  const classes = useStyles();
  const mixinStyles = useMixinStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const { data: borrowerDetails } = useBorrowerDetails();
  const queryCache = useQueryCache();
  const [mutate] = useDeletePaymentMethod(id, borrowerDetails.loan.loanNo, {
    onSuccess: (response) => {
      if (response?.status === API_RESPONSE_STATUS.SUCCESS || !response?.status) {
        if (isSchedule === 1) {
          queryCache.invalidateQueries('schedule-list', { refetchInactive: true });
        }
        setAlertMessage('Payment method deleted successfully!');
        return;
      }
      setErrorAlertMessage(response.message);
    },
    onError: () => {
      setErrorAlertMessage('There was a problem deleting the payment method');
    },
  });

  const handleOnDelete = () => {
    const formData = new FormData();
    buildFormData(formData, {
      MethodId: id,
      MethodType: paymentType,
      channel: PAYMENT_CHANNELS.LMS,
    });
    mutate(formData);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const isBank = paymentType === PAYMENT_TYPES.ACH || paymentType === PAYMENT_TYPES.PAD;

  return (
    <Box
      width="100%"
      border={readOnly ? 'none' : '1px solid rgba(124, 141, 160, 0.20)'}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="0.75rem"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center">
        {(isBank || CARD_BRAND_ICONS[brand]) && (
          <Box border="1px solid rgb(0 79 139 / 0.12)" display="flex" marginRight="1rem">
            <Icon width="54" height="34" name={isBank ? 'bank' : CARD_BRAND_ICONS[brand]} />
          </Box>
        )}
        <Box minWidth="12rem">
          <Text
            lineHeight={1}
            fontSize="0.75rem"
            color="text.primary"
            fontWeight={500}
            letterSpacing="0.18px"
            marginBottom="0.25rem"
          >
            {nameOnCard || nameOnAch || nameOnPad}
          </Text>
          {lastFourDigits && (
            <Text
              lineHeight={1}
              fontSize="0.75rem"
              color="text.primary"
              fontWeight={500}
              letterSpacing="0.18px"
            >
              {`**** **** **** ${lastFourDigits}`}
            </Text>
          )}
        </Box>
        <Box>
          <Text
            lineHeight={1}
            fontSize="0.5rem"
            color="text.secondary"
            fontWeight={500}
            letterSpacing="0.12px"
            marginBottom="0.25rem"
          >
            {isBank ? 'Bank Name' : 'Exp Date'}
          </Text>
          {lastFourDigits && (
            <Text
              lineHeight={1}
              fontSize="0.75rem"
              color="text.primary"
              fontWeight={500}
              letterSpacing="0.18px"
            >
              {isBank ? bankName : format(parse(expDate, 'MM-yyyy', new Date()), 'MM/yy')}
            </Text>
          )}
        </Box>
      </Box>
      {!readOnly && (
        <Box
          role="button"
          onClick={handleClick}
          display="flex"
          className={clsx(classes.deleteButton, mixinStyles.clickable)}
        >
          <Icon name="deleteAlt" />
        </Box>
      )}
      <ConfirmationPopover
        title={`
         ${isSchedule === 1 ? `This payment method is linked with your scheduled payment. \n Deleting this payment method
         will void your scheduled payment.` : '' }
         Are you sure you want to delete this?
         `}
        open={open}
        onConfirmClick={handleOnDelete}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </Box>
  );
}
