import axios from 'axios';
import { ENV } from '../../constants';
import { getStoredAuthToken, getStoredAPIUser } from './authToken';

function createAxiosInstance(baseURL, useUsernameAndPassword) {
  const axiosInstance = axios.create({
    baseURL,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (useUsernameAndPassword) {
        const APIUser = await getStoredAPIUser();
        if (APIUser) {
          config.headers.username = APIUser.username;
          config.headers.password = APIUser.password;
        }
      } else {
        const authToken = await getStoredAuthToken();
        if (authToken) {
          config.headers.accessToken = authToken;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}

const webAPI = createAxiosInstance(ENV.WEBHOST);
const API = createAxiosInstance(ENV.HOST);
const APIv1 = createAxiosInstance(ENV.HOST, true);

export { webAPI, API, APIv1 };
