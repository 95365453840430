import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import useMixinStyles from 'styles/mixins';
import clsx from 'clsx';

export default function AddPaymentMethodType({ disabled, children, onClick, selected }) {
  const mixinClasses = useMixinStyles();

  const getBackgroundColor = () => {
    if (!disabled) {
      if (selected) {
        return 'primary.200';
      }
      return 'white';
    }
    return 'grey.200';
  };
  const getTextColor = () => {
    if (!disabled) {
      if (selected) {
        return 'primary.500';
      }
      return 'text.primary';
    }
    return 'grey.500';
  };
  return (
    <Box
      bgcolor={getBackgroundColor()}
      border="1px solid #222F3E19"
      borderColor={selected ? 'primary.500' : 'rgba(124, 141, 160, 0.88)'}
      height="48px"
      width="100%"
      borderRadius="12px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={clsx(!disabled && mixinClasses.clickable)}
      onClick={onClick}
    >
      <Text
        color={getTextColor()}
        fontSize="0.8125rem"
        fontWeight={selected ? 600 : 500}
        letterSpacing="0.585px"
      >
        {children}
      </Text>
    </Box>
  );
}
