import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import materialTheme from 'styles/theme';
import { AlertProvider } from 'shared/contexts/useAlertContext';
import { ClientDetailsProvider } from 'services/client/context';
import SessionTimeExpirerProvider from 'components/SessionTimeExpirer/SessionTimeExpirer';
import { ReactQueryConfigProvider, QueryCache, ReactQueryCacheProvider } from 'react-query';
import { CurrentUserProvider } from './CurrentUserProvider';

const queryCache = new QueryCache();

const overrides = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

export default function AppProviders({ children }) {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ReactQueryConfigProvider config={overrides}>
        <CurrentUserProvider>
          <ThemeProvider theme={materialTheme}>
            <ClientDetailsProvider>
              <SessionTimeExpirerProvider>
                <AlertProvider>
                  <CssBaseline />
                  {children}
                </AlertProvider>
              </SessionTimeExpirerProvider>
            </ClientDetailsProvider>
          </ThemeProvider>
        </CurrentUserProvider>
      </ReactQueryConfigProvider>
    </ReactQueryCacheProvider>
  );
}
