import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormikContext, useField } from 'formik';

import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { pathSplit } from 'shared/utils';
import BasicInput from 'components/BasicInputs/BasicInput';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
}));

const NumberFormatCustom = (props) => {
  const {
    inputRef,
    value,
    min,
    max,
    onFormikChange,
    onBlur,
    touchField,
    validate,
    setError,
    isNumericString = true,
    maintainFormattedValue,
    ...other
  } = props;
  const [currentValue, setCurrentValue] = useState(value || '');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onFormikChange(currentValue);
    }
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={currentValue}
      onValueChange={(values) => {
        let val = maintainFormattedValue ? values.formattedValue : values.value;
        if (min !== undefined) {
          val = Math.max(val, min);
        }
        if (max !== undefined) {
          val = Math.min(val, max);
        }

        if (val === 0) {
          val = '';
        }

        setCurrentValue(val);
      }}
      onBlur={(ev) => {
        touchField(true);
        onBlur(ev);
        if (currentValue !== value) {
          onFormikChange(currentValue);
        }
      }}
      isNumericString={isNumericString}
      format="#### #### #### ####"
      onKeyDown={handleKeyDown}
      {...other}
    />
  );
};
const FormattedTextField = ({ name, label, min, max, hideErrors, formatProps = {}, ...props }) => {
  const { values, setFieldValue, errors, validateField } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const value = pathSplit(values, name);
  const onChange = (val) => {
    setFieldValue(name, val);
  };

  const error = pathSplit(errors, name);
  return (
    <BasicInput
      variant="outlined"
      error={meta.error && meta.touched ? meta.error : null}
      inputComponent={(inputProps) => {
        return (
          <NumberFormatCustom
            {...inputProps}
            {...formatProps}
            value={value}
            min={min}
            max={max}
            onFormikChange={onChange}
            touchField={helpers.setTouched}
            validate={() => validateField(name)}
            setError={helpers.setError}
          />
        );
      }}
      {...props}
      label={label}
      touched={meta.touched}
      touchField={helpers.setTouched}
    />
  );
};

export default FormattedTextField;
