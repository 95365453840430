import { Box } from '@material-ui/core';
import React from 'react';

export default function SettingsSectionContent({ children, ...rest }) {
  return (
    <Box padding="1.25rem 1.5rem" {...rest}>
      {children}
    </Box>
  );
}
