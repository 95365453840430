import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import { useFormikContext } from 'formik';
import React from 'react';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';

export default function TransactionsClearFilters() {
  const { resetForm } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();

  return (
    <ContainedButton
      disabled={isFormInvalid}
      onClick={resetForm}
      variant="outlined"
      color="primary"
    >
      Clear Filters
    </ContainedButton>
  );
}
