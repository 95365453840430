import React from 'react';

export default function FailureIcon({ width, height }) {
  return (
    <svg
      height={height || 76}
      width={width || 74}
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76 36.58c0 20.202-17.013 36.579-38 36.579S0 56.782 0 36.579C0 16.377 17.013 0 38 0s38 16.377 38 36.58z"
        fill="#EB001B"
      />
      <path
        opacity=".2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.903 53.715c10.2-.214 24.842-1.483 34.597-8.953C53.483 33.956 61.6 20.11 60.752 7.278 54.409 2.708 46.532 0 38 0 18.075 0 1.731 14.762.129 33.545c.287 7.87 1.844 15.358 4.774 20.17z"
        fill="#fff"
      />
      <path
        d="M57.564 56.564c-1.555 1.555-4.148 1.555-5.962 0L37.865 42.826 24.128 56.564c-1.555 1.555-4.407 1.555-5.962 0-1.555-1.555-1.555-4.407 0-5.962l13.997-13.737-13.997-13.737c-1.555-1.555-1.555-4.147 0-5.962 1.555-1.555 4.407-1.555 5.962 0l13.737 13.997 13.737-13.997c1.555-1.555 4.147-1.555 5.962 0 1.555 1.555 1.555 4.407 0 5.962L43.826 36.865l13.738 13.737c1.814 1.815 1.814 4.407 0 5.962z"
        fill="#fff"
      />
    </svg>
  );
}
