import { useMutation } from 'react-query';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import { storeLoggedInUser, storeAuthToken } from 'shared/utils/authToken';
import { useSessionTimeExpirer } from 'components/SessionTimeExpirer/SessionTimeExpirer';
import { login, forgotPassword, resetPassword } from './api';

// eslint-disable-next-line import/prefer-default-export
export const useLogin = (options = {}) => {
  const { setCurrentUser } = useCurrentUser();
  const { setIsTimerActive } = useSessionTimeExpirer();
  return useMutation(login, {
    ...options,
    onSuccess: (response) => {
      if (options.onSuccess) {
        options.onSuccess(response);
      }
      setIsTimerActive(true);
      storeAuthToken(response.result.accessToken);
      storeLoggedInUser(response.result.data);
      setCurrentUser(response.result.data);
    },
  });
};
export const useForgotPassword = (options = {}) => {
  return useMutation(forgotPassword, {
    ...options,
  });
};
export const useResetPassword = (options = {}) => {
  return useMutation(resetPassword, {
    ...options,
  });
};
