import React from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  letterSpacing: {
    letterSpacing: ({ letterSpacing }) => letterSpacing,
  },
}));

const Text = ({
  className,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  color,
  textAlign,
  text,
  style,
  children,
  component = 'p',
  ...rest
}) => {
  return (
    <Box
      color={color}
      textAlign={textAlign}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      fontSize={fontSize}
      component={component}
      margin={0}
      className={className}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Text;
