import { Box, makeStyles } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Text from 'components/Text/Text';
import React from 'react';
import useMixinStyles from 'styles/mixins';
import { useCurrentUser } from 'contexts/CurrentUserProvider';

const useStyles = makeStyles(() => ({
  powerIcon: {
    height: '1rem',
    width: '1rem',
  },
}));

export default function ProfileSummary() {
  const { currentUser, logout } = useCurrentUser();
  const mixinClasses = useMixinStyles();
  const classes = useStyles();
  return (
    <BasicCard noPadding height="16.25rem">
      <Box padding="2rem 1.5rem" display="flex" flexDirection="column">
        <Text
          className={mixinClasses.capitalize}
          fontWeight={500}
          fontSize="1rem"
          lineHeight="1.25rem"
          letterSpacing="-0.16px"
        >
          {`${currentUser.firstName} ${currentUser.lastName}`}
        </Text>
        <Text fontWeight={500} fontSize="0.625rem" lineHeight="0.625rem">
          {currentUser.role === 1 ? 'Admin' : 'Collector'}
        </Text>
      </Box>
      <Box height="1px" width="100%" bgcolor="common.borderLight" />
      <Box padding="0.875rem 1.125rem">
        <Box padding="0.875rem 0.375rem 1.25rem 0.375rem" bgcolor="common.backgroundMainLight">
          <Text
            fontWeight={500}
            fontSize="0.625rem"
            lineHeight="0.625rem"
            color="common.label"
            letterSpacing="-0.10px"
          >
            Email
          </Text>
          <Text fontWeight={500} fontSize="0.8125rem" lineHeight="1.25rem" letterSpacing="-0.16px">
            {currentUser.email}
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="auto"
        height="2.75rem"
        bgcolor="common.backgroundMainLight"
      >
        <Box onClick={logout} className={mixinClasses.clickable} display="flex" alignItems="center">
          <Box display="flex" marginRight="0.375rem">
            <PowerSettingsNewIcon className={classes.powerIcon} />
          </Box>
          <Text
            textAlign="center"
            fontWeight={500}
            fontSize="0.625rem"
            lineHeight="0.625rem"
            letterSpacing="0.45px"
          >
            Log Out
          </Text>
        </Box>
      </Box>
    </BasicCard>
  );
}
