import React from 'react';

export default function RestoreIcon({ width, height }) {
  return (
    <svg
      width={width || 13}
      height={height || 13}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.016 4.53C1.743 1.896 4.124.026 6.883.026c3.369 0 6.092 2.766 6.092 6.168s-2.721 6.167-6.09 6.169a5.795 5.795 0 0 1-4.81-2.443l-.001-.002a.741.741 0 0 1 .171-1.026.73.73 0 0 1 1.025.172 4.34 4.34 0 0 0 3.607 1.823h.006c2.265 0 4.202-1.665 4.567-3.94.41-2.562-1.307-4.97-3.825-5.384-2.26-.372-4.407.984-5.121 3.117.18.006.359.078.496.217a.742.742 0 0 1 0 1.04l-.956.97a.731.731 0 0 1-.519.22h-.058l-.019-.003a.732.732 0 0 1-.535-.329L.149 5.632v-.001a.741.741 0 0 1 .197-1.021.727.727 0 0 1 .67-.08z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
    </svg>
  );
}
