import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import ChipCheckbox from 'components/ChipCheckbox/ChipCheckbox';
import { useUpdateGroupStaticLoan } from 'services/groups/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import { useGroupsActiveTab } from '../../GroupsTabActiveProvider/GroupsTabActiveProvider';

export default function GroupAssociatedAccountsItem({ id, label, isEnabled, isStatic }) {
  const { setAlertMessage } = useAlertContext();
  const { groupsTabActive } = useGroupsActiveTab();
  const queryCache = useQueryCache();
  const [mutate] = useUpdateGroupStaticLoan({
    onSuccess: () => {
      queryCache.invalidateQueries(['group-loan-list', { groupId: groupsTabActive }]);
    },
  });
  const [checked, setChecked] = useState(isEnabled || !isStatic);
  const handleOnChange = () => {
    mutate({
      clientStaticGroupLoanId: id,
      status: checked ? 0 : 1,
    });
    setAlertMessage('Static Group Updated');
    setChecked(!checked);
  };

  return (
    <Box marginRight="0.75rem" marginBottom="1.25rem">
      <ChipCheckbox
        clickable={isStatic}
        onChange={handleOnChange}
        checked={checked}
        label={label}
      />
    </Box>
  );
}
