import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import BasicModal from 'components/BasicModal/BasicModal';
import { useAPIUser } from 'services/users/hooks';
import { storeAPIUser } from 'shared/utils/authToken';
import { useBorrowerDetails } from 'services/loans/hooks';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import IconButton from 'components/Icon/IconButton/IconButton';
import BasicModalCloseButton from 'components/BasicModal/BasicModalCloseButton/BasicModalCloseButton';
import {
  WEBPOP_COMPONENTS,
  WebPopSwitchProvider,
  useWebPopSwitch,
} from './WebPopSwitchProvider/WebPopSwitchProvider';
import PaymentMethodsList from './PaymentMethodsList/PaymentMethodsList';
import AddPaymentMethod from './AddPaymentMethod/AddPaymentMethod';
import PaymentTabs from './PaymentTabs/PaymentTabs';
import EditScheduledPayment from './EditScheduledPayment/EditScheduledPayment';
import { ScheduleListFilterProvider } from './PaymentTabs/ScheduleList/ScheduleListFilterProvider/ScheduleListFilterProvider';

export default function WebPop({ isOpen, onClose, initialTab }) {
  const { data } = useAPIUser();
  const { data: borrowerDetails } = useBorrowerDetails();
  useEffect(() => {
    if (data) {
      storeAPIUser(data.result.Credentials);
    }
  }, [data]);
  const { active } = useWebPopSwitch();

  return borrowerDetails ? (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <BasicModalCloseButton onClick={onClose} />
      <ScheduleListFilterProvider>
        <Box height="100%" overflow="hidden" display="flex" flexDirection="column" flex={1}>
          <BasicsTabsContextProvider onClose={onClose} initialActiveTab={initialTab}>
            {active === WEBPOP_COMPONENTS.PAYMENTS_TABS && <PaymentTabs initialTab={initialTab} />}
            {active === WEBPOP_COMPONENTS.EDIT_SCHEDULED_PAYMENT && <EditScheduledPayment />}
          </BasicsTabsContextProvider>
          {active === WEBPOP_COMPONENTS.EDIT_PAYMENTS && <PaymentMethodsList />}
          {active === WEBPOP_COMPONENTS.ADD_PAYMENTS && <AddPaymentMethod />}
        </Box>
      </ScheduleListFilterProvider>
    </BasicModal>
  ) : null;
}
