import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { Box } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { useDetails, useUpdateTermsText } from 'services/client/hooks';
import SettingsSection from '../../Shared/SettingsSection/SettingsSection';
import SettingsSectionUpdate from '../../Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import useBTC from '../useBTC';

export default function TermsText() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateTermsText({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, {
        alertMessage: 'Terms text updated successfully!',
      });
    },
  });

  const initialValues = useMemo(
    () => ({
      copyrightText: data.copyrightText,
    }),
    [data],
  );

  const handleOnSubmit = async (values) => {
    await mutate({
      copyrightText: values.copyrightText,
    });
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSection
        title="Terms Text"
        description="Information for Terms Date"
        name="logoPlaceholder"
      >
        <FlexColumn height="15rem">
          <Box flex={1} marginBottom="1.25rem">
            <BasicTextField
              name="copyrightText"
              placeholder="Copyright text"
              multiline
              fullHeight
            />
          </Box>
          <SettingsSectionUpdate />
        </FlexColumn>
      </SettingsSection>
    </Form>
  );
}
