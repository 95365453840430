const { makeStyles } = require('@material-ui/core');

const getStylesBySize = ({ size, fullHeight }) => {
  if (fullHeight) return { height: '100% !important', paddingTop: '1rem', overflow: 'scroll' };
  switch (size) {
    case 'xxs':
      return {
        height: '1.5rem',
        borderRadius: '4px',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        '&:focus': {
          borderRadius: '4px',
        },
      };
    case 'xs':
      return {
        height: '1.5rem',
        borderRadius: '4px',
        paddingLeft: '1.375rem',
        paddingRight: '1rem',
        '&:focus': {
          borderRadius: '4px',
        },
      };
    case 'sm':
      return {
        height: '2.25rem',
      };
    default:
      return {
        height: '3rem',
      };
  }
};

const getIconStylesBySize = ({ size }) => {
  switch (size) {
    case 'xs':
      return {
        left: '0.375rem',
      };
    default:
      return {
        left: '0.75rem',
      };
  }
};
const getAutoGrowStyles = ({ autoGrow }) => {
  if (autoGrow) {
    return {
      position: 'absolute',
      left: 0,
      width: '100%',
    };
  }
};

const paddingLeftStyle = ({ hasStartIcon, hasLabel }) => {
  if (hasStartIcon && hasLabel) {
    return '2.25rem';
  }
  if (hasStartIcon) {
    return '3rem';
  }
  return '0.75rem';
};

const textCenterStyle = ({ textAlign }) => {return textAlign || 'left'};
const paddingTopStyle = ({ hasLabel }) => hasLabel && '21px';
const paddingBottomStyle = ({ hasLabel }) => hasLabel && '4px';
const backgroundStyle = ({ isDisabled }, palette) => (isDisabled ? palette.grey[200] : 'white');
const boxShadowStyle = ({ noShadow }) => (noShadow ? 'none' : '0px 0px 8px rgba(34, 47, 62, 0.06)');
const disabledColorStyle = ({ isDisabled }, palette) =>
  isDisabled ? palette.grey[500] : palette.text.primary;
const colorStyle = ({ isDisabled }, palette) =>
  isDisabled ? palette.grey[500] : palette.text.primary;

const useInputStyles = makeStyles(({ palette, transitions }) => ({
  root: {
    '&  label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  icon: {
    color: palette.grey[500],
    right: 12,
    position: 'absolute',
  },
  label: {
    zIndex: 1,
    left: ({ hasStartIcon }) => (hasStartIcon ? '2.25rem' : '1rem'),
    top: '0.8875rem',
    transform: 'none',
    fontSize: '0.65625rem',
    letterSpacing: '0.15px',
    color: 'rgba(124, 141, 160, 0.88)',
    '&.Mui-focused': {
      color: 'rgba(124, 141, 160, 0.88)',
    },
    '&.Mui-disabled': {
      color: palette.grey[500],
    },
    '&.Mui-error': {
      color: 'rgba(124, 141, 160, 0.88)',
    },
  },
  startIcon: (props) => ({
    display: 'flex',
    position: 'absolute',
    left: '0.75rem',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    ...getIconStylesBySize(props),
  }),
  inputInput: (props) => ({
    display: 'flex',
    alignItems: 'center',
    lineHeight: '0.75rem',
    fontWeight: 500,
    width: '100%',
    border: `0.2px solid ${palette.common.border}`,
    padding: '0.75rem 0.75rem',
    paddingLeft: paddingLeftStyle(props),
    paddingTop: paddingTopStyle(props),
    paddingBottom: paddingBottomStyle(props),
    transition: transitions.create('width'),
    height: '3rem',
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontSize: '0.75rem',
    background: backgroundStyle(props, palette),
    color: colorStyle(props, palette),
    boxShadow: boxShadowStyle(props),
    textAlign: textCenterStyle(props),
    '&::placeholder': {
      color: 'rgba(124, 141, 160, 0.88)',
      opacity: '0.5',
    },
    '&.Mui-disabled': {
      color: disabledColorStyle(props, palette),
    },
    '&.MuiInputBase-multiline': {
      padding: 0,
    },
    '&:focus': {
      borderRadius: '8px',
      background: 'white',
    },
    ...getStylesBySize(props),
    ...getAutoGrowStyles(props),
  }),
}));

export default useInputStyles;
