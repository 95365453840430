import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import FullPageSpinner from 'components/Spinner/FullPageSpinner/FullPageSpinner';
import { useClientDetails } from './hooks';

const ClientDetailsContext = React.createContext();

export function ClientDetailsProvider({ children }) {
  const { data, isLoading } = useClientDetails();

  const value = React.useMemo(() => data, [data]);
  return (
    <ClientDetailsContext.Provider value={value}>
      {isLoading ? <FullPageSpinner /> : children}
    </ClientDetailsContext.Provider>
  );
}

export function useClientDetailsContext() {
  const context = useContext(ClientDetailsContext);
  if (!context) {
    throw new Error(`useClientDetailsContext must be used within a ClientDetailsProvider`);
  }
  return context;
}
