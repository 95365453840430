import React from 'react';
import { CardContent, Box } from '@material-ui/core';

export default function BasicCardContent({ children }) {
  return (
    <CardContent>
      <Box height="100%" display="flex" flexDirection="column" alignItems="center">
        {children}
      </Box>
    </CardContent>
  );
}
