import React from 'react';
import { Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export default function LoanDetailsPayment({ label, date, value, primary }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box color="rgba(34, 47, 62, 0.71)" fontSize="12px" letterSpacing="0.02px">
        {label}
      </Box>
      <Box
        color={primary ? 'primary.500' : 'rgba(34, 47, 62, 0.5)'}
        fontSize="24px"
        letterSpacing="0.4px"
        fontWeight={600}
      >
        {value ? (
          <NumberFormat value={value} displayType="text" thousandSeparator prefix="$" />
        ) : (
          '$0.00'
        )}
      </Box>
      <Box color="#222F3E" fontSize="14px" letterSpacing="-0.11px" fontWeight={500}>
        {date}
      </Box>
    </Box>
  );
}
