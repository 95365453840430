import { webAPI, APIv1 } from 'shared/utils/http';
import { buildFormData } from 'shared/utils';
import { ENDPOINTS } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const getLoanDetails = async (key, payload) => {
  const formData = new FormData();
  buildFormData(formData, payload);
  const response = await APIv1.post('/v1/web-service/get-loan-details', formData);
  return response.data;
};

export const getBorrowerDetails = async (key, { loanId }) => {
  const { data } = await webAPI.get(`${ENDPOINTS.getBorrowerDetails}${loanId}`);
  return data.result;
};

export const removeLoan = async (payload) => {
  const { data } = await webAPI.post('loan/remove-loan', payload);
  return data.result;
};

export const removeLoans = async (payload) => {
  const { data } = await webAPI.post('loan/remove-loans', payload);
  return data.result;
};

export const resetInstallPassword = async (payload) => {
  const { data } = await webAPI.post('users/reset-install-password', payload);
  return data;
};

export const addLoan = async (payload) => {
  const { data } = await webAPI.post('users/add-account', payload);
  return data;
};
