import { Grid } from '@material-ui/core';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import SettingsSectionContent from 'pages/Settings/Shared/SettingsSection/SettingsSectionContent/SettingsSectionContent';
import SettingsSectionHeader from 'pages/Settings/Shared/SettingsSection/SettingsSectionHeader/SettingsSectionHeader';
import SettingsSectionReset from 'pages/Settings/Shared/SettingsSection/SettingsSectionReset/SettingsSectionReset';
import SettingsSectionUpdate from 'pages/Settings/Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import React from 'react';
import NotificationsFormInput from './NotificationsFormInput/NotificationsFormInput';

export default function NotificationsForm() {
  return (
    <SettingsSectionContent>
      <FlexRow alignItems="flex-start" justifyContent="space-between">
        <SettingsSectionHeader
          title="Push Notifications"
          description="Send mobile push notifications to your clients"
        />
        <SettingsSectionReset />
      </FlexRow>
      <FlexColumn height="13rem">
        <Grid container spacing={4}>
          <Grid item xs>
            <NotificationsFormInput
              label="No. of days before due date"
              name="notifyBeforeDueDate"
            />
          </Grid>
          <Grid item xs>
            <NotificationsFormInput
              label="No. of days before expiry of card"
              name="notifyBeforeCardExp"
            />
          </Grid>
        </Grid>
        <SettingsSectionUpdate />
      </FlexColumn>
    </SettingsSectionContent>
  );
}
