import React from 'react';
import BasicModalCloseButton from 'components/BasicModal/BasicModalCloseButton/BasicModalCloseButton';
import { Box } from '@material-ui/core';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import BasicModalContent from 'components/BasicModal/BasicModalContent/BasicModalContent';
import Text from 'components/Text/Text';
import BasicDialog from 'components/BasicDialog/BasicDialog';
import { useRemoveLoan, useBorrowerDetails, useRemoveLoans } from 'services/loans/hooks';
import { useHistory } from 'react-router-dom';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import RemoveAccountSection from './RemoveAccountSection/RemoveAccountSection';

export default function RemoveAccount({ isOpen, onClose, name }) {
  const { data: borrowerDetails } = useBorrowerDetails();
  const history = useHistory();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();

  const [mutate, { isLoading }] = useRemoveLoan({
    onSuccess: () => {
      setAlertMessage('Loan removed for user successfully!');
      queryCache.invalidateQueries('signed-up', { refetchInactive: true });
      onClose();
      setTimeout(() => {
        history.push('/borrowers/signed-up');
      }, 2000);
    },
  });
  const [mutateForAll, { isLoading: isRemoveForAllLoading }] = useRemoveLoans({
    onSuccess: () => {
      setAlertMessage('Loan removed for all users successfully!');
      queryCache.invalidateQueries('signed-up', { refetchInactive: true });
      onClose();
      setTimeout(() => {
        history.push('/borrowers/signed-up');
      }, 2000);
    },
  });

  const handleRemoveForUser = () => {
    mutate({
      install_account_id: borrowerDetails?.linkAttempt?.installAccountId,
      loan_no: borrowerDetails?.loan?.loanNo,
    });
  };

  const handleRemoveForAll = () => {
    mutateForAll({
      loan_number: borrowerDetails?.loan?.loanNo,
    });
  };

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose}>
      <Box display="flex" flexDirection="column" width="430px" height="580px">
        <BasicModalCloseButton onClick={onClose} />
        <Box marginBottom="1.25rem">
          <BasicModalTitle>Remove Account</BasicModalTitle>
        </Box>
        <BasicModalContent flex={1} width="auto">
          <Text
            fontWeight={500}
            color="text.primary"
            fontSize="1rem"
            letterSpacing="0.025em"
            marginBottom="2rem"
          >
            {name}
          </Text>
          <RemoveAccountSection
            title="Remove for user"
            description="Unlink / hide this account for this user only (Account will still show if linked to other users)"
            primaryText="Remove for user"
            primaryClick={handleRemoveForUser}
            isLoading={isLoading}
          />
          <RemoveAccountSection
            title="Remove for all"
            description="Unlink / hide this account for all users (Account will no longer show in the system unless added by another user)"
            primaryText="Remove for all"
            secondaryText="Cancel"
            primaryClick={handleRemoveForAll}
            secondaryClick={onClose}
            isLoading={isRemoveForAllLoading}
          />
        </BasicModalContent>
      </Box>
    </BasicDialog>
  );
}
