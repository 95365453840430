import InterRegularWoff2 from 'assets/fonts/Inter-Regular.woff2';
import InterMediumWoff2 from 'assets/fonts/Inter-Medium.woff2';
import InterSemiBoldWoff2 from 'assets/fonts/Inter-SemiBold.woff2';
import InterBoldWoff2 from 'assets/fonts/Inter-Bold.woff2';

import MontserratRegularWoff2 from 'assets/fonts/Montserrat-Regular.woff2';
import MontserratMediumWoff2 from 'assets/fonts/Montserrat-Medium.woff2';
import MontserratSemiBoldWoff2 from 'assets/fonts/Montserrat-SemiBold.woff2';
import MontserratBoldWoff2 from 'assets/fonts/Montserrat-Bold.woff2';
// eslint-disable-next-line import/prefer-default-export
export const montserratRegular = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${MontserratRegularWoff2}) format('woff2')`,
};
export const montserratMediuam = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${MontserratMediumWoff2}) format('woff2')`,
};
export const montserratSemiBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `url(${MontserratSemiBoldWoff2}) format('woff2')`,
};
export const montserratBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${MontserratBoldWoff2}) format('woff2')`,
};
