import { MenuItem } from '@material-ui/core';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';
import React from 'react';

export default function TimezoneOptions() {
  return (
    <BasicSelectInput name="cron_timezone" placeholder="Select timezone">
      <MenuItem value="America/New_York">Eastern</MenuItem>
      <MenuItem value="America/Chicago">Central</MenuItem>
      <MenuItem value="America/Denver">Mountain</MenuItem>
      <MenuItem value="America/Phoenix">Mountain no DST</MenuItem>
      <MenuItem value="America/Anchorage">Alaska</MenuItem>
      <MenuItem value="America/Los_Angeles">Hawaii</MenuItem>
    </BasicSelectInput>
  );
}
