import { parse } from 'date-fns';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { isValidDate } from 'shared/utils';
import useInputStyles from '../useInputStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.border,
    },
    '&:hover': {
      borderColor: theme.palette.common.border,
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
  input: {
    '& > .MuiInputBase-root': {
      boxShadow: '0px 0px 8px rgba(34, 47, 62, 0.06)',
      minWidth: '160px',
      boxSizing: 'border-box',
      height: '3rem',
      background: 'white',
      color: theme.palette.text.primary,
      borderColor: theme.palette.common.border,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '8px',
      paddingLeft: '16px',
      paddingTop: '21px',
      paddingBottom: '8px',
      paddingRight: '24px',
      fontSize: '0.75rem',
      letterSpacing: '0.15px',
      '&:focus': {
        borderRadius: '8px',
        background: 'white',
      },
      '&::before, &::after': {
        content: 'none',
      },
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
  calendarButton: {
    padding: 0,
  },
  dialog: {
    '& .MuiDialogContent-root .MuiButton-root': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& .MuiDialogContent-root .MuiButton-root:first-of-type': {
      pointerEvents: 'all',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[700],
      padding: '0 0.5rem',
    },
    '& .MuiDialogContent-root .MuiButton-root:first-of-type:hover': {
      backgroundColor: theme.palette.primary[800],
    },
    '& h6.MuiPickersToolbarText-toolbarTxt': {
      fontWeight: '600',
      fontSize: '0.875rem',
      color: 'white',
    },
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
}));

export const BasicDateInput = ({ name, label, hideErrors, disabled, value, onChange, ...rest }) => {
  const inputClasses = useInputStyles();
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl className={clsx(classes.formControl)} classes={{ root: inputClasses.root }}>
        <KeyboardDatePicker
          label={label}
          name={name}
          format="MM/dd/yyyy"
          DialogProps={{ className: classes.dialog }}
          value={value ? parse(value, 'yyyy-MM-dd', new Date()) : null}
          placeholder="Select date to filter"
          onChange={onChange}
          disabled={disabled || false}
          InputLabelProps={{ className: inputClasses.label, shrink: true }}
          className={classes.input}
          KeyboardButtonProps={{ className: clsx(classes.calendarButton, inputClasses.icon) }}
          {...rest}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

export const DateInput = ({ name, label, currentValue, onChange: onChangeCb, disabled }) => {
  const classes = useStyles();
  const [value, setValue] = useState();

  useEffect(() => {
    const parsedDate = parse(currentValue, 'yyyy-MM-dd', new Date());
    if (typeof currentValue === 'string') {
      onChange(parsedDate);
    } else if (currentValue !== value) {
      setValue(typeof currentValue === 'string' ? parsedDate : currentValue);
    }
  }, [currentValue]);

  const onChange = (date, text) => {
    setValue(text);
    if (date && isValidDate(date)) {
      onChangeCb(date);
    } else {
      onChangeCb(null);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl className={clsx(classes.formControl)}>
        <KeyboardDatePicker
          label={label}
          name={name}
          format="MM/dd/yyyy"
          value={value}
          onChange={onChange}
          inputVariant="outlined"
          disabled={disabled || false}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};
