/* eslint-disable import/prefer-default-export */

import { webAPI } from 'shared/utils/http';
import { ENDPOINTS } from '../../constants';

export const getRules = async (key, { id }) => {
  const { data } = await webAPI.get(`${ENDPOINTS.getRulesDetails}${id}`);
  return data;
};

export const updateRule = async data => {
  const response = await webAPI.post(`${ENDPOINTS.updateRuleDetails}`, data);
  return response.data;
};
