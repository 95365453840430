import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import { useFormikContext } from 'formik';
import Icon from 'components/Icon/Icon';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import FormattedTextField from 'components/BasicInputs/BasicTextField/FormattedTextField/FormattedTextField';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '10rem',
  },
}));

export default function PaymentResponseForm({ isReceiptSent }) {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" marginBottom="1.5rem">
        <Box flex={1} marginRight="2rem">
          <FormattedTextField
            disabled={isReceiptSent}
            placeholder="1234234563"
            name="phoneNo"
            label="Mobile Number"
            startIcon={<Icon name="mobileAlt" />}
            formatProps={{
              format: '(###) ###-####',
              allowEmptyFormatting: true,
              mask: '_',
            }}
          />
        </Box>
        <Box flex={1}>
          <BasicTextField
            disabled={isReceiptSent}
            placeholder="receipt@reliable.com"
            name="email"
            label="Email Address"
            startIcon={<Icon name="mail" />}
          />
        </Box>
      </Box>
      <Box alignSelf="center">
        <ContainedButton
          isLoading={isSubmitting}
          type="submit"
          variant={isReceiptSent ? 'contained' : 'outlined'}
          color="primary"
          size="sm"
          disabled={isFormInvalid || isReceiptSent}
          className={classes.button}
        >
          {isReceiptSent ? 'Receipt Sent' : 'Send Receipt'}
        </ContainedButton>
      </Box>
    </Box>
  );
}
