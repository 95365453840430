import React from 'react';
import { Box } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import Text from 'components/Text/Text';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import useMixinStyles from 'styles/mixins';

export default function HomeCardActions({
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonURL,
  secondaryButtonColor = '#1A1A20',
}) {
  const classes = useMixinStyles();
  const { isSubmitting } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  return (
    <Box>
      <Box marginBottom="1.875rem">
        <ContainedButton disabled={isFormInvalid || isSubmitting} type="submit" fullWidth>
          {primaryButtonText}
        </ContainedButton>
      </Box>
      {secondaryButtonText && (
        <Link className={classes.noTextDecoration} to={secondaryButtonURL}>
          <Text
            color={secondaryButtonColor}
            letterSpacing="0.025em"
            fontWeight={600}
            fontSize="0.625rem"
          >
            {secondaryButtonText}
          </Text>
        </Link>
      )}
    </Box>
  );
}
