import { makeStyles } from '@material-ui/core';

const useMixinStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
  },
  properCase: {
    textTransform: 'initial',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  full: {
    height: '100%',
    width: '100%',
    flex: 1,
  },
  scroll: {
    overflow: 'scroll',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  absoluteCenter: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  scrollBar: {
    '&::-webkit-scrollbar-track': {
      background: 'hsla(0, 0%, 100%, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  noTextDecoration: {
    textDecoration: 'none',
  },
}));

export default useMixinStyles;
