import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';

export default function PADForm() {
  return (
    <>
      <Grid item xs={12}>
        <BasicTextField name="name" label="Name On Account" placeholder="John Doe" />
      </Grid>
      <Grid item xs={6}>
        <BasicTextField name="bankName" label="Bank Name" placeholder="Bank of America" />
      </Grid>
      <Grid item xs={6}>
        <BasicTextField name="bankNumber" label="Bank Number" placeholder="123456789" />
      </Grid>
      <Grid item xs={12}>
        <BasicTextField name="branchNumber" label="Branch Number" placeholder="234" />
      </Grid>
      <Grid item xs={12}>
        <BasicTextField name="accountNumber" label="Account Number" placeholder="000123456789" />
      </Grid>
    </>
  );
}
