import { Box } from '@material-ui/core';
import IconButton from 'components/Icon/IconButton/IconButton';
import { useFormikContext } from 'formik';
import React from 'react';

export default function SettingsSectionReset() {
  const { resetForm } = useFormikContext();
  return (
    <Box title="Restore to previous state">
      <IconButton name="restore" onClick={resetForm} />
    </Box>
  );
}
