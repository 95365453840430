import React, { useState, useContext, useMemo, createContext, useCallback } from 'react';

const SearchBar = createContext();

const SearchBarProvider = ({ children }) => {
  const [searchString, setSearchString] = useState('');

  const handleSearchStringChange = useCallback(
    e => {
      setSearchString(e.target.value);
    },
    [setSearchString],
  );

  const value = useMemo(
    () => ({
      searchString,
      setSearchString,
      handleSearchStringChange,
    }),
    [searchString, setSearchString, handleSearchStringChange],
  );

  return <SearchBar.Provider value={value}>{children}</SearchBar.Provider>;
};

export default SearchBarProvider;

export function useSearchBar() {
  const context = useContext(SearchBar);
  if (!context) {
    throw new Error(`useSearchBar must be used within a SearchBarProvider`);
  }
  return context;
}
