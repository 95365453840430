import { Box, FormControl, FormHelperText, InputLabel, makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import useMixinStyles from 'styles/mixins';
import useInputStyles from '../useInputStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
}));

export default function BasicUpload({
  name,
  hideErrors,
  children,
  accept = '.pdf,.jpg,.jpeg,.png',
}) {
  const classes = useStyles();
  const mixinClasses = useMixinStyles();
  const [field, meta, helpers] = useField({ name });

  const handleChange = (e) => {
    const reader = new FileReader();
    const file = e.currentTarget.files[0];
    if (file) {
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
      helpers.setValue(file);
    }
  };
  return (
    <FormControl classes={{ root: classes.root }} error={Boolean(meta.error && meta.touched)}>
      <label htmlFor={`file-${name}`}>
        <Box
          component="input"
          display="none"
          onChange={handleChange}
          accept={accept}
          type="file"
          id={`file-${name}`}
        />
        <Box className={mixinClasses.clickable}>{children}</Box>
      </label>
      {!hideErrors && meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
