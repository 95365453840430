import React from 'react';
import { Dialog } from '@material-ui/core';
import Form from 'components/Form/Form';
import RefundDialog from './RefundDialog';

export const REFUND_OPTIONS = {
  FULL_REFUND: '0',
  PARTIAL_REFUND: '1',
};

const initialValues = {
  amount: '0',
  option: REFUND_OPTIONS.FULL_REFUND,
};

export default function Refund({ openRefund, val, handleClosePayment, pmtId }) {
  return (
    <Dialog open={openRefund} onClose={handleClosePayment} aria-labelledby="form-dialog-title">
      <Form initialValues={initialValues}>
        <RefundDialog
          val={val}
          handleClosePayment={handleClosePayment}
          pmtId={pmtId}
          openRefund={openRefund}
        />
      </Form>
    </Dialog>
  );
}
