import React from 'react';
import { Box } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';
import Spinner from '../Spinner';

export default function InputSpinner() {
  const mixinClasses = useMixinStyles();
  return (
    <Box right="1rem" className={mixinClasses.absoluteCenter}>
      <Spinner size={16} />
    </Box>
  );
}
