import React from 'react';
import { useStyles } from 'containers/borrowers/borrowerStyles';
import { Typography, Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import useMixinStyles from 'styles/mixins';

export default function LoanDetailsRow({ attribute, value }) {
  const classes = useStyles();
  const mixinClasses = useMixinStyles();

  return (
    <Box
      position="relative"
      marginBottom="1rem"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text
        fontSize="0.75rem"
        color="rgba(124, 141, 160, 0.88)"
        letterSpacing="-0.12px"
        marginRight="2rem"
      >
        {attribute}
      </Text>
      <Text
        fontSize="0.75rem"
        color="rgba(34, 47, 62, 0.87)"
        letterSpacing="-0.12px"
        fontWeight={500}
        className={mixinClasses.ellipsis}
      >
        {value || 'N/A'}
      </Text>
    </Box>
  );
}
