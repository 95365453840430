import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Grid, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';

const useStyles = makeStyles((theme) =>
  createStyles({
    pagination: {
      justifyContent: 'center',
      '& > li:first-of-type button, & > li:last-of-type button': {
        background: 'rgba(34, 47, 62, 0.09)',
      },
    },
    paginationItem: {
      '&.Mui-selected&.Mui-selected': {
        backgroundColor: theme.palette.primary[500],
        color: 'white',
      },
    },
  }),
);

export default function BorrowersPagination({ count }) {
  const classes = useStyles();
  const { currentPage, setQuery } = useBorrowersFilters();

  const handleChangePage = (event, newPage) => {
    setQuery({ page: newPage });
  };

  return (
    <Box marginTop="auto">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} className={classes.list}>
          <Pagination
            count={count}
            onChange={handleChangePage}
            page={currentPage}
            shape="rounded"
            classes={{ ul: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem {...item} className={classes.paginationItem} shape="rounded" />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
