import React from 'react';
import MuiPagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Grid, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FlexRow from 'components/FlexRow/FlexRow';
import { usePagination } from './PaginationProvider/PaginationProvider';
import RowsPerPage from './RowsPerPage/RowsPerPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    pagination: {
      justifyContent: 'center',
      '& > li:first-of-type button, & > li:last-of-type button': {
        background: 'rgba(34, 47, 62, 0.09)',
      },
    },
    paginationItem: {
      '&.Mui-selected&.Mui-selected': {
        backgroundColor: theme.palette.primary[500],
        color: 'white',
      },
    },
    list: {
      position: 'relative',
    },
    rows: {
      transform: 'translateY(-50%)',
    },
  }),
);

export default function Pagination({ count }) {
  const classes = useStyles();
  const { handleChangePage, data } = usePagination();

  return (
    <Box marginTop="auto">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} className={classes.list}>
          <FlexRow position="absolute" left="0" top="50%" className={classes.rows}>
            <RowsPerPage />
          </FlexRow>
          <MuiPagination
            count={count}
            onChange={handleChangePage}
            page={data.page}
            shape="rounded"
            classes={{ ul: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem {...item} className={classes.paginationItem} shape="rounded" />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
