import { webAPI } from 'shared/utils/http';

// eslint-disable-next-line import/prefer-default-export
export const getAPIUser = async () => {
  const { data } = await webAPI.get('/client/get-api-user');
  return data;
};

export const editEmail = async (payload) => {
  const { data } = await webAPI.post('users/edit-primary-email', payload);
  return data;
};

export const editMobile = async (payload) => {
  const { data } = await webAPI.post('users/edit-mobile-phone-number', payload);
  return data;
};

export const changePassword = async (payload) => {
  const { data } = await webAPI.post('users/change-password', payload);
  return data;
};
