import React from 'react';
import BasicCardActions from 'components/BasicCard/BasicCardActions/BasicCardActions';
import CommonTilesActions from 'components/BasicCard/BasicCardActions/CommonTilesActions/CommonTilesActions';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardSecondaryContent from 'components/BasicCard/BasicCardContent/BasicCardSecondaryContent/BasicCardSecondaryContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardHighlight from 'components/BasicCard/BasicCardHighlight/BasicCardHighlight';
import BasicCardCommon from 'components/BasicCard/BasicCardCommon/BasicCardCommon';
import { dateWithSlashes } from 'shared/utils';

const getPaymentAmount = (data) => {
  const totalPaymentAmount =
    data.pmtAmt +
    data.cardTransactionAmount +
    data.achTransactionAmount +
    data.platformTransactionFees;
  return totalPaymentAmount;
};

export default function PaymentMadeCard({ data }) {
  return (
    <BasicCard>
      <BasicCardContent>
        <BasicCardTitle>{`Account Number ${data.loan.loanNo}`}</BasicCardTitle>
        <BasicCardSecondaryContent>
          <BasicCardCommon
            attribute="Borrower"
            value={`${data.loan.borrower.firstName || '- -'} ${
              data.loan.borrower.lastName ? `${data.loan.borrower.lastName}` : ''
            }`}
          />
          <BasicCardCommon
            attribute="Payment Date"
            value={dateWithSlashes(data.paymentDate.date)}
          />
        </BasicCardSecondaryContent>
        <BasicCardHighlight
          attribute="Amount Paid"
          value={`$${data.pmtAmt ? getPaymentAmount(data).toFixed(2) : 'N/A'}`}
        />
      </BasicCardContent>
      <BasicCardActions>
        <CommonTilesActions detailsPathId={data.installLoanId} data={data} />
      </BasicCardActions>
    </BasicCard>
  );
}
