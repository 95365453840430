import React from 'react';
import { Grid } from '@material-ui/core';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import SettingsSectionUpdate from '../../../Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import SignInImageOption from './SignInImageOption/SignInImageOption';

export default function SignInImageForm() {
  return (
    <FlexColumn height="15rem">
      <Grid container spacing={4}>
        <Grid item xs>
          <SignInImageOption option="1" name="background_image1" />
        </Grid>
        <Grid item xs>
          <SignInImageOption option="2" name="background_image2" />
        </Grid>
        <Grid item xs>
          <SignInImageOption option="3" name="background_image3" />
        </Grid>
      </Grid>
      <SettingsSectionUpdate />
    </FlexColumn>
  );
}
