import React from 'react';
import { useField } from 'formik';
import BasicInput from '../BasicInput';

export default function BasicTextField({ name, ...restOfProps }) {
  const [field, meta] = useField(name);

  return (
    <BasicInput noShadow touched={meta.touched} error={meta.error} {...restOfProps} {...field} />
  );
}
