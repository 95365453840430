import { Box, Grid, makeStyles } from '@material-ui/core';
import FlexRow from 'components/FlexRow/FlexRow';
import Text from 'components/Text/Text';
import React from 'react';
import iPhoneNotifications from 'assets/img/notifications-iphone.png';
import SettingsSectionContent from 'pages/Settings/Shared/SettingsSection/SettingsSectionContent/SettingsSectionContent';
import useImagesMixins from 'styles/images-mixins';
import NotificationsPreviewItem from './NotificationsPreviewItem/NotificationsPreviewItem';
import NotificationsPreviewCarousel from './NotificationsPreviewCarousel/NotificationsPreviewCarousel';

const useStyles = makeStyles((theme) => ({
  image: {
    transform: 'scale(1.3)',
  },
}));

export default function NotificationsPreview() {
  const classes = useStyles();
  const imagesMixins = useImagesMixins();
  return (
    <Box height="100%" bgcolor="#c4c4c414">
      <NotificationsPreviewCarousel>
        <NotificationsPreviewItem
          title="NO. OF DAYS BEFORE DUE DATE PREVIEW"
          description="This is a preview of the notifications the users will receive"
          image={iPhoneNotifications}
        />
        <NotificationsPreviewItem
          title="NO. OF DAYS BEFORE EXPIRY OF CARD PREVIEW"
          description="This is a preview of the notifications the users will receive"
          image={iPhoneNotifications}
        />
      </NotificationsPreviewCarousel>
    </Box>
  );
}
