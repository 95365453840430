import { Grid } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import { useFormikContext } from 'formik';
import SettingsSectionContent from 'pages/Settings/Shared/SettingsSection/SettingsSectionContent/SettingsSectionContent';
import SettingsSectionHeader from 'pages/Settings/Shared/SettingsSection/SettingsSectionHeader/SettingsSectionHeader';
import React from 'react';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import NACHAUploadInput from './NACHAUploadInput/NACHAUploadInput';

export default function NACHAUploadHeader() {
  const { isFormInvalid } = useFormikHelpers();
  const { isSubmitting } = useFormikContext();
  return (
    <BasicCard noPadding>
      <SettingsSectionContent>
        <SettingsSectionHeader title="Add new file" />
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <BasicTextField
              name="nickname"
              label="Nickname (Optional)"
              placeholder="file_nickname"
            />
          </Grid>
          <Grid item xs={6}>
            <NACHAUploadInput />
          </Grid>
          <Grid item xs={2}>
            <ContainedButton
              disabled={isFormInvalid}
              isLoading={isSubmitting}
              type="submit"
              size="md"
            >
              Upload File
            </ContainedButton>
          </Grid>
        </Grid>
      </SettingsSectionContent>
    </BasicCard>
  );
}
