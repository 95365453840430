import useDisclosure from 'shared/hooks/useDisclosure';
import { useDeleteFailedLink } from 'services/borrowers/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import { API_RESPONSE_STATUS } from 'shared/constants/http';

export default function useLinkedFailedTiles() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryCache = useQueryCache();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const [mutate, { isLoading }] = useDeleteFailedLink({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        queryCache.invalidateQueries('failed-notify-lenders');
        queryCache.invalidateQueries('app-details');
        onClose();
        setAlertMessage('Success!');
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleOnDeleteFailedLink = async (notifyLenderId) => {
    mutate({ notifyLenderId });
  };

  return { handleOnDeleteFailedLink, isOpen, onOpen, onClose, isLoading };
}
