import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import useBTC from 'pages/Settings/BTC/useBTC';
import { useDetails, useUpdateCAPSettings, useUploadClientLogo } from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import { useClientDetailsContext } from 'services/client/context';
import SettingsSectionUploadImage from '../../Shared/SettingsSection/SettingsSectionUploadImage/SettingsSectionUploadImage';

export default function NavbarLogo() {
  const { capCustomizationSettings } = useClientDetailsContext();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateCAPSettings({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, {
        alertMessage: 'Navbar logo updated successfully!',
        keyToInvalidate: 'client-details',
      });
    },
  });

  const initialValues = useMemo(
    () => ({
      imageNavbarLogo: capCustomizationSettings?.imageNavbarLogo,
    }),
    [capCustomizationSettings?.imageNavbarLogo],
  );

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      imageNavbarLogo: values.imageNavbarLogo,
    });
    await mutate(formData);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSectionUploadImage
        title="Navbar Logo"
        description="This logo will appear in the navbar"
        name="imageNavbarLogo"
      />
    </Form>
  );
}
