export const getStoredAuthToken = () => sessionStorage.getItem('accessToken');

export const storeAuthToken = (token) => sessionStorage.setItem('accessToken', token);

export const removeStoredAuthToken = () => sessionStorage.removeItem('accessToken');

export const storeAPIUser = (user) => sessionStorage.setItem('APIUser', JSON.stringify(user));

export const getStoredAPIUser = () => JSON.parse(sessionStorage.getItem('APIUser'));

export const getStoredLoggedInUser = () => JSON.parse(sessionStorage.getItem('user'));

export const storeLoggedInUser = (user) => sessionStorage.setItem('user', JSON.stringify(user));

export const removeStoredLoggedInUser = () => sessionStorage.removeItem('user');
