import React from 'react';

export default function AddIcon({ width = '13px', height = '13px' }) {
  return (
    <svg
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.762 6.214C.762 3.068 3.235.518 6.285.518s5.524 2.55 5.524 5.696-2.473 5.697-5.524 5.697c-3.05 0-5.523-2.55-5.523-5.697zm10.042 0c0-2.574-2.023-4.66-4.519-4.66S1.766 3.64 1.766 6.214c0 2.574 2.023 4.661 4.52 4.661 2.495 0 4.518-2.087 4.518-4.66zM6.83 3.606a.554.554 0 0 0-.544-.499.557.557 0 0 0-.548.565V5.65H3.82l-.064.004a.56.56 0 0 0-.484.561c0 .312.246.565.548.565h1.917v1.977l.004.066c.032.281.263.5.544.5a.557.557 0 0 0 .548-.566V6.78H8.75l.064-.004a.56.56 0 0 0 .484-.56.557.557 0 0 0-.548-.566H6.833V3.672l-.004-.066z"
        fill="currentColor"
      />
    </svg>
  );
}
