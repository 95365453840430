import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { Box } from '@material-ui/core';
import FlexRow from 'components/FlexRow/FlexRow';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { useCardFees, useUpdateTimezone } from 'services/client/hooks';
import SettingsSection from '../../Shared/SettingsSection/SettingsSection';
import TimezoneRealTime from './TimezoneRealTime/TimezoneRealTime';
import TimezoneOptions from './TimezoneOptions/TimezoneOptions';
import SettingsSectionUpdate from '../../Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import useBTC from '../useBTC';

export default function Timezone() {
  const { data } = useCardFees();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateTimezone({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, {
        alertMessage: 'Timezone updated successfully!',
        keyToInvalidate: 'card-fees',
      });
    },
  });

  const initialValues = useMemo(
    () => ({
      cron_timezone: data.cron_timezone,
    }),
    [data.cron_timezone],
  );

  const handleOnSubmit = async (values) => {
    await mutate({
      cron_timezone: values.cron_timezone,
    });
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSection
        title="Timezone"
        description="Set time zone for the Payix system"
        name="logoPlaceholder"
      >
        <FlexColumn paddingTop="1.5rem" height="15rem">
          <FlexRow alignItems="flex-start">
            <Box flexBasis="50%" marginRight="2.5rem">
              <TimezoneRealTime />
            </Box>
            <Box flexBasis="50%">
              <TimezoneOptions />
            </Box>
          </FlexRow>
          <SettingsSectionUpdate />
        </FlexColumn>
      </SettingsSection>
    </Form>
  );
}
