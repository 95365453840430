import React from 'react';
import { usePaymentMade } from 'services/borrowers/hooks';
import BorrowersContainer from '../BorrowersContainer/BorrowersContainer';
import BorrowersFilters from '../BorrowersFilters/BorrowersFilters';
import BorrowersList from '../BorrowersList/BorrowersList';
import BorrowersListItemToggle from '../BorrowersFilters/BorrowersListItemToggle/BorrowersListItemToggle';
import BorrowersSearchBar from '../BorrowersFilters/BorrowersSearchBar/BorrowersSearchBar';
import BorrowersPagination from '../BorrowersFilters/BorrowersPagination/BorrowersPagination';
import PaymentMadeCard from './PaymentMadeCard/PaymentMadeCard';
import PaymentMadeTable from './PaymentMadeTable/PaymentMadeTable';
import BorrowersPaymentDate from '../BorrowersFilters/BorrowersPaymentDate/BorrowersPaymentDate';
import BorrowersFiltersProvider from '../BorrowersFiltersProvider/BorrowersFiltersProvider';

function PaymentMade() {
  const { data, isFetching } = usePaymentMade();
  return (
    <BorrowersContainer>
      <BorrowersFilters
        SearchBar={<BorrowersSearchBar isLoading={isFetching} />}
        ListTypeToggle={<BorrowersListItemToggle />}
        PaymentMade={<BorrowersPaymentDate />}
      />
      <BorrowersList
        data={data}
        CardComponent={PaymentMadeCard}
        TableComponent={PaymentMadeTable}
      />
      <BorrowersPagination count={data?.result?.totalPage} />
    </BorrowersContainer>
  );
}

export default function StatelessSignedUp() {
  return (
    <BorrowersFiltersProvider>
      <PaymentMade />
    </BorrowersFiltersProvider>
  );
}
