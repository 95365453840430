import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Icon from 'components/Icon/Icon';

const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer',
  },
}));

export default function BasicCardFloatingAction({ children, onClick }) {
  const classes = useStyles();
  return (
    <Box
      role="button"
      onClick={onClick}
      className={classes.container}
      position="absolute"
      top="-6px"
      right="-4px"
    >
      {children || <Icon name="delete" />}
    </Box>
  );
}
