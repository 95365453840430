import React from 'react';

export default function PeopleIcon({ width = '12px', height = '10px' }) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6h.55c.9 0 1.698.432 2.2 1.1A2.745 2.745 0 0 1 8.06 6.004L8.2 6h.55a2.75 2.75 0 0 1 2.75 2.75.55.55 0 0 1-1.096.064l-.007-.16a1.65 1.65 0 0 0-1.55-1.551L8.75 7.1H8.2a1.65 1.65 0 0 0-1.65 1.65.55.55 0 0 1-.486.546L6 9.3a.55.55 0 0 1-.546-.486l-.007-.16a1.65 1.65 0 0 0-1.55-1.551L3.8 7.1h-.55A1.65 1.65 0 0 0 1.6 8.75a.55.55 0 1 1-1.1 0 2.75 2.75 0 0 1 2.63-2.747L3.25 6zm0-5.5a2.2 2.2 0 1 1 0 4.4 2.2 2.2 0 0 1 0-4.4zm5.5 0a2.2 2.2 0 1 1 0 4.4 2.2 2.2 0 0 1 0-4.4zm-5.5 1.1a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2zm5.5 0a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2z"
        fill="#F5F6F8"
      />
    </svg>
  );
}
