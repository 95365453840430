import React from 'react';

export default function SuccessIcon({ width, height }) {
  return (
    <svg
      width={width || 76}
      height={height || 76}
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76 38c0 20.987-17.013 38-38 38S0 58.987 0 38 17.013 0 38 0s38 17.013 38 38z"
        fill="#008949"
      />
      <path
        opacity=".2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.903 55.8c10.2-.22 24.841-1.54 34.597-9.3C53.483 35.274 61.6 20.89 60.752 7.56A37.832 37.832 0 0 0 38 0C18.074 0 1.73 15.337.13 34.85c.288 8.175 1.844 15.953 4.774 20.95z"
        fill="#fff"
      />
      <path
        d="M32.502 57.452c-1.553 0-3.104-.665-3.991-1.996L16.095 39.493c-1.774-2.218-1.33-5.322.886-7.095 2.217-1.774 5.322-1.33 7.095.886l8.426 10.865L51.126 19.98c1.774-2.217 4.878-2.66 7.095-.887 2.217 1.774 2.66 4.878.887 7.095L36.493 55.456c-1.109 1.109-2.44 1.996-3.991 1.996z"
        fill="#fff"
      />
    </svg>
  );
}
