import React from 'react';
import { MenuItem } from '@material-ui/core';
import BasicSelectInput from '../BasicSelectInput';

const DEFAULT_OPERATORS = ['=', '!=', '>', '>=', '<', '<='];

export default function OperatorsSelect({ name, label, disabled, operators = DEFAULT_OPERATORS }) {
  return (
    <BasicSelectInput size="sm" noIcon name={name} label={label} disabled={disabled}>
      {operators.map((operator) => (
        <MenuItem key={operator} value={operator}>
          {operator}
        </MenuItem>
      ))}
    </BasicSelectInput>
  );
}
