import React from 'react';

export default function CheckIcon({ width, height }) {
  return (
    <svg
      width={width || 16}
      height={height || 12}
      fill="none"
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.322.485c.463-.551 1.316-.647 1.906-.214.59.432.691 1.23.228 1.78l-7.963 9.464c-.503.598-1.452.651-2.028.114L.398 6.896a1.21 1.21 0 0 1 0-1.792 1.426 1.426 0 0 1 1.92 0l3.985 3.722L13.322.485z"
        fill="#008949"
      />
    </svg>
  );
}
