import React from 'react';
import { BasicDateInput } from 'components/BasicInputs/BasicDateInput/BasicDateInput';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';
import { dateWithDashes } from 'shared/utils';
import { isValid } from 'date-fns';

export default function BorrowersPaymentDate() {
  const { setQuery, paymentDate } = useBorrowersFilters();
  const handleDateChange = (date) => {
    const dateFormated = isValid(date) ? dateWithDashes(date) : undefined;
    setQuery({ date: dateFormated });
  };
  return (
    <BasicDateInput
      onChange={handleDateChange}
      value={paymentDate}
      label="Payment Made On"
      name="paymentMade"
      disableFuture
      clearable
    />
  );
}
