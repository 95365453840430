import { createMuiTheme } from '@material-ui/core';
import { montserratRegular, montserratBold, montserratMediuam, montserratSemiBold } from './fonts';

const materialTheme = createMuiTheme({
  palette: {
    error: {
      50: '#EB4D4B',
      100: '#eb4d4b0d',
      200: '#EB4D4B1C',
      300: '#EB4D4B',
      400: '#EB4D4B',
      500: '#EB4D4B',
      600: '#EB4D4B',
      700: '#EB4D4B',
      800: '#EB4D4B',
      900: '#EB4D4B',
    },
    common: {
      border: '#222F3E30',
      borderLight: 'rgba(34, 47, 62, 0.1)',
      backgroundMain: '#fbfbfd',
      backgroundMainLight: '#F5F6F864',
      backgroundCardHighlight: '#F5F6F8',
      backgroundLight: 'rgba(187, 195, 208, 0.15)',
      borderInput: '#cccfd4',
      navbar: '#1A1A20',
      secNavbarText: '#222F3E80',
      label: 'rgba(124, 141, 160, 0.88)',
    },
    text: {
      light: '#F5F6F8',
      primary: '#222F3E',
      secondary: '#7C8DA0E1',
      lightHeaderTitle: 'rgba(34, 47, 62, 0.37)',
      lightHeaderDescription: 'rgba(34, 47, 62, 0.5)',
    },
    primary: {
      50: '#ebfff580',
      100: '#008A4919',
      200: '#008A4919',
      300: '#07ae5e8f',
      400: '#00a757',
      500: '#008949',
      600: '#008646',
      700: '#00753d',
      800: '#0a523b',
      900: '#004323',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    subtitle1: {
      fontSize: '0.75rem',
    },
  },
  radius: {
    containers: '8px',
  },
  boxShadows: {
    card: '0px 4px 8px rgba(34, 47, 62, 0.04)',
  },
  borders: {
    listItem: '0.2px solid rgba(124, 141, 160, 0.88)',
    containers: '1px solid #222F3E19',
  },
  overrides: {
    root: {
      backgroundColor: '#123412',
    },
    MuiCssBaseline: {
      '@global': {
        'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        body: {
          backgroundColor: '#fbfbfd',
        },
        '#root': {
          height: 'auto !important',
        },
        "*,*:after,*:before,input[type='search']": {
          boxSizing: 'border-box',
        },
        '@font-face': [montserratRegular, montserratMediuam, montserratSemiBold, montserratBold],
      },
    },
  },
});

export default materialTheme;
