import React from 'react';

export default function BankIcon({ width, height }) {
  return (
    <svg
      width={width || 54}
      height={height || 34}
      viewBox="0 0 54 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 31.112A2.883 2.883 0 0 0 2.88 34h48.24c1.59 0 2.88-1.293 2.88-2.888V2.888A2.883 2.883 0 0 0 51.12 0H2.88A2.884 2.884 0 0 0 0 2.888v28.224z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.367 4.678l10.451 4.55a.35.35 0 0 1 .21.322c0 .116-.209.561-.417.898a.973.973 0 0 1-.927.676h-.05v1.05a.35.35 0 0 1-.349.35h-.348v11.198h.348a.35.35 0 0 1 .349.35v1.4h1.045v.875h.35v1.55h-21.6v-1.55h.347v-.875h1.045v-1.4a.35.35 0 0 1 .349-.35h.348V12.524h-.348a.35.35 0 0 1-.349-.35v-1.05a.959.959 0 0 1-.874-.564 9.28 9.28 0 0 1-.49-.872.35.35 0 0 1 .182-.46l10.451-4.55a.347.347 0 0 1 .277 0zm-2.578 19.394v1.4h-2.09v-1.4a.35.35 0 0 0-.349-.35h-.348V12.524h.348a.35.35 0 0 0 .348-.35v-1.05h2.09v1.05c0 .193.157.35.35.35h.348v11.198h-.349a.35.35 0 0 0-.348.35zm6.967 0v1.4h-2.09v-1.4a.35.35 0 0 0-.348-.35h-.349V12.524h.349a.35.35 0 0 0 .348-.35v-1.05h2.09v1.05c0 .193.156.35.349.35h.348v11.198h-.348a.35.35 0 0 0-.349.35z"
        fill="#1CA0BD"
      />
    </svg>
  );
}
