import React from 'react';
import { Box } from '@material-ui/core';
import SpinnerWrapped from '../SpinnerWrapped/SpinnerContainer';

export default function FullPageSpinner() {
  return (
    <Box height="100vh" width="100vw">
      <SpinnerWrapped margin="0" />
    </Box>
  );
}
