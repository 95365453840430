import React from 'react';
import { Box, Grid } from '@material-ui/core';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import EmptyState from 'components/EmptyState/EmptyState';
import { useBorrowersFilters } from '../BorrowersFiltersProvider/BorrowersFiltersProvider';

export default function BorrowersList({ data, CardComponent, TableComponent, EmptyStateComponent }) {
  const { isListOfCards } = useBorrowersFilters();
  return (
    <Box marginBottom="3rem" paddingTop="2rem" marginTop="-2rem">
      {data &&
        data?.status !== API_RESPONSE_STATUS.FAILED &&
        (isListOfCards ? (
          <Grid container spacing={3}>
            {data.result.data.map(item => (
              <Grid item xs={12} sm={6} md={3} key={data.id}>
                <CardComponent data={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableComponent data={data.result.data} />
        ))}
      {data && !data?.result?.data?.length && (EmptyStateComponent && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <EmptyStateComponent />
          </Grid>
        </Grid>
      ) || <EmptyState />)}
      {!data && <SpinnerWrapped size={64} />}
    </Box>
  );
}
