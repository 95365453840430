import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';

export default function RulesFormSectionError({ inputName }) {
  const [field, meta] = useField(inputName);
  return (
    <FormControl error={Boolean(meta.error)}>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
