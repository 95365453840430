import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    backgroundColor: theme.palette.error[100],
  },
  bannerTitle: {
    fontWeight: 600,
    fontSize: '0.6875rem',
    letterSpacing: '-0.09px',
    textTransform: 'capitalize',
    lineHeight: 1,
    alignSelf: 'flex-start',
  },
}));

export default function BasicCardBanner({ children, className, ...rest }) {
  const classes = useStyles();
  return (
    <Box padding="0.5rem 0.875rem" className={clsx(classes.bannerContainer, className)} {...rest}>
      <Typography className={classes.bannerTitle} color="error">
        {children}
      </Typography>
    </Box>
  );
}
