import React from 'react';

export default function ClockIcon({ width, height }) {
  return (
    <svg
      height={height || 24}
      viewBox="0 0 512 512"
      width={width || 24}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M256 0C114.845 0 0 114.839 0 256s114.845 256 256 256c141.161 0 256-114.839 256-256S397.155 0 256 0zm0 474.628C135.45 474.628 37.372 376.55 37.372 256S135.45 37.372 256 37.372s218.628 98.077 218.628 218.622c0 120.556-98.078 218.634-218.628 218.634z" />
      <path d="M343.202 256h-80.973V143.883c0-10.321-8.365-18.686-18.686-18.686s-18.686 8.365-18.686 18.686v130.803c0 10.321 8.365 18.686 18.686 18.686h99.659c10.321 0 18.686-8.365 18.686-18.686S353.523 256 343.202 256z" />
    </svg>
  );
}
