import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { Fade, Snackbar, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useIdleTimer } from 'react-idle-timer';

import useDisclosure from 'shared/hooks/useDisclosure';
import { webAPI, API } from 'shared/utils/http';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import { removeStoredAuthToken, removeStoredLoggedInUser } from 'shared/utils/authToken';
import { SERVER_RESPONSE_MESSAGES } from 'shared/constants/http';
import { useClientDetailsContext } from 'services/client/context';

const SessionTimeExpirerContext = React.createContext();

export default function SessionTimeExpirerProvider({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const alertRef = useRef(null);
  const [expired, setExpired] = useState(false);
  const { logout, currentUser } = useCurrentUser();
  const { sessionTimeoutCap } = useClientDetailsContext();
  const [isTimerActive, setIsTimerActive] = useState(false);
  const numberSessionTimeoutCap = Number(sessionTimeoutCap) || 15;
  const aboutToExpireTimeInMinutes = Number((numberSessionTimeoutCap / 2).toFixed(2));
  

  const { reset: resetAboutToExpireSessionTimer } = useIdleTimer({
    timeout: 1000 * 60 * aboutToExpireTimeInMinutes,
    onIdle: () => {
      if (currentUser) {
        onOpen()
      }
    },
    startOnMount: isTimerActive,
  });
  const { } = useIdleTimer({
    timeout: 1000 * 60 * numberSessionTimeoutCap,
    onIdle: () => {
      if (currentUser) {
        logout();
        setExpired(true);
      }
    },
    onActive: () => resetAboutToExpireSessionTimer(),
    startOnMount: isTimerActive,
  });

  useEffect(() => {
    if (currentUser && expired) {
      onClose();
      setExpired(false);
    }
  }, [currentUser])
  
  const expireSession = error => {
      if (
        error.response?.data?.message === SERVER_RESPONSE_MESSAGES.UNATORHIZED ||
        error.response?.data?.statusCode === 460 || error.response?.data?.statusCode === 604 ||
        (error.status === 401 && error.config.headers.Authorization)
      ) {
        logout();
        removeStoredAuthToken();
        removeStoredLoggedInUser();
      } 
      return error;
    }

  useEffect(() => {
    const webAPISessionInterceptorResponse = webAPI.interceptors.response.use(
      config => {
        return config;
      },
      expireSession,
    );
    const APISessionInterceptorResponse = API.interceptors.response.use(
      config => {
        return config;
      },
      expireSession,
    );

    return () => {
      webAPI.interceptors.response.eject(webAPISessionInterceptorResponse);
      API.interceptors.response.eject(APISessionInterceptorResponse);
    };
  }, []);

  const value = useMemo(() => ({ isTimerActive, setIsTimerActive }), [
    isTimerActive,
    setIsTimerActive,
  ]);

  return (
    <SessionTimeExpirerContext.Provider value={value}>
      {children}
      <Fade in={isOpen}>
        <Snackbar
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert
            ref={alertRef}
            variant="filled"
            severity="warning"
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {expired ? (
              <strong>Your session has expired</strong>
            ) : (
              <>
                <strong>
                  You've been idle for {aboutToExpireTimeInMinutes} minutes. In {numberSessionTimeoutCap - aboutToExpireTimeInMinutes} minutes your session will expire
                </strong>
                <br />
                Close this dialog to continue in session
              </>
            )}
          </Alert>
        </Snackbar>
      </Fade>
    </SessionTimeExpirerContext.Provider>
  );
}

export function useSessionTimeExpirer() {
  const context = useContext(SessionTimeExpirerContext);
  if (!context) {
    throw new Error(`useSessionTimeExpirer must be used within a SessionTimeExpirerProvider`);
  }
  return context;
}
