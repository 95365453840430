import React from 'react';

export default function PaymentIcon({ width = '459.669', height = '459.669', color = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 459.669 459.669"
      fill="currentColor"
    >
      <path d="M404.723 76.087H54.947C24.648 76.087 0 100.735 0 131.035v197.599c0 30.298 24.648 54.948 54.947 54.948h349.775c30.297 0 54.947-24.65 54.947-54.948V131.035c.001-30.3-24.649-54.948-54.946-54.948zm24.543 252.546c0 13.534-11.01 24.544-24.543 24.544H54.947c-13.533 0-24.545-11.01-24.545-24.544V196.214h398.863l.001 132.419zm0-175.794l-398.863.029v-21.834c0-13.534 11.012-24.545 24.545-24.545h349.775c13.533 0 24.543 11.011 24.543 24.545v21.805z" />
      <path d="M68.135 324.98h83.23a5.398 5.398 0 0 0 5.398-5.396v-16.421a5.398 5.398 0 0 0-5.398-5.397h-83.23a5.398 5.398 0 0 0-5.398 5.397v16.421a5.397 5.397 0 0 0 5.398 5.396zM312.363 249.025a37.8 37.8 0 0 1 23.006 7.768 37.806 37.806 0 0 1 23.006-7.768c20.975 0 37.979 17.004 37.979 37.979s-17.005 37.977-37.979 37.977a37.791 37.791 0 0 1-23.006-7.768 37.795 37.795 0 0 1-23.006 7.768c-20.976 0-37.979-17.002-37.979-37.977s17.005-37.979 37.979-37.979z" />
    </svg>
  );
}
