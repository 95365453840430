import React from 'react';
import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import { useFormikContext } from 'formik';

export default function PaymentResponseHeader({ paymentFailed, isSchedule, responseMessage }) {
  const { values } = useFormikContext();
  const { refund } = values;
  return (
    <Box alignSelf="center" marginBottom="1rem">
      <Box color="primary.500" display="flex" justifyContent="center" marginBottom="0.5rem">
        <Icon height={56} width={56} name={paymentFailed ? 'failure' : 'success'} />
      </Box>
      {!isSchedule && !refund && (
        <Text
          textAlign="center"
          fontSize="1.125rem"
          color="text.primary"
          fontWeight={700}
          letterSpacing="0.27px"
        >
          {paymentFailed ? 'Payment Failed' : 'Payment Successful'}
        </Text>
      )}
      {isSchedule && (
        <Text
          textAlign="center"
          fontSize="1.125rem"
          color="text.primary"
          fontWeight={700}
          letterSpacing="0.27px"
        >
          {paymentFailed ? 'Payment Scheduled Failed' : 'Payment Scheduled Successfully'}
        </Text>
      )}
      {refund && (
        <Text
          textAlign="center"
          fontSize="1.125rem"
          color="text.primary"
          fontWeight={700}
          letterSpacing="0.27px"
        >
          {paymentFailed ? 'Credit Failed' : 'Credit Successful'}
        </Text>
      )}
      {(isSchedule || paymentFailed) && (
        <Text
          textAlign="center"
          fontSize="0.875rem"
          color="text.primary"
          fontWeight={500}
          letterSpacing="0.2px"
        >
          {responseMessage}
        </Text>
      )}
    </Box>
  );
}
