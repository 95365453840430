import React from 'react';
import Icon from 'components/Icon/Icon';
import * as yup from 'yup';
import * as JSEncrypt from 'jsencrypt';
import Form from 'components/Form/Form';
import { useResetPassword } from 'services/auth/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import PasswordField from 'components/BasicInputs/BasicTextField/PasswordField/PasswordField';
import { useHistory, useParams } from 'react-router';
import HomeCard from '../HomeCard/HomeCard';
import HomeCardHeader from '../HomeCard/HomeCardHeader/HomeCardHeader';
import HomeCardForm from '../HomeCard/HomeCardForm/HomeCardForm';
import HomeCardFormInput from '../HomeCard/HomeCardForm/HomeCardFormInput/HomeCardFormInput';
import HomeCardActions from '../HomeCard/HomeCardActions/HomeCardActions';
import { ENV } from '../../../constants';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password Must contain at least one number. ')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
  confirm_password: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const initialValues = {
  confirm_password: '',
  password: '',
  reset_code: '',
};

export default function ResetPassword() {
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const { resetCode } = useParams();
  const history = useHistory();
  const [mutate] = useResetPassword({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setAlertMessage(response.message);
        history.push('/');
        return;
      }
      setErrorAlertMessage(response.message);
    },
    onError: () => {
      setErrorAlertMessage('There was an issue fulfilling the request');
    },
  });

  const handleOnSubmit = async (values) => {
    const key = new JSEncrypt.JSEncrypt();
    key.setPublicKey(ENV.TOKEN);
    const password = key.encrypt(values.password);
    const confirmPassword = key.encrypt(values.confirm_password);
    await mutate({
      password,
      confirm_password: confirmPassword,
      reset_code: resetCode,
    });
  };

  return (
    <HomeCard>
      <HomeCardHeader title="Reset password" description="Enter your new password." />
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
      >
        <HomeCardForm>
          <HomeCardFormInput>
            <PasswordField
              startIcon={<Icon name="lock" />}
              name="password"
              label="New Password"
              placeholder="••••••"
              type="password"
            />
          </HomeCardFormInput>
          <HomeCardFormInput>
            <PasswordField
              startIcon={<Icon name="lock" />}
              name="confirm_password"
              label="Confirm New Password"
              placeholder="••••••"
              type="password"
            />
          </HomeCardFormInput>
        </HomeCardForm>
        <HomeCardActions
          secondaryButtonColor="primary.500"
          primaryButtonText="Reset Password"
          secondaryButtonText="LOGIN"
          secondaryButtonURL="/"
        />
      </Form>
    </HomeCard>
  );
}
