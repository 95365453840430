import React from 'react';

export default function ListIcon({ width, height }) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 18a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h14zm0-7a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h14zm0-7a1 1 0 1 1 0 2H7a1 1 0 0 1 0-2h14zM2 4h2v2H2V4zm0 7h2v2H2v-2zm0 7h2v2H2v-2z"
        fill="#7C8DA0"
        fillOpacity=".88"
      />
    </svg>
  );
}
