import React from 'react';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import { useFormikContext } from 'formik';

export default function StaticGroupSwitch() {
  const { values } = useFormikContext();
  return (
    <Box marginBottom="1.25rem" display="flex" alignItems="center">
      <Text
        fontSize="0.75rem"
        fontWeight={values.isStatic ? 600 : 400}
        lineHeight="0.875rem"
        marginRight="1rem"
        color={values.isStatic ? 'text.primary' : '#1A1A20'}
      >
        STATIC GROUP
      </Text>
      <Box>
        <BasicSwitch name="isStatic" />
      </Box>
    </Box>
  );
}
