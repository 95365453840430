import React from 'react';
import { Box } from '@material-ui/core';

export default function LoanDetailsBalance({ label, value }) {
  return (
    <Box
      padding="0.75rem 0.875rem"
      borderRadius="4px"
      bgcolor="rgba(245, 246, 248, 0.38)"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Box
        marginBottom="0.25rem"
        fontSize="12px"
        color="rgba(124, 141, 160, 0.88)"
        letterSpacing="-0.1px"
      >
        {label}
      </Box>
      <Box fontSize="18px" color="rgba(34, 47, 62, 0.87)" letterSpacing="-0.18px">
        {value}
      </Box>
    </Box>
  );
}
