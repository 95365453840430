import React from 'react';
import { Box } from '@material-ui/core';
import AddNewGroupInput from 'components/AddNewGroupModal/AddNewGroupInput/AddNewGroupInput';
import StaticGroupSwitch from 'components/AddNewGroupModal/AddNewGroupInput/StaticGroupSwitch/StaticGroupSwitch';
import LoanStatusCodeInput from 'components/AddNewGroupModal/AddNewGroupInput/LoanStatusCodeInput/LoanStatusCodeInput';
import { useFormikContext } from 'formik';
import useMixinStyles from 'styles/mixins';

const LOAN_STATUS_CODE_OPERATORS = ['=', '!='];

export default function GroupDetailsForm({ maxHeight = '70.5vh' }) {
  const { values } = useFormikContext();
  const mixinStyles = useMixinStyles();
  return (
    <Box
      overflow="scroll"
      maxHeight={maxHeight}
      className={mixinStyles.scrollBar}
      paddingRight="0.5rem"
      marginBottom="1rem"
    >
      <AddNewGroupInput placeholder="Enter group name" inputName="groupName" label="GROUP NAME" />
      <StaticGroupSwitch />
      {!values.isStatic && (
        <>
          <AddNewGroupInput
            operatorName="dpdOperator"
            inputName="daysPastDue"
            switchName="dpdHide"
            label="DAYS PAST DUE"
          />
          <AddNewGroupInput
            operatorName="potentialDpdOperator"
            inputName="potentialDaysPastDue"
            switchName="potentialDpdHide"
            label="POTENTIAL DAYS PAST DUE"
          />
          <AddNewGroupInput
            operatorName="nsfOperator"
            inputName="nsfCount"
            switchName="nsfHide"
            label="NFS COUNT"
          />
          <AddNewGroupInput
            operatorName="tmdOperator"
            inputName="totalAmountDue"
            switchName="tmdHide"
            isMoneyInput
            label="TOTAL AMOUNT DUE"
          />
          <AddNewGroupInput
            operatorName="rpaOperator"
            inputName="regularPaymentAmount"
            switchName="rpaHide"
            isMoneyInput
            label="REGULAR PAYMENT AMOUNT"
          />
          <AddNewGroupInput
            operatorName="bpOperator"
            inputName="brokenPromise"
            switchName="bpHide"
            label="BROKEN PROMISE TO PAY"
          />
          <AddNewGroupInput
            operatorName="pbOperator"
            inputName="principalBalance"
            switchName="pbHide"
            isMoneyInput
            label="PRINCIPAL BALANCE"
          />
          <AddNewGroupInput
            operatorName="lsOperator"
            inputName="loanStatusCode"
            switchName="lsHide"
            label="LOAN STATUS CODE"
            placeholder="Select Loan Status Code"
            InputComponent={LoanStatusCodeInput}
            customOperators={LOAN_STATUS_CODE_OPERATORS}
          />
        </>
      )}
    </Box>
  );
}
