import React, { useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';
import Icon from 'components/Icon/Icon';
import ConfirmationPopover from 'components/ConfirmationPopover/ConfirmationPopover';
import BasicButton from 'components/BasicButton/BasicButton';
import { useDeleteAnyScheduledPayment } from 'services/payments/hooks';
import { buildFormData } from 'shared/utils';
import { PAYMENT_CHANNELS, SCHEDULED_PAYMENT_TYPES } from 'shared/constants/payments';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import produce from 'immer';
import { useQueryCache } from 'react-query';

export default function ScheduleListItem({ data, type }) {
  const mixinStyles = useMixinStyles();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const queryCache = useQueryCache();
  const [mutate, { isLoading }] = useDeleteAnyScheduledPayment({
    onMutate: (formData) => {
      const typeOfScheduledPayment = formData.get('typeOfScheduledPayment');
      setAnchorEl(null);
      setAlertMessage('Scheduled payment removed successfully!');
      const previousItems = queryCache.getQueryData([
        'schedule-list',
        { offset: 0, limit: 2000, loanId: data.loanId, type },
      ]);

      queryCache.setQueryData(
        ['schedule-list', { offset: 0, limit: 2000, loanId: data.loanId, type }],
        (old) => {
          const updatedLoanDetails = produce(old, (draft) => {
            draft.data = draft.data.filter((prevScheduledPayment) => {
              if (Number(typeOfScheduledPayment) === SCHEDULED_PAYMENT_TYPES.ONE_TIME) {
                return prevScheduledPayment.id !== data.id;
              }
              if (Number(typeOfScheduledPayment) === SCHEDULED_PAYMENT_TYPES.RECURRING) {
                return prevScheduledPayment.groupId !== data.groupId;
              }
              return prevScheduledPayment;
            });
          });
          return updatedLoanDetails;
        },
      );

      return () =>
        queryCache.setQueryData(
          ['schedule-list', { offset: 0, limit: 2000, loanId: `${data.loanId}`, type }],
          previousItems,
        );
    },
    onError: () => {
      setErrorAlertMessage('There was a problem removing the scheduled payment');
    },
  });

  const handleDeleteScheduledPayment = (typeOfScheduledPayment) => {
    const formData = new FormData();
    const formDataObject = {
      typeOfScheduledPayment,
      loanId: data.loanId,
      ...(typeOfScheduledPayment === SCHEDULED_PAYMENT_TYPES.ONE_TIME && { scheduleId: data.id }),
      ...(typeOfScheduledPayment === SCHEDULED_PAYMENT_TYPES.RECURRING && {
        recurringId: data.groupId,
      }),
      channel: PAYMENT_CHANNELS.CAP,
    };
    buildFormData(formData, formDataObject);
    mutate(formData);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const getTitleMessage = () => {
    if (data.groupId) {
      return 'Do you want to delete this individual scheduled payment, or the recurring schedule?';
    }
    return 'Do you want to delete this scheduled payment?';
  };

  return (
    <>
      <Box
        role="button"
        onClick={handleClick}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={mixinStyles.clickable}
        color="error.500"
      >
        <Icon name="deleteAlt" width={18} height={18} />
      </Box>
      <ConfirmationPopover
        title={getTitleMessage()}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        actionButtons={[
          <Grid key="no" item xs>
            <BasicButton
              onClick={handleClose}
              fullWidth
              size="small"
              color="textPrimary"
              variant="text"
            >
              Cancel
            </BasicButton>
          </Grid>,
          <Grid key="deleteOne" item xs>
            <BasicButton
              color="error"
              onClick={() => handleDeleteScheduledPayment(SCHEDULED_PAYMENT_TYPES.ONE_TIME)}
              fullWidth
              size="small"
              variant="error"
              loading={isLoading}
              className={mixinStyles.noWrap}
            >
              Delete This Payment
            </BasicButton>
          </Grid>,
          <React.Fragment key="deleteAll">
            {data.groupId && (
              <Grid item xs>
                <BasicButton
                  color="error"
                  onClick={() => handleDeleteScheduledPayment(SCHEDULED_PAYMENT_TYPES.RECURRING)}
                  fullWidth
                  size="small"
                  variant="error"
                  disabled={isLoading}
                  className={mixinStyles.noWrap}
                >
                  Delete All
                </BasicButton>
              </Grid>
            )}
          </React.Fragment>,
        ]}
      />
    </>
  );
}
