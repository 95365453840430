import React from 'react';
import { useBorrowerDetails, useLoanDetails } from 'services/loans/hooks';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Text from 'components/Text/Text';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import useMixinStyles from 'styles/mixins';
import Icon from 'components/Icon/Icon';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import BasicModalGoBack from 'components/BasicModal/BasicModalGoBack/BasicModalGoBack';
import BasicButton from 'components/BasicButton/BasicButton';
import PaymentMethodsListItem from './PaymentMethodsListItem/PaymentMethodsListItem';
import WebPopContent from '../WebPopContent/WebPopContent';
import { useWebPopSwitch, WEBPOP_COMPONENTS } from '../WebPopSwitchProvider/WebPopSwitchProvider';
import WebPopSection from '../WebPopSection/WebPopSection';

const useStyles = makeStyles({
  addPaymentMethodButton: {
    borderRadius: '10px',
  },
});

export default function PaymentMethodsList() {
  const classes = useStyles();
  const { data: borrowerDetails, isLoading: isBorrowerDetailsLoading } = useBorrowerDetails();
  const { pushHistory, goBack } = useWebPopSwitch();
  const { data, isLoading } = useLoanDetails(borrowerDetails.loan.loanNo);
  const handleGoBack = () => {
    goBack();
  };

  return (
    <>
      <BasicModalTitle>
        <BasicModalGoBack onClick={handleGoBack} />
        Edit Payment Methods
      </BasicModalTitle>
      <WebPopContent>
        {isBorrowerDetailsLoading || isLoading ? (
          <SpinnerWrapped />
        ) : (
          <>
            <WebPopSection
              title="Payment Methods"
              rightSideOfTitle={
                <BasicButton
                  onClick={() => pushHistory(WEBPOP_COMPONENTS.ADD_PAYMENTS)}
                  className={classes.rightSideOfTitleButton}
                >
                  + Add new
                </BasicButton>
              }
            >
              <Grid justify="center" container spacing={3}>
                {data.result.data.paymentMethods.map((paymentMethod) => (
                  <Grid key={paymentMethod.id} item xs={12}>
                    <PaymentMethodsListItem
                      id={paymentMethod.id}
                      brand={paymentMethod.cardBrand}
                      lastFourDigits={paymentMethod.lastFour}
                      nameOnCard={paymentMethod.name}
                      nameOnAch={paymentMethod.nameOnAch}
                      nameOnPad={paymentMethod.nameOnPad}
                      paymentType={paymentMethod.paymentType}
                      bankName={paymentMethod.bankName}
                      expDate={paymentMethod.cardExpDate}
                      isSchedule={paymentMethod.isSchedule}
                    />
                  </Grid>
                ))}
              </Grid>
            </WebPopSection>
          </>
        )}
      </WebPopContent>
    </>
  );
}
