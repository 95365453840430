import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { useDetails, useUploadClientLogo, useUploadFavicon } from 'services/client/hooks';
import PayixLogoWhite from 'assets/img/payix-logo-white.svg';
import { buildFormData } from 'shared/utils';
import SettingsSectionUploadImage from '../../Shared/SettingsSection/SettingsSectionUploadImage/SettingsSectionUploadImage';
import useBTC from '../useBTC';

export default function Favicon() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUploadFavicon({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Favicon updated successfully!' });
    },
  });
  const initialValues = useMemo(
    () => ({
      favImageUrl: data.favImageUrl.trim() || PayixLogoWhite,
    }),
    [data],
  );

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      favicon: values.favImageUrl,
    });
    await mutate(formData);
  };
  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSectionUploadImage
        title="Favicon"
        description="Set a favicon for your browser"
        name="favImageUrl"
        accept=".ico"
      />
    </Form>
  );
}
