import React from 'react';
import { Button, makeStyles, Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { handleLogin } from 'commons';
import BasicButton from 'components/BasicButton/BasicButton';
import { useHistory } from 'react-router-dom';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';

const useStyles = makeStyles(theme => ({
  action: {
    marginRight: '0.5rem',
  },
  actionDetails: {
    width: 'auto',
    minWidth: 'auto',
    padding: '0.5rem',
    borderRadius: '50%',
  },
}));

export default function TableActions({ data, onLoginClick, detailsPathId }) {
  const history = useHistory();
  const classes = useStyles();
  const viewDetails = () => {
    const path = `/borrowers/signed-up/borrowers-details/loan-id/${detailsPathId || data.id}`;
    history.push(path);
  };
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <AccessControl perform={PERMISSIONS.BORROWERS.IMPERSONATE}>
        <BasicButton
          className={classes.action}
          size="small"
          onClick={onLoginClick || (() => handleLogin(
            data.linkAttempt?.installAccountId || data.installAccountId,
            data.loan.loanNo,
          ))}
        >
          Login
        </BasicButton>
      </AccessControl>
      <AccessControl perform={PERMISSIONS.BORROWERS.DETAILS}>
        <BasicButton
          className={classes.actionDetails}
          onClick={viewDetails}
        >
          <VisibilityIcon fontSize="small" />
        </BasicButton>
      </AccessControl>
    </Box>
  );
}
