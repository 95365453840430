import React from 'react';
import { makeStyles, CircularProgress, Box } from '@material-ui/core';

const useStylesFacebook = makeStyles(theme => ({
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.primary[400],
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export default function Spinner(props) {
  const classes = useStylesFacebook();

  return (
    <Box display="flex" position="relative">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
        disableShrink
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
