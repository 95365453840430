import React, { useEffect, useCallback, useState } from 'react';

import { useEmblaCarousel } from 'embla-carousel/react';
import FlexRow from 'components/FlexRow/FlexRow';
import { Box, makeStyles } from '@material-ui/core';
import NotificationsPreviewCarouselButton from './NotificationsPreviewCarouselButton/NotificationsPreviewCarouselButton';

const useStyles = makeStyles((theme) => ({
  arrows: {
    transform: 'translateX(-50%)',
  },
}));

export default function NotificationsPreviewCarousel({ children }) {
  const classes = useStyles();
  const [emblaCarouselRef, embla] = useEmblaCarousel({});
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap());
    };
    if (embla) {
      setScrollSnaps(embla.scrollSnapList());
      embla.on('select', onSelect);
      onSelect();
    }
  }, [embla]);

  return (
    <Box height="100%" position="relative">
      <Box height="100%" overflow="hidden" width="100%" ref={emblaCarouselRef}>
        <FlexRow>{children}</FlexRow>
      </Box>
      <FlexRow className={classes.arrows} position="absolute" left="50%" bottom="0.5rem">
        {scrollSnaps.map((_, index) => (
          <NotificationsPreviewCarouselButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </FlexRow>
    </Box>
  );
}
