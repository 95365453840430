import { Box, DialogContentText } from '@material-ui/core';
import BasicRadio from 'components/BasicInputs/BasicRadio/BasicRadio';
import BasicRadioGroup from 'components/BasicInputs/BasicRadioGroup/BasicRadioGroup';
import MoneyFormatField from 'components/BasicInputs/BasicTextField/MoneyFormatField/MoneyFormatField';
import Text from 'components/Text/Text';
import { useFormikContext } from 'formik';
import React from 'react';
import { useClientDetailsContext } from 'services/client/context';
import { REFUND_OPTIONS } from './Refund';

export default function RefundForm({ val }) {
  const { values } = useFormikContext();
  const { partialRefund } = useClientDetailsContext();
  return (
    <Box height={partialRefund ? '10rem' : 'auto'}>
      {partialRefund && (
        <BasicRadioGroup name="option">
          <BasicRadio
            value={REFUND_OPTIONS.FULL_REFUND}
            name="option"
            label={`Full refund of $${val}`}
          />
          <BasicRadio value={REFUND_OPTIONS.PARTIAL_REFUND} name="option" label="Partial refund" />
        </BasicRadioGroup>
      )}
      {values.option === REFUND_OPTIONS.PARTIAL_REFUND && (
        <MoneyFormatField
          name="amount"
          label="Money to Refund"
          placeholder="$4.15"
          min={0}
          max={val}
        />
      )}
      <Box marginTop="0.75rem" marginBottom="1rem">
        <DialogContentText>
          <Box component="span" color="text.primary">
            Are you sure you want to refund
{' '}
            <Box component="span" color="text.primary" fontWeight={600}>
              {`$${values.option === REFUND_OPTIONS.FULL_REFUND ? val : values.amount}?`}
            </Box>
          </Box>
        </DialogContentText>
      </Box>
    </Box>
  );
}
