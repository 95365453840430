import React from 'react';
import Container from 'components/Container/Container';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import SecondNavBar from 'containers/navigation/SecondNavBar';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';

const RULES_TABS = [
  { name: 'Payment Rules', value: 0, path: '/app-rules/payment' },
  { name: 'Scheduling Qualified PTP', value: 0, path: '/app-rules/scheduling-qualified-ptp' },
  { name: 'Installation Rules', value: 0, path: '/app-rules/installation-rules' },
];

export default function Rules({ routes }) {
  return (
    <AccessControl perform={PERMISSIONS.RULES.VISIT} redirectOnNoAccessTo="/borrowers">
      <SecondNavBar counts={RULES_TABS} />
      <Container>
        <Switch>
          {routes.map((route) => (
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
        </Switch>
      </Container>
    </AccessControl>
  );
}
