import React from 'react';
import Container from 'components/Container/Container';

export default function BorrowersContainer({ children }) {
  return (
    <Container padding="0" display="flex" flexDirection="column" minHeight="calc(100vh - 148px)">
      {children}
    </Container>
  );
}
