import React from 'react';

export default function CrossIcon({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '41.756'}
      height={height || '41.756'}
      viewBox="0 0 41.756 41.756"
    >
      <path
        fill="currentColor"
        d="M27.948 20.878L40.291 8.536a5 5 0 1 0-7.07-7.071L20.878 13.809 8.535 1.465a5 5 0 0 0-7.07 7.071l12.342 12.342L1.465 33.22a5 5 0 1 0 7.07 7.071l12.343-12.342 12.343 12.343c.976.977 2.256 1.464 3.535 1.464s2.56-.487 3.535-1.464a5 5 0 0 0 0-7.071L27.948 20.878z"
      />
    </svg>
  );
}
