import { Box } from '@material-ui/core';
import React from 'react';

export default function Center({ children, ...rest }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...rest}>
      {children}
    </Box>
  );
}
