import React from 'react';
import { Box, useTheme, makeStyles } from '@material-ui/core';
import Text from 'components/Text/Text';
import clsx from 'clsx';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';

const pseudoHighlight = theme => ({
  content: '""',
  height: '100%',
  width: '3px',
  background: theme.palette.primary[400],
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
});

const useStyles = makeStyles(theme => ({
  item: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: ({ isActiveTab }) =>
        isActiveTab ? theme.palette.primary[100] : theme.palette.grey[50],
    },
  },
  active: {
    '&::after': pseudoHighlight(theme),
    background: theme.palette.primary[100],
  },
}));

export default function RulesSelectorItem({ text, children, index }) {
  const theme = useTheme();
  const { setActiveTab, activeTab } = useActiveTab();
  const isActiveTab = activeTab === index;
  const classes = useStyles({ isActiveTab });

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="0 1rem"
      minHeight="3.75rem"
      borderBottom={theme.borders.containers}
      role="button"
      cursor="pointer"
      className={clsx(classes.item, isActiveTab && classes.active)}
      onClick={() => setActiveTab(index)}
    >
      <Text
        fontWeight={isActiveTab ? 600 : 500}
        fontSize="0.75rem"
        letterSpacing="-0.01em"
        color="textPrimary"
      >
        {text || children}
      </Text>
    </Box>
  );
}
