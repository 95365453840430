import React from 'react';
import { Box } from '@material-ui/core';
import BasicTabs from 'components/BasicTabs/BasicTabs';
import BasicTabPanel from 'components/BasicTabs/BasicTabPanel/BasicTabPanel';
import BasicTab from 'components/BasicTabs/BasicTab/BasicTab';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import { ScheduleListFilterProvider } from 'components/WebPop/PaymentTabs/ScheduleList/ScheduleListFilterProvider/ScheduleListFilterProvider';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import PaymentHistory from 'containers/borrowers/PaymentHistory/PaymentHistory';
import ScheduleList from 'components/WebPop/PaymentTabs/ScheduleList/ScheduleList';

export default function PaymentsInformation() {
  const { canUserAccessTo } = useCurrentUser();
  return (
    <BasicsTabsContextProvider
      initialActiveTab={canUserAccessTo(PERMISSIONS.PAYMENTS.READ_HISTORY) ? 1 : 2}
    >
      <BasicTabs>
        <Box display="flex" padding="0.5rem 2.25rem 0">
          <AccessControl perform={PERMISSIONS.PAYMENTS.READ_HISTORY}>
            <Box marginRight="4.5rem">
              <BasicTab index={1}>Payment History</BasicTab>
            </Box>
          </AccessControl>
          <Box marginRight="4.5rem">
            <BasicTab index={2}>Scheduled Payments</BasicTab>
          </Box>
        </Box>
      </BasicTabs>
      <AccessControl perform={PERMISSIONS.PAYMENTS.READ_HISTORY}>
        <BasicTabPanel index={1}>
          <Box height="33.8rem" overflow="auto">
            <PaymentHistory />
          </Box>
        </BasicTabPanel>
      </AccessControl>
      <BasicTabPanel index={2}>
        <ScheduleListFilterProvider>
          <Box height="33.8rem" overflow="auto">
            <ScheduleList />
          </Box>
        </ScheduleListFilterProvider>
      </BasicTabPanel>
    </BasicsTabsContextProvider>
  );
}
