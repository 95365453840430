import { FormControlLabel, makeStyles, Radio } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& + *': {
      marginTop: '2px',
      fontSize: '0.875rem',
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent !important',
    },
    '& input + *': {
      borderRadius: '50%',
      width: 16,
      height: 16,
    },
    '& input:disabled + *': {
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.3) !important',
      background: 'rgba(206,217,224,.5) !important',
    },
    '&:not($checked)': {
      '& input + *': {
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.12), inset 0 0 0 1px rgba(16,22,26,.5)',
        backgroundColor: '#fff',
      },
      '& input:hover ~ *, & input:focus + *': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.primary[500]}, inset 0 -1px 0 ${theme.palette.primary[500]}`,
        backgroundColor: theme.palette.primary[50],
      },
    },
  },
  checked: {
    '& input + *': {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary[500]}`,
      backgroundColor: '#fff',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: `radial-gradient(${theme.palette.primary[500]},${theme.palette.primary[500]} 28%,rgba(0,0,0,0) 32%)`,
        content: '""',
      },
    },
    '& input:focus + *': {
      backgroundColor: theme.palette.primary[50],
    },
  },
}));

export default function BasicRadio({ label, value, name }) {
  const classes = useStyles();
  const [field] = useField(name);

  return (
    <FormControlLabel
      control={
        <Radio
          value={value}
          disableRipple
          classes={classes}
          checkedIcon={<span />}
          icon={<span />}
          checked={field.value === value}
        />
      }
      label={label}
    />
  );
}
