import { makeStyles } from '@material-ui/core';
import BasicUpload from 'components/BasicInputs/BasicUpload/BasicUpload';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import { useField } from 'formik';
import React from 'react';

const useStyles = makeStyles(() => ({
  fileInputHighlight: {
    textDecoration: 'underline',
  },
}));

export default function NACHAUploadInput() {
  const classes = useStyles();
  const [field] = useField('file');

  return (
    <BasicUpload name="file" accept="text/plain">
      <FlexRow
        borderRadius="0.5rem"
        border={`1px dashed ${field.value ? '#008949' : 'rgba(124, 141, 160, 0.13)'}`}
        padding="1rem 1.5rem"
        height="3rem"
        bgcolor={field.value ? 'rgba(0, 137, 73, 0.03)' : 'rgba(124, 141, 160, 0.05)'}
        justifyContent={field.value ? 'flex-start' : 'center'}
      >
        {field.value ? (
          <Text textAlign="center" fontSize="0.75rem" lineHeight="0.875rem" letterSpacing="0.3px">
            {field.value.name}
          </Text>
        ) : (
          <>
            <FlexRow marginRight="0.625rem">
              <Icon width="1.25rem" height="1.25rem" name="upload" />
            </FlexRow>
            <Text textAlign="center" fontSize="0.75rem" lineHeight="0.875rem" letterSpacing="0.3px">
              Upload a new file or
{' '}
              <Text
                fontWeight={500}
                className={classes.fileInputHighlight}
                component="span"
                color="primary.500"
              >
                browse
              </Text>
{' '}
              in your computer
            </Text>
          </>
        )}
      </FlexRow>
    </BasicUpload>
  );
}
