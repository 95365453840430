import React from 'react';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';

export default function HomeCardHeader({ title, description }) {
  return (
    <Box marginBottom="2rem">
      <Text
        lineHeight="1"
        fontSize="1.5rem"
        letterSpacing="-0.01em"
        fontWeight={500}
        marginBottom="0.25rem"
      >
        {title}
      </Text>
      <Text lineHeight="1.25rem" fontSize="0.875rem" letterSpacing="-0.01em">
        {description}
      </Text>
    </Box>
  );
}
