import { useQuery, useMutation, useQueryCache } from 'react-query';
import { getRules, updateRule } from './api';

export function useRules(id) {
  return useQuery(['rules-details', { id }], getRules, { staleTime: 0 });
}

// eslint-disable-next-line import/prefer-default-export
export const useUpdateRule = (options = {}) => {
  const queryCache = useQueryCache();
  return useMutation(updateRule, {
    onSuccess: updatingRule => {
      queryCache.invalidateQueries('rules-details');
    },
    onError: (err, variables, recover) => (typeof recover === 'function' ? recover() : null),
    ...options,
  });
};
