import React from 'react';
import { PAYMENT_RULES } from 'shared/constants/rules';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import RulesCommonLayout from '../RulesCommonLayout/RulesCommonLayout';

const PAYMENT_TABS = [
  {
    index: PAYMENT_RULES.ALL_PAYMENTS,
    title: 'Do not allow payments entirely',
  },
  {
    index: PAYMENT_RULES.CARD_PAYMENTS,
    title: ' Do not allow card payments entirely',
  },
  {
    index: PAYMENT_RULES.ACH_PAYMENTS,
    title: 'Do not allow ACH payments entirely',
  },
];

const PLATFORMS = [
  {
    value: 1,
    icon: 'web',
    name: 'Web',
  },
  {
    value: 2,
    icon: 'mobile',
    name: 'Mobile',
  },
  {
    value: 3,
    icon: 'sms',
    name: 'SMS',
  },
  {
    value: 4,
    icon: 'phone',
    name: 'IVR',
  },
  {
    value: 71,
    icon: 'clock',
    name: 'One Time',
  },
  {
    value: 72,
    icon: 'clockwise',
    name: 'Recurring',
  },
];

export default function PaymentRules() {
  return (
    <BasicsTabsContextProvider initialActiveTab={PAYMENT_TABS[0].index}>
      <RulesCommonLayout
        platforms={PLATFORMS}
        tabs={PAYMENT_TABS}
        icon="payment"
        title="Payment Rules"
      />
    </BasicsTabsContextProvider>
  );
}
