import { Box } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import Text from 'components/Text/Text';
import React from 'react';

export default function NotificationsFormInput({ label, name }) {
  return (
    <Box>
      <Text
        fontSize="0.75rem"
        letterSpacing="0.18px"
        lineHeight="0.875rem"
        color="common.label"
        marginBottom="0.625rem"
      >
        {label}
      </Text>
      <BasicTextField type="number" name={name} />
    </Box>
  );
}
