import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { useDetails, useUpdatePrivacyPolicy } from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import PrivacyPolicyForm from './PrivacyPolicyForm/PrivacyPolicyForm';
import useBTC from '../useBTC';

export default function PrivacyPolicy() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdatePrivacyPolicy({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'PrivacyPolicy updated successfully!' });
    },
  });
  const initialValues = useMemo(
    () => ({
      privacyPolicy: data.privacyPolicy,
    }),
    [data.privacyPolicy],
  );

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      privacy_policy: values.privacyPolicy,
    });
    await mutate(formData);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <PrivacyPolicyForm />
    </Form>
  );
}
