import React from 'react';
import { useLoanDetails, useBorrowerDetails } from 'services/loans/hooks';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { useMakePayment } from 'services/payments/hooks';
import { buildFormData, dateToISO, to, pick } from 'shared/utils';
import { getStoredLoggedInUser } from 'shared/utils/authToken';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import useDisclosure from 'shared/hooks/useDisclosure';
import { PAYMENT_CHANNELS } from 'shared/constants/payments';
import * as yup from 'yup';
import { useQueryCache } from 'react-query';
import WebPopForm from '../../WebPopForm/WebPopForm';
import PaymentResponse from '../PaymentResponse/PaymentResponse';
import MakePaymentDisclaimer from './MakePaymentDisclaimer/MakePaymentDisclaimer';
import MakePaymentForm from './MakePaymentForm/MakePaymentForm';
import { useCurrentUser } from 'contexts/CurrentUserProvider';

const initialValues = {
  amount: '',
  token: '',
  paymentType: 1,
  channel: PAYMENT_CHANNELS.CAP,
  type: 1,
  postPayment: 1,
  postComment: 1,
  transaction: '',
};

const MAKE_PAYMENT_KEYS = [
  'amount',
  'token',
  'type',
  'channel',
  'postPayment',
  'postComment',
  'transaction',
];

const validationSchema = yup.object({
  token: yup
    .string()
    .required('Payment method is required')
    .test('isPaymentMethodSelect', 'Payment method is required', val => Number(val) !== 0),
  amount: yup
    .string().required('Payment amount is required')
    .test('len', 'Payment amount should be at least $0.10', val => Number(val) >= 0.10),
  convFeeWaivedReasonId: yup.string().when('waiveConvenienceFee', {
    is: true,
    then: yup.string().required('A reason is required'),
    otherwise: yup.string(),
  }),
});

export default function MakePayment() {
  const { data: borrowerDetails } = useBorrowerDetails();
  const { isLoading } = useLoanDetails(borrowerDetails.loan.loanNo);
  const { setErrorAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const [
    mutate,
    { isLoading: isMakePaymentLoading, data: makePaymentResponse, isSuccess, reset },
  ] = useMakePayment({
    onSuccess: () => {
      queryCache.invalidateQueries('borrower-details', {
        refetchInactive: true,
        refetchActive: true,
      });
      queryCache.invalidateQueries('payment-history', {
        refetchInactive: true,
        refetchActive: true,
      });
    },
    onError: () => {
      setErrorAlertMessage('There was a problem completing the payment');
    },
  });
  const {
    isOpen: isDisclaimerOpen,
    onOpen: onDisclaimerOpen,
    onClose: onDisclaimerClose,
  } = useDisclosure();

  const { currentUser } = useCurrentUser();

  const handleSubmitMakePayment = async (values, { resetForm }) => {
    const selectedValues = pick(values, MAKE_PAYMENT_KEYS);
    const formData = new FormData();
    buildFormData(formData, {
      ...selectedValues,
      paymentType: values?.paymentMethodData?.paymentType,
      account: borrowerDetails.loan.loanNo,
      userno: currentUser.id,
      amount: values.refund ? `-${values.amount}` : values.amount,
      ...(values.principalOnly && { principalOnly: values.principalOnly }),
      ...(values.waiveConvenienceFee && { convFeeWaivedReasonId: values.convFeeWaivedReasonId }),
    });
    await mutate(formData);
  };

  const handleGoBack = () => {
    reset();
    onDisclaimerClose();
  };

  return (
    <WebPopForm
      initialValues={initialValues}
      onSubmit={handleSubmitMakePayment}
      validationSchema={validationSchema}
    >
      {isLoading ? (
        <SpinnerWrapped />
      ) : (
        <>
          {isDisclaimerOpen && !makePaymentResponse && (
            <MakePaymentDisclaimer
              onAcceptClick={handleSubmitMakePayment}
              onRejectClick={onDisclaimerClose}
              isLoading={isMakePaymentLoading}
            />
          )}
          {makePaymentResponse && (
            <PaymentResponse
              onBackClick={handleGoBack}
              isSuccess={isSuccess}
              responseInfo={makePaymentResponse}
            />
          )}
          {!makePaymentResponse && !isDisclaimerOpen && (
            <MakePaymentForm onDisclaimerOpen={onDisclaimerOpen} />
          )}
        </>
      )}
    </WebPopForm>
  );
}
