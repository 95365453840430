import { ENV } from '../constants';

export function handleLogin(installAccountId, loanNumber) {
  const bpLogin =
    `installAccountId=${installAccountId}&type=3` +
    `&deviceId=123` +
    `&deviceOs=WEB` +
    `&deviceType=3` +
    `&deviceToken=asdasd` +
    `&loanNumber=${loanNumber}&accessToken=${sessionStorage.getItem('accessToken')}`;
  window.open(`${`${ENV.BP}/redirect?${bpLogin}`}`);
}
