import React from 'react';
import { Box } from '@material-ui/core';

export default function BasicModalContent({ children, ...rest }) {
  return (
    <Box
      height="100%"
      width="100%"
      padding="1rem 2rem 1.5rem"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      {children}
    </Box>
  );
}
