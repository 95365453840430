import React, { useState, useContext, useMemo, createContext, useCallback } from 'react';

const Pagination = createContext();

const PaginationProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(48);
  const [data, setData] = useState({
    payload: {},
    page: 1,
  });

  const handleChangePage = useCallback(
    (event, newPage) => {
      setData((oldData) => {
        return { ...oldData, page: newPage };
      });
    },
    [setPage],
  );

  const handleItemsPerPageChange = useCallback(
    (newItemsPerPage) => {
      setItemsPerPage(newItemsPerPage);
    },
    [setItemsPerPage],
  );

  const value = useMemo(
    () => ({
      page,
      setPage,
      handleChangePage,
      itemsPerPage,
      handleItemsPerPageChange,
      setData,
      data,
    }),
    [page, setPage, handleChangePage, handleItemsPerPageChange, itemsPerPage, data, setData],
  );

  return <Pagination.Provider value={value}>{children}</Pagination.Provider>;
};

export default PaginationProvider;

export function usePagination() {
  const context = useContext(Pagination);
  if (!context) {
    throw new Error(`usePagination must be used within a PaginationProvider`);
  }
  return context;
}
