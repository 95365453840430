import { webAPI } from 'shared/utils/http';

export const getGroups = async (key, { page, searchString }) => {
  const response = await webAPI.get(
    `/rule/list-group?page=${page}&limit=12&searchString=${searchString}`,
  );
  return response.data;
};
export const getGroupDetail = async (key, { groupId }) => {
  const response = await webAPI.get(`rule/group-detail?clientGroupRuleId=${groupId}`);
  return response.data;
};
export const getGroupDetailLoanList = async (key, { groupId }) => {
  const response = await webAPI.get(
    `rule/group-loan-list?clientGroupRuleId=${groupId}&limit=10000&page=1`,
  );
  return response.data;
};
export const getLoanStatusCode = async () => {
  const response = await webAPI.get(`client/loan-status-codes/list`);
  return response.data;
};

export const createGroup = async payload => {
  const response = await webAPI.post('rule/create-group', payload);
  return response.data;
};

export const deleteGroup = async payload => {
  const response = await webAPI.post('rule/delete-group', payload);
  return response.data;
};

export const updateGroup = async payload => {
  const response = await webAPI.post('rule/update-group', payload);
  return response.data;
};

export const updateGroupStaticLoan = async payload => {
  const response = await webAPI.post('rule/update-group-static-loan', payload);
  return response.data;
};
