import React from 'react';

export default function SearchIcon({ width, height }) {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      fill="currentColor"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8 10.808L9.156 8.163c.636-.847.98-1.88.979-2.94A4.917 4.917 0 0 0 5.224.314a4.917 4.917 0 0 0-4.912 4.91 4.917 4.917 0 0 0 4.912 4.912 4.885 4.885 0 0 0 2.94-.98l2.644 2.645a.703.703 0 0 0 .992-.992zM1.716 5.224a3.508 3.508 0 1 1 7.016 0 3.508 3.508 0 0 1-7.016 0z"
        fill="currentColor"
        fillOpacity=".88"
      />
    </svg>
  );
}
