import React from 'react';
import { Box } from '@material-ui/core';
import { useGroupDetailLoanList } from 'services/groups/hooks';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { useSearchBar } from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import EmptyState from 'components/EmptyState/EmptyState';
import Text from 'components/Text/Text';
import SearchBar from 'components/SearchBar/SearchBar';
import GroupAssociatedAccountsItem from '../GroupAssociatedAccountsItem/GroupAssociatedAccountsItem';
import { useGroupsActiveTab } from '../../GroupsTabActiveProvider/GroupsTabActiveProvider';
import useMixinStyles from 'styles/mixins';

export default function GroupAssociatedAccountsList() {
  const mixinClasses = useMixinStyles();
  const { data: groupDetailLoanList, isLoading } = useGroupDetailLoanList();
  const { searchString } = useSearchBar();
  const { groupsTabActive } = useGroupsActiveTab();

  const selectedListOfLoans = groupDetailLoanList?.result?.data?.isStatic
    ? 'clientStaticGroupLoans'
    : 'clientDynamicGroupLoans';
  const filteredList = groupDetailLoanList?.result?.data[selectedListOfLoans]?.filter(item =>
    item.loanNumber.includes(searchString),
  );

  return (
    <>
      {isLoading || groupDetailLoanList?.result?.data?.id !== groupsTabActive ? (
        <SpinnerWrapped />
      ) : (
        <>
          <Box display="flex" alignItems="center" marginBottom="1.875rem">
            <Text
              fontSize="0.8125rem"
              color="rgba(124, 141, 160, 0.88)"
              letterSpacing="-0.13px"
              marginRight="1rem"
            >
              {`${filteredList?.length} associated accounts`}
            </Text>
            <SearchBar placeholder="Search specific accounts" size="sm" />
          </Box>
          {filteredList?.length ? (
            <Box display="flex" alignItems="center" flexWrap="wrap" overflow="scroll" className={mixinClasses.scrollBar}>
              {filteredList.map(item => (
                <GroupAssociatedAccountsItem
                  key={item.id}
                  id={item.id}
                  label={item.loanNumber}
                  isEnabled={item.isEnabled}
                  isStatic={groupDetailLoanList?.result?.data?.isStatic}
                />
              ))}
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
              <Box marginTop="-3rem">
                <EmptyState />
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}
