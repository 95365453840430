import React from 'react';
import BasicModalCloseButton from 'components/BasicModal/BasicModalCloseButton/BasicModalCloseButton';
import { Box } from '@material-ui/core';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import BasicModalContent from 'components/BasicModal/BasicModalContent/BasicModalContent';
import Text from 'components/Text/Text';
import Form from 'components/Form/Form';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import Icon from 'components/Icon/Icon';
import BasicDialog from 'components/BasicDialog/BasicDialog';
import * as yup from 'yup';
import * as JSEncrypt from 'jsencrypt';
import { useResetInstallPassword, useBorrowerDetails } from 'services/loans/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import PasswordField from 'components/BasicInputs/BasicTextField/PasswordField/PasswordField';
import { ENV } from '../../../../constants';
import ResetPasswordActions from './ResetPasswordActions/ResetPasswordActions';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password Must contain at least one number. ')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
  confirm_password: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const initialValues = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

export default function ResetPassword({ isOpen, onClose, name }) {
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { data: borrowerDetails } = useBorrowerDetails();
  const [mutate] = useResetInstallPassword({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        onClose();
        setAlertMessage('Password reset successfully!');
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleSubmit = (values) => {
    const key = new JSEncrypt.JSEncrypt();
    key.setPublicKey(ENV.TOKEN);
    const pass = key.encrypt(values.password);
    const confPass = key.encrypt(values.confirm_password);
    mutate({
      confirm_password: confPass,
      password: pass,
      id: borrowerDetails.linkAttempt?.installAccountId,
    });
  };

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose}>
      <Box display="flex" flexDirection="column" width="430px" height="580px">
        <BasicModalCloseButton onClick={onClose} />
        <Box marginBottom="1.25rem">
          <BasicModalTitle>Reset Password</BasicModalTitle>
        </Box>
        <BasicModalContent flex={1} width="auto">
          <Box marginBottom="2.25rem">
            <Text
              fontWeight={500}
              color="text.secondary"
              fontSize="0.875rem"
              letterSpacing="0.025em"
              marginBottom="0.25rem"
            >
              Update the password for
            </Text>
            <Text fontWeight={500} color="text.primary" fontSize="1rem" letterSpacing="0.025em">
              {name}
            </Text>
          </Box>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Box display="flex" flexDirection="column">
              <Box marginBottom="3.5rem">
                {/* <Box marginBottom="1.75rem">
                  <BasicTextField
                    startIcon={<Icon name="lock" />}
                    name="password"
                    label="Current Password"
                    placeholder="••••••"
                    type="password"
                  />
                </Box> */}
                <Box marginBottom="1.75rem">
                  <PasswordField
                    name="password"
                    label="New Password"
                    placeholder="••••••"
                    type="password"
                  />
                </Box>
                <Box>
                  <PasswordField
                    name="confirm_password"
                    label="Confirm New Password"
                    placeholder="••••••"
                    type="password"
                  />
                </Box>
              </Box>
              <ResetPasswordActions onClose={onClose} />
            </Box>
          </Form>
        </BasicModalContent>
      </Box>
    </BasicDialog>
  );
}
