import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import BasicSelectBase from 'components/BasicInputs/BasicSelectBase/BasicSelectBase';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';

const sortByOptions = [
  {
    id: '0',
    value: 'Latest',
  },
  {
    id: '1',
    value: 'Days past due',
  },
];

const BorrowersSortBy = () => {
  const { sortBy, setQuery } = useBorrowersFilters();

  const handleSortByChange = (newSelectedItem) => {
    setQuery({ sort_by: newSelectedItem });
  };

  return (
    <BasicSelectBase
      onChange={handleSortByChange}
      defaultValue={sortByOptions[0].id}
      label="Sort By"
      value={sortBy}
    >
      {sortByOptions.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.value}
        </MenuItem>
      ))}
    </BasicSelectBase>
  );
};

export default BorrowersSortBy;
