import React from 'react';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';

export default function PageHeader({ title, description, ...rest }) {
  return (
    <Box marginBottom="2rem" {...rest}>
      <Text
        fontSize="1.125rem"
        fontWeight={600}
        lineHeight="0.875rem"
        marginBottom="0.375rem"
        letterSpacing="-0.18px"
      >
        {title}
      </Text>
      {description && (
        <Text
          fontSize="0.875rem"
          lineHeight="0.875rem"
          letterSpacing="0.35px"
          color="rgba(34, 47, 62, 0.5)"
        >
          {description}
        </Text>
      )}
    </Box>
  );
}
