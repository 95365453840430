import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { useDetails, useUpdateColorCode, useUpdateWebsite } from 'services/client/hooks';
import SettingsSection from '../../Shared/SettingsSection/SettingsSection';
import AccentColorForm from './AccentColorForm/AccentColorForm';
import useBTC from '../useBTC';

export default function AccentColor() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateColorCode({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Accent color updated successfully!' });
    },
  });

  const initialValues = useMemo(
    () => ({
      accentColor: data.colorCode,
    }),
    [data.colorCode],
  );

  const handleOnSubmit = async (values) => {
    await mutate({
      colorCode: values.accentColor,
    });
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSection title="Accent Color" description="Change color theme for Portal tools">
        <AccentColorForm />
      </SettingsSection>
    </Form>
  );
}
