import React from 'react';
import { Box, Chip, makeStyles } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  chip: {
    cursor: 'pointer',
    fontWeight: 600,
    border: `1px solid ${theme.palette.primary[500]}`,
    '&:not(:last-of-type)': {
      marginRight: '0.75rem',
    },
  },
  deleteIcon: {
    margin: '0 0.5rem 0 0',
    width: 'auto',
    height: 'auto',
    color: theme.palette.grey[400],
  },
}));

export default function BasicChip({ label, onClick, onDelete, disabled, className, ...rest }) {
  const classes = useStyles();
  return (
    <Chip
      disabled={disabled}
      className={clsx(classes.chip, className)}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      classes={{ deleteIcon: classes.deleteIcon }}
      deleteIcon={
        <Box display="flex">
          <Icon name="cross" height="12px" width="12px" />
        </Box>
      }
      {...rest}
    />
  );
}
