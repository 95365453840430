import React from 'react';

export default function ChevronDownIcon({ width, height }) {
  return (
    <svg
      width={width || 6}
      height={height || 6}
      fill="currentColor"
      viewBox="0 0 6 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.836 1.544l-2.332 3.5a.583.583 0 0 1-.965.064L.164 1.544A.583.583 0 0 1 .667.667h4.666c.45 0 .73.488.503.877z"
        fill="currentColor"
      />
    </svg>
  );
}
