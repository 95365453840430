import PMS from 'components/MicroFrontEnd/PMS/PMS';
import React from 'react';
import { useBorrowerDetails } from 'services/loans/hooks';

export default function Chat() {
  const { data: borrowerDetails } = useBorrowerDetails();
  return (
    <>
      <PMS
        config={{
          hostId: borrowerDetails.linkAttempt.installAccount.pmsId,
          room: borrowerDetails.linkAttempt.installAccount.pmsConversationId,
          chatName: `${borrowerDetails.linkAttempt.installAccount.instlFirstName} ${borrowerDetails.linkAttempt.installAccount.instlLastName}`,
        }}
      />
    </>
  );
}
