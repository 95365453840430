import React from 'react';
import { useSignedUp } from 'services/borrowers/hooks';
import BorrowersContainer from '../BorrowersContainer/BorrowersContainer';
import BorrowersFilters from '../BorrowersFilters/BorrowersFilters';
import BorrowersList from '../BorrowersList/BorrowersList';
import SignedUpCard from './SignedUpCard/SignedUpCard';
import SignedUpTable from './SignedUpTable/SignedUpTable';
import BorrowersSortBy from '../BorrowersFilters/BorrowersSortBy/BorrowersSortBy';
import BorrowersListItemToggle from '../BorrowersFilters/BorrowersListItemToggle/BorrowersListItemToggle';
import BorrowersSearchBar from '../BorrowersFilters/BorrowersSearchBar/BorrowersSearchBar';
import BorrowersPagination from '../BorrowersFilters/BorrowersPagination/BorrowersPagination';
import BorrowersFiltersProvider from '../BorrowersFiltersProvider/BorrowersFiltersProvider';
import BorrowersExportList from '../BorrowersFilters/BorrowersExportList/BorrowersExportList';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import AddBorrower from '../AddBorrower/AddBorrower';

function SignedUp() {
  const { data, isFetching } = useSignedUp();
  return (
    <BorrowersContainer>
      <BorrowersFilters
        SearchBar={<BorrowersSearchBar isLoading={isFetching} />}
        SortBy={<BorrowersSortBy />}
        ListTypeToggle={<BorrowersListItemToggle />}
        ExportBorrowersList={(
          <AccessControl perform={PERMISSIONS.BORROWERS.EXPORT_LIST}>
            <BorrowersExportList />
          </AccessControl>
        )}
      />
      <BorrowersList data={data} CardComponent={SignedUpCard} TableComponent={SignedUpTable} EmptyStateComponent={AddBorrower} />
      <BorrowersPagination count={data?.result?.totalPage} />
    </BorrowersContainer>
  );
}

export default function StatelessSignedUp() {
  return (
    <BorrowersFiltersProvider>
      <SignedUp />
    </BorrowersFiltersProvider>
  );
}
