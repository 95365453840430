import React, { useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import AuthenticatedLayout from 'components/AuthenticatedLayout/AuthenticatedLayout';
import Borrowers from 'pages/Borrowers/Borrowers';

import Rules from 'pages/Rules/Rules';
import ScheduledQPTPRules from 'pages/Rules/ScheduledQPTPRules/ScheduledQPTPRules';
import InstallationRules from 'pages/Rules/InstallationRules/InstallationRules';
import PaymentRules from 'pages/Rules/payment';
import BorrowerMessagingRules from 'pages/Rules/MessagingRules/MessagingRules';
import NavBar from 'components/navigation/NavBar';
import SignedUp from 'pages/Borrowers/SignedUp/SignedUp';
import LoanDetailsPage from 'pages/Borrowers/LoanDetails/LoanDetails';
import PaymentMade from 'pages/Borrowers/PaymentMade/PaymentMade';
import LinkedFailed from 'pages/Borrowers/LinkedFailed/LinkedFailed';
import OnApp from 'pages/Borrowers/OnApp/OnApp';
import Groups from 'pages/Groups/Groups';
import GroupsTab from 'pages/Groups/GroupsTab/GroupsTab';
import PayixLogo from 'assets/img/payix-logo.svg';
import { Box, makeStyles } from '@material-ui/core';
import Profile from 'pages/Profile/Profile';
import ProfileSettings from 'pages/Profile/ProfileSettings/ProfileSettings';
import Settings from 'pages/Settings/Settings';
import BTC from 'pages/Settings/BTC/BTC';
import Notifications from 'pages/Settings/Notifications/Notifications';
import NACHAUpload from 'pages/Settings/NACHAUpload/NACHAUpload';
import Transactions from 'pages/Borrowers/Transactions/Transactions';
import Messages from 'pages/Messages/Messages';
import Text from 'components/Text/Text';
import CATC from 'pages/Settings/CATC/CATC';

const ROUTES = [
  {
    path: '/',
    key: 'AUTHENTICATED_ROOT',
    component: AuthenticatedLayout,
    routes: [
      {
        path: '/borrowers',
        key: 'BORROWERS',
        component: Borrowers,
        routes: [
          {
            path: '/borrowers/signed-up',
            key: 'BORROWERS_SIGNED_UP',
            exact: true,
            component: SignedUp,
          },
          {
            path: '/borrowers/on-app',
            key: 'BORROWERS_ON_APP',
            exact: true,
            component: OnApp,
          },
          {
            path: '/borrowers/payment-made',
            key: 'BORROWERS_PAYMENT_MADE',
            exact: true,
            component: PaymentMade,
          },
          {
            path: '/borrowers/failed-link',
            key: 'BORROWERS_FAILED_LINK',
            exact: true,
            component: LinkedFailed,
          },
          {
            path: '/borrowers/transactions',
            key: 'TRANSACTIONS',
            exact: true,
            component: Transactions,
          },
          {
            path: '/borrowers/signed-up/borrowers-details/loan-id/:id',
            key: 'BORROWERS_SIGNED_UP_DETAILS',
            exact: true,
            component: LoanDetailsPage,
          },
          {
            path: '/borrowers/on-app/borrowers-details/loan-id/:id',
            key: 'BORROWERS_ON_APP_DETAILS',
            exact: true,
            component: LoanDetailsPage,
          },
          {
            path: '/borrowers/payment-made/borrowers-details/loan-id/:id',
            key: 'BORROWERS_PAYMENT_MADE_DETAILS',
            exact: true,
            component: LoanDetailsPage,
          },
          {
            path: '*',
            key: 'REDIRECT',
            exact: true,
            component: () => <Redirect to="/borrowers/signed-up" />,
          },
        ],
      },
      {
        path: '/app-rules',
        key: 'RULES',
        component: Rules,
        routes: [
          {
            path: '/app-rules/payment',
            key: 'RULES_PAYMENT',
            exact: true,
            component: PaymentRules,
          },
          {
            path: '/app-rules/scheduling-qualified-ptp',
            key: 'RULES_SCHEDULING_QUALIFED_PTP',
            exact: true,
            component: ScheduledQPTPRules,
          },
          {
            path: '/app-rules/installation-rules',
            key: 'RULES_INSTALLATION_RULES',
            exact: true,
            component: InstallationRules,
          },
          {
            path: '*',
            key: 'REDIRECT',
            exact: true,
            component: () => <Redirect to="/app-rules/payment" />,
          },
        ],
      },
      {
        path: '/groups',
        key: 'GROUPS_ROOT',
        component: Groups,
        routes: [
          {
            path: '/groups',
            key: 'GROUPS',
            exact: true,
            component: GroupsTab,
          },
        ],
      },
      {
        path: '/messages',
        key: 'MESSAGES',
        exact: true,
        component: Messages,
      },
      {
        path: '/profile',
        key: 'PROFILE_ROOT',
        component: Profile,
        routes: [
          {
            path: '/profile',
            key: 'PROFILE',
            exact: true,
            component: ProfileSettings,
          },
        ],
      },
      {
        path: '/settings',
        key: 'SETTINGS_ROOT',
        component: Settings,
        routes: [
          {
            path: '/settings',
            key: 'SETTINGS',
            exact: true,
            component: BTC,
          },
          {
            path: '/settings/cap',
            key: 'SETTINGS_CAP',
            exact: true,
            component: CATC,
          },
          {
            path: '/settings/notifications',
            key: 'NOTIFICATIONS',
            exact: true,
            component: Notifications,
          },
          {
            path: '/settings/nacha',
            key: 'NACHA',
            exact: true,
            component: NACHAUpload,
          },
        ],
      },
      {
        path: '*',
        key: 'REDIRECT',
        exact: true,
        component: () => <Redirect to="/borrowers" />,
      },
    ],
  },
];

const useStyles = makeStyles(() => ({
  footerImg: {
    opacity: '0.5',
  },
}));

export default function AuthenticatedRoutes() {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <NavBar />
      <Box marginBottom="2rem" display="flex" flexDirection="column" flex={1}>
        <Switch>
          {ROUTES.map((route) => (
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
        </Switch>
      </Box>
      <Box className={classes.footerImg} margin="auto auto 1rem">
        <Text fontSize="0.625rem" color="#3E3C38" marginBottom="0rem" letterSpacing="0.01em">
          powered by
        </Text>
        <Box component="img" src={PayixLogo} />
      </Box>
    </>
  );
}
