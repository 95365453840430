import React, { useCallback, useEffect, useMemo } from 'react';

import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useAddCard, useAddACH } from 'services/payments/hooks';
import { buildFormData, pick } from 'shared/utils';
import { useBorrowerDetails } from 'services/loans/hooks';
import { setYear, setMonth, format, parse, parseISO, isValid } from 'date-fns';
import { PAYMENT_TYPES, PAYMENT_METHODS, PAYMENT_CHANNELS } from 'shared/constants/payments';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import * as yup from 'yup';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import BasicModalGoBack from 'components/BasicModal/BasicModalGoBack/BasicModalGoBack';
import { Box } from '@material-ui/core';
import { useClientDetailsContext } from 'services/client/context';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import { isBefore } from 'date-fns/esm';
import WebPopActions from '../WebPopActions/WebPopActions';
import AddPaymentMethodForm from './AddPaymentMethodForm/AddPaymentMethodForm';
import WebPopForm from '../WebPopForm/WebPopForm';
import WebPopContent from '../WebPopContent/WebPopContent';
import { useWebPopSwitch, WEBPOP_COMPONENTS } from '../WebPopSwitchProvider/WebPopSwitchProvider';

const requiredIfCard = (requiredText, extraCondition = true) => ({
  is: (paymentMethodType) => paymentMethodType === PAYMENT_TYPES.CREDIT_CARD && extraCondition,
  then: yup.string().required(requiredText),
  otherwise: yup.string(),
});

const requiredIfACH = (requiredText) => ({
  is: (paymentMethodType) => paymentMethodType === PAYMENT_TYPES.ACH,
  then: yup.string().required(requiredText),
  otherwise: yup.string(),
});

const getValidationSchema = (isCVVHidden) =>
  yup.object({
    name: yup.string().required('Name is required'),
    cardNumber: yup.string().when('paymentMethodType', requiredIfCard('Card number is required')),
    expiryDate: yup.string().when('paymentMethodType', {
      is: (paymentMethodType) => paymentMethodType === PAYMENT_TYPES.CREDIT_CARD,
      then: yup
        .string()
        .required('Expiry date is required')
        .test('isExpiryDateValid', 'Expiry date is invalid', (val) => {
          if (!val || !isValid(parse(val, 'MM/yy', new Date()))) {
            return false;
          }
          return !isBefore(
            parse(val, 'MM/yy', new Date()),
            parse(format(new Date(), 'MM/yy'), 'MM/yy', new Date()),
          );
        }),
      otherwise: yup.string(),
    }),
    cvvNumber: yup
      .string()
      .when('paymentMethodType', requiredIfCard('CVC is required', !isCVVHidden)),
    billingZip: yup.string().when('paymentMethodType', requiredIfCard('Billing ZIP is required')),
    accountType: yup.string().when('paymentMethodType', {
      is: (paymentMethodType) => paymentMethodType === PAYMENT_TYPES.ACH,
      then: yup
        .string()
        .required('Account type is required')
        .test('isAccountType', 'Account type is required', (val) => Number(val) !== 0),
      otherwise: yup.string(),
    }),
    routingNumber: yup
      .string()
      .when('paymentMethodType', requiredIfACH('Routing number is required')),
    accountNumber: yup
      .string()
      .when('paymentMethodType', requiredIfACH('Account number is required')),
    bankName: yup.string().when('paymentMethodType', requiredIfACH('Bank name is required')),
  });

export default function AddPaymentMethod() {
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const { goBack } = useWebPopSwitch();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { hideCvvInWebpop, cardEnable, achEnable, enablePad } = useClientDetailsContext();

  const getInitialActivePaymentMethodType = useCallback(() => {
    if (cardEnable) {
      return PAYMENT_TYPES.CREDIT_CARD;
    }
    if (achEnable) {
      return PAYMENT_TYPES.ACH;
    }
    return PAYMENT_TYPES.PAD;
  }, [cardEnable, achEnable]);

  const initialValues = useMemo(
    () => ({
      name: '',
      cardNumber: '',
      expiryDate: '',
      cvvNumber: '',
      paymentMethodType: getInitialActivePaymentMethodType(),
      billingZip: '',
      channel: PAYMENT_CHANNELS.LMS,
      doNotSavePaymentMethods: false,
      accountType: 0,
      routingNumber: '',
      accountNumber: '',
      bankName: '',
    }),
    [cardEnable, achEnable],
  );

  const handleGoBack = () => {
    goBack();
  };

  const onAddPaymentSuccess = (response) => {
    if (response.status === API_RESPONSE_STATUS.SUCCESS) {
      setAlertMessage('Payment method added successfully!');
      goBack();
      return;
    }
    setErrorAlertMessage(response.message);
  };
  const [mutate] = useAddCard(borrowerDetails.loan.loanNo, {
    onSuccess: onAddPaymentSuccess,
    onError: () => {
      setErrorAlertMessage('There was a problem adding the payment method');
    },
  });
  const [mutateACH] = useAddACH(borrowerDetails.loan.loanNo, {
    onSuccess: onAddPaymentSuccess,
    onError: () => {
      setErrorAlertMessage('There was a problem adding the payment method');
    },
  });

  const handleAddPaymentMethodSubmit = async (values) => {
    const selectedPaymentMethod = values.paymentMethodType;
    const apiValues = pick(values, PAYMENT_METHODS[selectedPaymentMethod]);
    const formData = new FormData();

    const formDataObject = {
      ...apiValues,
      account: borrowerDetails.loan.loanNo,
    };

    buildFormData(formData, formDataObject);

    if (selectedPaymentMethod === PAYMENT_TYPES.CREDIT_CARD) {
      await mutate(formData);
      return;
    }
    await mutateACH(formData);
  };

  return (
    <WebPopForm
      initialValues={initialValues}
      validationSchema={getValidationSchema(hideCvvInWebpop)}
      onSubmit={handleAddPaymentMethodSubmit}
    >
      <Box marginBottom="1rem">
        <BasicModalTitle>
          <BasicModalGoBack onClick={handleGoBack} />
          Add Payment Method
        </BasicModalTitle>
      </Box>
      <WebPopContent>
        <AddPaymentMethodForm />
      </WebPopContent>
    </WebPopForm>
  );
}
