import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

const ScheduleListFilterContext = React.createContext();

const STATUS_FIlTERS_ID = {
  ALL: '',
  SCHEDULED: 0,
  SUCCESS: 1,
  FAILED: 2,
  DELETED: 3,
};

export function ScheduleListFilterProvider({ children }) {
  const { id } = useParams();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(STATUS_FIlTERS_ID.SCHEDULED);

  const value = React.useMemo(
    () => ({ offset: 0, limit: 2000, id, selectedStatusFilter, setSelectedStatusFilter }),
    [selectedStatusFilter, id],
  );
  return (
    <ScheduleListFilterContext.Provider value={value}>
      {children}
    </ScheduleListFilterContext.Provider>
  );
}

export function useScheduleListFilterContext() {
  const context = useContext(ScheduleListFilterContext);
  if (!context) {
    throw new Error(
      `useScheduleListFilterContext must be used within a ScheduleListFilterProvider`,
    );
  }
  return context;
}
