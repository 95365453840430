import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import HomeContainer from './HomeContainer/HomeContainer';

export default function Home({ routes }) {
  return (
    <HomeContainer>
      <Switch>
        {routes.map(route => (
          <RouteWithSubRoutes key={route.key} {...route} />
        ))}
      </Switch>
    </HomeContainer>
  );
}
