import React from 'react';
import BasicTable from 'components/BasicTable/BasicTable';
import TableActions from 'pages/Borrowers/TableActions/TableActions';
import { dateWithSlashes } from 'shared/utils';

const columns = [
  { id: 'loanNumber', label: 'Account Number', minWidth: 100, align: 'left' },
  { id: 'paymentMade', label: 'Payment Made On', minWidth: 100, align: 'left' },
  {
    id: 'user',
    label: 'User',
    minWidth: 100,
    format: (value) => value.toLocaleString('en-US'),
    align: 'left',
  },
  {
    id: 'collateral',
    label: 'Collateral',
    minWidth: 100,
    format: (value) => value.toLocaleString('en-US'),
    align: 'left',
  },
  {
    id: 'amountPaid',
    label: 'Amount Paid',
    minWidth: 100,
    format: (value) => value.toFixed(2),
    align: 'left',
  },
  { id: 'actions', label: 'Actions', minWidth: 100, align: 'left' },
];

function createData(loanNumber, paymentMade, user, collateral, amountPaid, actions) {
  return { loanNumber, paymentMade, user, collateral, amountPaid, actions };
}

const getPaymentAmount = (data) => {
  const totalPaymentAmount =
    data.pmtAmt +
    data.cardTransactionAmount +
    data.achTransactionAmount +
    data.platformTransactionFees;
  return totalPaymentAmount;
};

export default function PaymentMadeTable({ data }) {
  const rows = data.map((data) => {
    const array = createData(
      data.loan.loanNo ? data.loan.loanNo : 'N/A',
      dateWithSlashes(data.paymentDate.date),
      data.installAccount.instlFirstName
        ? `${data.installAccount.instlFirstName} ${data.installAccount.instlLastName}`
        : 'N/A',
      data.loan.loanCollateral.collatMake
        ? `${data.loan.loanCollateral.collatMake} ${data.loan.loanCollateral.collatModel}`
        : 'N/A',
      data.pmtAmt ? `$${getPaymentAmount(data).toFixed(2)}` : 'N/A',
      <TableActions detailsPathId={data.installLoanId} data={data} />,
    );
    return array;
  });

  return <BasicTable rows={rows} columns={columns} />;
}
