import { Box } from '@material-ui/core';
import React from 'react';

export default function FlexColumn({ children, ...rest }) {
  return (
    <Box display="flex" flexDirection="column" {...rest}>
      {children}
    </Box>
  );
}
