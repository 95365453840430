import { webAPI } from 'shared/utils/http';

export const login = async (payload) => {
  const response = await webAPI.post('users/login', payload);
  return response.data;
};

export const forgotPassword = async (payload) => {
  const response = await webAPI.post('users/forgot-password', payload);
  return response.data;
};
export const resetPassword = async (payload) => {
  const response = await webAPI.post('users/reset-password', payload);
  return response.data;
};
