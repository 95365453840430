import React from 'react';
import { Box, Avatar, makeStyles } from '@material-ui/core';
import Text from 'components/Text/Text';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: '40px',
    width: '40px',
    border: `1px solid ${theme.palette.primary[500]}`,
  },
}));

export default function LoanDetailsBorrower({ avatar, name }) {
  const classes = useStyles();
  return (
    <Box marginBottom="1.5rem" display="flex" alignItems="center">
      <Box marginRight="0.6875rem">
        <Avatar className={classes.avatar} src={avatar} />
      </Box>
      <Box>
        <Text fontSize="1rem" color="#222F3E" fontWeight={500} letterSpacing="-0.16px">
          {name}
        </Text>
      </Box>
    </Box>
  );
}
