// eslint-disable-next-line import/prefer-default-export
export const PAYMENT_RULES = {
  ALL_PAYMENTS: 1,
  CARD_PAYMENTS: 2,
  ACH_PAYMENTS: 3,
};
export const BORROWER_MESSAGING_RULES_TABS = {
  DISABLE_MESSAGING: 7,
};
export const SCHEDULED_QPTP_RULES_TABS = {
  NOT_ACCEPT: 8,
  NOT_ACCEPT_AFTER_MONTH_END: 9,
};
export const INSTALLATION_RULES_TABS = {
  DISABLE_ACCOUNT_LINK: 10,
  FORCE_USER_TO_CALL_RESTRICT_LOGIN: 11,
};

export const RULES_APPLY_TO = {
  ALL_LOANS: 1,
  SPECIFIC_LOANS: 2,
  GROUPS: 3,
};

export const PLATFORMS_APPLY_ON = {
  WEB: 1,
  MOBILE: 2,
  SMS: 3,
  IVR: 4,
  COLO: 71,
  COLR: 72,
};

export const PLATFORMS_API_KEYS = ['onColO', 'onColR', 'onIvr', 'onMobile', 'onSms', 'onWeb'];

export const MAP_PLATFORMS_API_KEYS_TO_APPLY_ON = {
  onWeb: PLATFORMS_APPLY_ON.WEB,
  onMobile: PLATFORMS_APPLY_ON.MOBILE,
  onSms: PLATFORMS_APPLY_ON.SMS,
  onIvr: PLATFORMS_APPLY_ON.IVR,
  onColO: PLATFORMS_APPLY_ON.COLO,
  onColR: PLATFORMS_APPLY_ON.COLR,
};

export const RULES_FORM_KEYS = [
  'applyTo',
  'startDate',
  'endDate',
  'offHide',
  'clientRuleGroups',
  'clientRuleLoans',
];
export const RULES_APPLY_TO_COMMON_KEYS = ['applyOn', 'applyTo', 'startDate', 'endDate', 'offHide'];
export const RULES_ALL_LOANS_KEYS = [...RULES_APPLY_TO_COMMON_KEYS];
export const RULES_SPECIFIC_LOANS_KEYS = [...RULES_APPLY_TO_COMMON_KEYS, 'loanNumber'];
export const RULES_GROUPS_KEYS = [...RULES_APPLY_TO_COMMON_KEYS, 'allGroup'];
