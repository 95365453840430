import React from 'react';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import { Switch } from 'react-router-dom';

export default function AuthenticatedLayout({ routes }) {
  return (
    <Switch>
      {routes.map(route => (
        <RouteWithSubRoutes key={route.key} {...route} />
      ))}
    </Switch>
  );
}
