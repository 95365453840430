import React from 'react';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';

export default function RemoveAccountSection({
  title,
  description,
  primaryClick,
  primaryText,
  secondaryClick,
  secondaryText,
  isLoading,
}) {
  return (
    <Box marginBottom="2rem">
      <Box marginBottom="1.5rem">
        <Text
          fontWeight={600}
          color="text.primary"
          fontSize="0.875rem"
          letterSpacing="0.025em"
          marginBottom="0.25rem"
        >
          {title}
        </Text>
        <Text
          fontWeight={500}
          color="text.secondary"
          fontSize="0.875rem"
          letterSpacing="0.025em"
          marginBottom="0.25rem"
          lineHeight="0.875rem"
        >
          {description}
        </Text>
      </Box>
      {primaryText && (
        <Box margin="0 auto" maxWidth="10rem" marginBottom="1.25rem">
          <ContainedButton
            isLoading={isLoading}
            onClick={primaryClick}
            fullWidth
            size="sm"
            variant="contained"
          >
            {primaryText}
          </ContainedButton>
        </Box>
      )}
      {secondaryText && (
        <Box margin="0 auto" maxWidth="10rem">
          <ContainedButton onClick={secondaryClick} fullWidth size="sm" variant="outlined">
            {secondaryText}
          </ContainedButton>
        </Box>
      )}
    </Box>
  );
}
