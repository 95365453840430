import React from 'react';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 'auto',
    padding: '0.5rem 0.75rem',
    textTransform: 'capitalize',
    fontWeight: 500,
    borderRadius: ({ round }) => (round ? '999999px' : '8px'),
    fontSize: '0.75rem',
    letterSpacing: '0.54px',
    lineHeight: 1,
    backgroundColor: ({ active }) => active && theme.palette.primary[200],
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
    '&.MuiButton-outlinedPrimary': {
      border: `1px solid ${theme.palette.primary[500]}`,
    },
  },
}));

export default function ButtonBase({
  className,
  loading,
  children,
  fullWidth,
  round,
  active,
  ...rest
}) {
  const classes = useStyles({ fullWidth, round, active });
  return (
    <Button className={`${classes.button} ${className}`} {...rest}>
      {loading ? <CircularProgress size={18} /> : children}
    </Button>
  );
}
