import React from 'react';
import { Tabs, makeStyles } from '@material-ui/core';
import { useActiveTab } from './BasicTabsProvider/BasicTabsProvider';

const useTabsStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    boxShadow: 'inset 0 -1px 0 0 #E6ECF0',
  },
  indicator: {
    transform: 'matrix(1, 0, 0, -1, 0, 0)',
    backgroundColor: palette.primary[500],
    height: 0,
  },
}));

const BasicTabs = ({ children }) => {
  const { activeTab, setActiveTab } = useActiveTab();
  const tabsClasses = useTabsStyles();
  return (
    <Tabs variant="fullWidth" classes={tabsClasses} value={false}>
      {children}
    </Tabs>
  );
};

export default BasicTabs;
