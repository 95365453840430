import React from 'react';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import Icon from 'components/Icon/Icon';
import useDisclosure from 'shared/hooks/useDisclosure';
import AddNewGroupModal from 'components/AddNewGroupModal/AddNewGroupModal';

export default function AddNewGroup() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <ContainedButton onClick={onOpen} size="md" startIcon={<Icon name="add" />}>
        New Group
      </ContainedButton>
      <AddNewGroupModal isOpen={isOpen} onClose={onClose} onSuccess={onClose} />
    </>
  );
}
