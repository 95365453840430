import React from 'react';

export default function TuitionIcon({ width, height }) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <g data-name="08-money">
        <g data-name="linear color">
          <path d="M380 169.906v-69.151l58.343-29.01a12 12 0 0 0-2.2-22.327l-177-48a12.017 12.017 0 0 0-6.315.009L79.244 49.038A11.991 11.991 0 0 0 68 61v60h-4a12 12 0 0 0 0 24h4v28a12 12 0 0 0 24 0v-28h4a12 12 0 0 0 0-24h-4V79.963l40 20.113v69.839a190.341 190.341 0 0 0-44.418 57.113A186.655 186.655 0 0 0 67.4 312c0 104 84.6 188.6 188.6 188.6S444.6 416 444.6 312A188.14 188.14 0 0 0 380 169.906zM156 154.6v-54.009c58.42-26.2 141.579-26.2 200 0V154.6c-29.43-11.417-64.229-17.6-100-17.6s-70.57 6.183-100 17.6zM256.018 25.438l143.361 38.878-31.159 15.493C336.447 65.081 296.866 57 256 57c-40.571 0-79.88 7.957-111.535 22.481L114.3 64.311zM256 476.6c-90.761 0-164.6-73.839-164.6-164.6-.108-52.418 24.352-100.3 66-132 57.665-25.291 138.693-25.386 196.535-.286A165.243 165.243 0 0 1 420.6 312c0 90.761-73.839 164.6-164.6 164.6z" />
          <path d="M244 244h37a19.021 19.021 0 0 1 19 19 12 12 0 0 0 24 0 43.049 43.049 0 0 0-43-43h-13v-20a12 12 0 0 0-24 0v20a56 56 0 0 0 0 112h24a32 32 0 0 1 0 64h-36a20.023 20.023 0 0 1-20-20 12 12 0 0 0-24 0 44.049 44.049 0 0 0 44 44h12v12a12 12 0 0 0 24 0v-12a56 56 0 0 0 0-112h-24a32 32 0 0 1 0-64z" />
        </g>
      </g>
    </svg>
  );
}
