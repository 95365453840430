import React from 'react';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';
import LockIcon from '@material-ui/icons/Lock';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import { Box, Grid } from '@material-ui/core';
import { useBorrowerDetails } from 'services/loans/hooks';
import { useStyles } from 'containers/borrowers/borrowerStyles';
import useDisclosure from 'shared/hooks/useDisclosure';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import FlexRow from 'components/FlexRow/FlexRow';
import LoanDetailsRow from '../LoanDetailsCommon/LoanDetailsRow/LoanDetailsRow';
import LoanDetailsMobile from './LoanDetailsMobile/LoanDetailsMobile';
import LoanDetailsEmail from './LoanDetailsEmail/LoanDetailsEmail';
import ResetPassword from '../ResetPassword/ResetPassword';
import RemoveAccount from '../RemoveAccount/RemoveAccount';

export default function DetailsUserInfo(props) {
  const classes = useStyles();
  const { data: borrowerDetails } = useBorrowerDetails();
  const userName = borrowerDetails.linkAttempt.installAccount
    ? `${borrowerDetails.linkAttempt.installAccount.instlFirstName} ${borrowerDetails.linkAttempt.installAccount.instlLastName}`
    : 'N/A';
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isRemoveAccountOpen,
    onClose: onRemoveAccountClose,
    onOpen: onRemoveAccountOpen,
  } = useDisclosure();

  return (
    <div className="col-md-6 col-lg-4 mt-2">
      {borrowerDetails.loan && (
        <>
          <BasicCardWrapper padding="1.5rem 1.125rem 0.5rem">
            <Box marginBottom="1rem">
              <FlexRow position="relative">
                <Box fontSize="0.75rem" letterSpacing="-0.12px" color="#222F3E">
                  Profile Account
                </Box>
              </FlexRow>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" height="2.25rem">
              <LoanDetailsRow
                attribute="Name"
                value={
                  borrowerDetails.linkAttempt.installAccount
                    ? `${borrowerDetails.linkAttempt.installAccount.instlFirstName} ${borrowerDetails.linkAttempt.installAccount.instlLastName}`
                    : 'N/A'
                }
              />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" height="2.25rem">
              <LoanDetailsEmail />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" height="2.25rem">
              <LoanDetailsMobile />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" height="2.25rem">
              <LoanDetailsRow
                attribute="Address"
                value={
                  borrowerDetails.linkAttempt.installAccount.installAddresses.Adress
                    ? borrowerDetails.linkAttempt.installAccount.installAddresses.Adress
                    : 'N/A'
                }
              />
            </Box>
          </BasicCardWrapper>
          <Box bgcolor="#F5F6F888" padding="1rem 1.125rem">
            <Grid container>
              <AccessControl perform={PERMISSIONS.BORROWERS.CHANGE_PASSWORD}>
                <Grid item xs>
                  <Button className={classes.Button} size="small" onClick={onOpen}>
                    <Box display="flex" alignItems="center">
                      <Box display="flex" marginRight="0.5rem">
                        <LockIcon className={classes.loanDetailsIcons} />
                      </Box>
                      <Box
                        fontSize="0.6875rem"
                        letterSpacing="0.54px"
                        color="#222F3E"
                        fontWeight={500}
                        lineHeight={1}
                      >
                        Change Password
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              </AccessControl>
              <AccessControl perform={PERMISSIONS.BORROWERS.UNLINK_ACCOUNT}>
                <Grid item xs>
                  <Button className={classes.Button} size="small" onClick={onRemoveAccountOpen}>
                    <Box display="flex" marginRight="0.5rem">
                      <BlockIcon className={classes.loanDetailsIcons} />
                    </Box>
                    <Box
                      fontSize="0.6875rem"
                      letterSpacing="0.54px"
                      color="#222F3E"
                      fontWeight={500}
                      lineHeight={1}
                    >
                      Unlink Account
                    </Box>
                  </Button>
                </Grid>
              </AccessControl>
            </Grid>
            <ResetPassword name={userName} isOpen={isOpen} onClose={onClose} />
            <RemoveAccount
              name={userName}
              isOpen={isRemoveAccountOpen}
              onClose={onRemoveAccountClose}
            />
          </Box>
        </>
      )}
    </div>
  );
}
