import React from 'react';
import { INSTALLATION_RULES_TABS } from 'shared/constants/rules';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import RulesCommonLayout from '../RulesCommonLayout/RulesCommonLayout';

const INSTALLATION_TABS = [
  {
    index: INSTALLATION_RULES_TABS.DISABLE_ACCOUNT_LINK,
    title: 'Disable account link',
  },
  {
    index: INSTALLATION_RULES_TABS.FORCE_USER_TO_CALL_RESTRICT_LOGIN,
    title: 'Force User to Call restrict login',
  },
];

const PLATFORMS = [
  {
    value: 1,
    icon: 'web',
    name: 'Web',
  },
  {
    value: 2,
    icon: 'mobile',
    name: 'Mobile',
  },
];

export default function InstallationRules() {
  return (
    <BasicsTabsContextProvider initialActiveTab={INSTALLATION_TABS[0].index}>
      <RulesCommonLayout
        platforms={PLATFORMS}
        tabs={INSTALLATION_TABS}
        icon="installation"
        title="Installation Rules"
      />
    </BasicsTabsContextProvider>
  );
}
