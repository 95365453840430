import React from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import { Box } from '@material-ui/core';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import PaymentsInformation from 'pages/Borrowers/LoanDetails/PaymentsInformation/PaymentsInformation';
import Chat from 'pages/Borrowers/LoanDetails/Chat/Chat';
import { useClientDetailsContext } from 'services/client/context';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { BOOLEAN_NUMBERS_STRING } from 'shared/constants/common';

export default function ReceiptsView() {
  const { hideChat, enablePms } = useClientDetailsContext();
  return (
    <FlexColumn width="100%">
      {!hideChat && enablePms === BOOLEAN_NUMBERS_STRING.TRUE && (
        <AccessControl perform={PERMISSIONS.CHAT.USE}>
          <BasicCard noPadding marginBottom="2rem">
            <BasicCardContent>
              <Box width="100%" height="30rem">
                <Chat />
              </Box>
            </BasicCardContent>
          </BasicCard>
        </AccessControl>
      )}
      <BasicCard noPadding>
        <BasicCardContent>
          <Box width="100%">
            <PaymentsInformation />
          </Box>
        </BasicCardContent>
      </BasicCard>
    </FlexColumn>
  );
}
