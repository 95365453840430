import { usePagination } from 'components/Pagination/PaginationProvider/PaginationProvider';
import { usePaginatedQuery, useMutation, useQuery, useQueryCache } from 'react-query';
import { useSearchBar } from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import produce from 'immer';
import { useGroupsActiveTab } from 'pages/Groups/GroupsTab/GroupsTabActive/GroupsTabActiveProvider/GroupsTabActiveProvider';
import {
  getGroups,
  createGroup,
  deleteGroup,
  getGroupDetail,
  updateGroup,
  getLoanStatusCode,
  getGroupDetailLoanList,
  updateGroupStaticLoan,
} from './api';

export function useGroups() {
  const { page } = usePagination();
  const { searchString } = useSearchBar();
  return usePaginatedQuery(['groups', { page, searchString }], getGroups, {
    staleTime: 0,
  });
}
export function useGroupDetail(groupId, options = {}) {
  return useQuery(['group-detail', { groupId }], getGroupDetail, {
    staleTime: 0,
    enabled: groupId,
    ...options,
  });
}

export function useGroupDetailLoanList() {
  const { groupsTabActive } = useGroupsActiveTab();
  return usePaginatedQuery(
    ['group-loan-list', { groupId: groupsTabActive }],
    getGroupDetailLoanList,
    {
      staleTime: 0,
      enabled: groupsTabActive,
    },
  );
}

export const useLoanStatusCode = () => {
  return useQuery(['loan-status-code'], getLoanStatusCode, {
    staleTime: 0,
  });
};

export const useCreateGroup = (options = {}) => {
  return useMutation(createGroup, {
    ...options,
  });
};

export const useDeleteGroup = (options = {}) => {
  const { setAlertMessage } = useAlertContext();
  const { page } = usePagination();
  const { searchString } = useSearchBar();
  const queryCache = useQueryCache();
  return useMutation(deleteGroup, {
    ...options,
    onMutate: ({ clientGroupRuleId }) => {
      const previousItems = queryCache.getQueryData(['groups', { page, searchString }]);
      queryCache.setQueryData(['groups', { page, searchString }], (old) => {
        const updatedGroups = produce(old, (draft) => {
          draft.result.data = draft.result.data.filter((prevGroup) => {
            return prevGroup.id !== clientGroupRuleId;
          });
        });
        return updatedGroups;
      });
      setAlertMessage('Group deleted successfully!');
      if (options.onMutate) {
        options.onMutate();
      }
      return () => queryCache.setQueryData(['groups', { page, searchString }], previousItems);
    },
    onError: (err, groupRemoved, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('groups');
    },
  });
};
export const useEditGroup = (options = {}) => {
  return useMutation(updateGroup, {
    ...options,
    // onMutate: ({ clientGroupRuleId }) => {
    //   const previousItems = queryCache.getQueryData(['groups', { page, searchString }]);
    //   queryCache.setQueryData(['groups', { page, searchString }], old => {
    //     const updatedGroups = produce(old, draft => {
    //       draft.result.data = draft.result.data.filter(prevGroup => {
    //         return prevGroup.id !== clientGroupRuleId;
    //       });
    //     });
    //     return updatedGroups;
    //   });
    //   setAlertMessage('Group deleted successfully!');
    //   if (options.onMutate) {
    //     options.onMutate();
    //   }
    //   return () => queryCache.setQueryData(['groups', { page, searchString }], previousItems);
    // },
    // onError: (err, groupRemoved, rollback) => rollback(),
    // onSettled: () => {
    //   queryCache.invalidateQueries('groups');
    // },
  });
};
export const useUpdateGroupStaticLoan = (options = {}) => {
  return useMutation(updateGroupStaticLoan, {
    ...options,
  });
};
