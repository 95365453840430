const PERMISSIONS = {
  RULES: {
    VISIT: 'rules:visit',
  },
  CHAT: {
    USE: 'chat:use',
    READ_ONLY: 'chat:read_only',
  },
  PAYMENTS: {
    MAKE: 'payments:make',
    SCHEDULE: 'payments:schedule',
    SCHEDULE_LIST: 'payments:schedule_list',
    READ_HISTORY: 'payments:read_history',
    EDIT_SCHEDULES: 'payments:edit_schedules',
    DELETE_SCHEDULES: 'payments:delete_schedules',
    REFUND_CARD: 'payments:refund_card',
    VOID_ACH: 'payments:void_ach',
  },
  BORROWERS: {
    LIST: 'borrowers:list',
    EXPORT_LIST: 'borrowers:export_list',
    DETAILS: 'borrowers:details',
    IMPERSONATE: 'borrowers:impersonate',
    UNLINK_ACCOUNT: 'borrowers:unlink_account',
    CHANGE_PASSWORD: 'borrowers:change_password',
    EDIT_INFORMATION: 'borrowers:edit_information',
  },
  GROUPS: {
    VISIT: 'groups:visit',
  },
  SETTINGS: {
    VISIT: 'settings:visit',
  },
};

export default PERMISSIONS;
