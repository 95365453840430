export const PLATFORMS_IDS = {
  IOS: 1,
  ANDROID: 2,
  WEB: 3,
  SMS: 4,
  IVR: 5,
  CAP: 6,
  NLS: 7,
  CAP_COPY: 8,
};

export const PLATFORMS = {
  [PLATFORMS_IDS.IOS]: 'IOS',
  [PLATFORMS_IDS.ANDROID]: 'ANDROID',
  [PLATFORMS_IDS.WEB]: 'WEB',
  [PLATFORMS_IDS.SMS]: 'SMS',
  [PLATFORMS_IDS.IVR]: 'IVR',
  [PLATFORMS_IDS.NLS]: 'NLS',
  [PLATFORMS_IDS.CAP]: 'CAP',
  [PLATFORMS_IDS.CAP_COPY]: 'CAP',
};
