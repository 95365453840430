import { createStyles, makeStyles } from '@material-ui/core/styles';
import { COLOR } from '../../constants';

const drawerWidth = '100%';

export const button = {
  textTransform: 'capitalize',
  color: COLOR.GREENBUTTON,
  fontWeight: 'bold',
  width: '100%',
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    /* MAIN NAV STYLES */
    appbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.navbar,
      height: '50px',
    },
    MainNavDesktop: {
      display: 'none',
      flex: 4,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    MainNavMenuButton: {
      fontSize: '0.875rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      color: theme.palette.text.light,
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      letterSpacing: '0.14px',
      '&:hover': {
        textDecoration: 'none',
        color: 'white',
      },
    },
    MainNavLogout: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    MainNavTitle: {
      flexGrow: 1,
      marginRight: 65,
      lineHeight: 1,
    },
    MainNavIcons: {
      marginRight: theme.spacing(1),
      color: 'white',
    },
    MainNavDrawer: {
      width: drawerWidth,
      flexShrink: 0,
      marginTop: '5%',
      position: 'absolute',
    },
    MainNavDrawerPaper: {
      width: drawerWidth,
      marginTop: '3rem',
      backgroundColor: '#1A1A20',
      color: 'white',
    },
    MainNavMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    /* SEC NAV STYLES */
    SecMenuRoot: {
      flexGrow: 1,
      marginBottom: '2rem',
      '& .MuiToolbar-root': {
        width: 'auto',
        [theme.breakpoints.up('md')]: {
          minWidth: '60%',
          '& div': {
            width: '100%',
          },
        },
      },
    },
    SecMenuButton: {
      marginRight: 'auto',
      marginLeft: 'auto',
      padding: '0.5rem 1.5rem',
      textTransform: 'capitalize',
      alignItems: 'center',
      fontSize: '0.75rem',
      textDecoration: 'none',
      color: theme.palette.common.secNavbarText,
      '& :visited': {
        color: COLOR.LIGTHGRAY,
      },
    },
    SecAppbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      color: 'black',
      boxShadow: '0px 4px 8px rgba(34, 47, 62, 0.04)',
      border: `1px solid ${theme.palette.common.borderLight}`,
      '& .MuiToolbar-regular': {
        maxWidth: '48.75rem',
        width: '100%',
        minHeight: 'auto',
        height: '3.25rem',
        overflowX: 'scroll',
        'scrollbar-width': 'none',
        '-ms-overflow-style': 'none',
        justifyContent: ({ justifyContent }) => justifyContent || 'center',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      },
    },
    secBadgeRoot: {
      fontWeight: 500,
      color: theme.palette.common.secNavbarText,
    },
    SecCustomBadge: {
      borderRadius: '3px',
      backgroundColor: theme.palette.common.secNavbarText,
      color: theme.palette.common.backgroundCardHighlight,
      top: '-3px !important',
      right: '-12px !important',
      fontSize: '0.5rem',
      fontWeight: 500,
      letterSpacing: '0.08px',
      height: '0.75rem',
      padding: '2px 4px 4px',
    },
    verticalDiv: {
      alignSelf: 'baseline',
    },
    SecGrid: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      '& .MuiGrid-root': {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    /* LOGIN FORM STYLES */

    /** DETAILS TABLE STYLES */
    Troot: {
      width: '100%',
    },
    Tcontainer: {
      maxHeight: 640,
    },
    /** DETAILS CARDS STYLE */
    detRoot: {
      minWidth: '20rem',
      boxShadow: '1rem 1rem 1rem rgba(0,0,0,.175)',
      borderRadius: '0.5rem',
    },
    media: {
      height: '10rem',
      backgroundSize: 'contain',
      border: `1px solid ${COLOR.THINBORDER}`,
      borderRadius: '0.5rem',
    },
    detTitle: {
      fontSize: '0.9rem ',
      fontWeight: 'bold',
    },
    shadow: {
      textTransform: 'capitalize',
      color: 'gray',
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
    thumbnail: {
      borderRadius: '5rem',
      width: '3rem',
      marginTop: '-8rem',
      border: `0.2rem solid ${COLOR.ORANGE}`,
    },
    button_danger: {
      backgroundColor: 'transparent !important',
      color: `${COLOR.GREENBUTTON} !important`,
      width: '100%',
      fontWeight: 'bold !important',
      textTransform: 'capitalize !important',
      ' &:hover': {
        color: '#941515 !important',
      },
    },
    cardIcons: {
      color: `${COLOR.GREENBUTTON} !important`,
      fontWeight: 'bold !important',
    },
  }),
);
