import React from 'react';
import { BasicDateField } from 'components/BasicInputs/BasicDateField/BasicDateField';
import PaymentDetailsItem from '../PaymentDetails/PaymentDetailsContent/PaymentDetailsItem/PaymentDetailsItem';

export default function PaymentDate({ disabled, label = 'Payment Date', ...rest }) {
  return (
    <PaymentDetailsItem
      label={label}
      input={<BasicDateField noShadow disabled={disabled} name="paymentDate" {...rest} />}
    />
  );
}
