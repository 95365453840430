import { Box, makeStyles } from '@material-ui/core';
import { BasicDateField } from 'components/BasicInputs/BasicDateField/BasicDateField';
import React from 'react';
import { dateWithSlashes } from 'shared/utils';

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    zIndex: 1,
    position: 'absolute',
    left: '-0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.common.borderInput,
    height: '1.2rem',
    width: '1.5rem',
  },
}));

export default function DateColumnHeader({ column }) {
  const classes = useStyles();
  return (
    <Box marginTop="0.375rem" paddingRight="0.5rem" paddingBottom="0.625rem">
      <BasicDateField
        noKeyboard
        withDateIcon
        maxDate={dateWithSlashes()}
        size="xs"
        name={column.id}
      />
    </Box>
  );
}
