import React from 'react';
import { Box } from '@material-ui/core';
import AddNewGroup from './AddNewGroup/AddNewGroup';

export default function GroupsToolbar() {
  return (
    <Box display="flex" alignItems="center" marginBottom="1.5rem">
      <Box marginRight="3.5rem">
        <AddNewGroup />
      </Box>
    </Box>
  );
}
