import React, { useMemo } from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardSecondaryContent from 'components/BasicCard/BasicCardContent/BasicCardSecondaryContent/BasicCardSecondaryContent';
import BasicCardCommon from 'components/BasicCard/BasicCardCommon/BasicCardCommon';
import BasicCardHighlight from 'components/BasicCard/BasicCardHighlight/BasicCardHighlight';
import BasicCardActions from 'components/BasicCard/BasicCardActions/BasicCardActions';
import CommonTilesActions from 'components/BasicCard/BasicCardActions/CommonTilesActions/CommonTilesActions';
import { handleLogin } from 'commons';
import { dateWithSlashes } from 'shared/utils';

// eslint-disable-next-line import/prefer-default-export
export function OnAppCard({ data }) {
  const handleOnLoginClick = item => {
    handleLogin(item.linkAttempts[0]?.installAccountId, item.linkAttempts[0]?.attemptLoanNo);
  };

  const detailsPathId = useMemo(() => {
    return data.linkAttempts.find(elem => !!elem.installLoan?.id && !elem.deletedAt)
  }, [data])

  return (
    <BasicCard>
      <BasicCardContent>
        <BasicCardTitle>
          {`Account ${
            detailsPathId?.attemptLoanNo ? detailsPathId?.attemptLoanNo : 'N/A'
          }`}
        </BasicCardTitle>
        <BasicCardSecondaryContent>
          <BasicCardCommon
            attribute="User"
            value={`${data.instlFirstName || '- -'} ${
              data.instlLastName ? `${data.instlLastName}` : ''
            }`}
          />
          <BasicCardCommon
            attribute="Last Seen On"
            value={dateWithSlashes(data.lastOnline?.date)}
          />
        </BasicCardSecondaryContent>
        <BasicCardHighlight
          attribute="Zip Code"
          value={detailsPathId?.attemptZipCd ? detailsPathId?.attemptZipCd : 'N/A'}
        />
      </BasicCardContent>
      <BasicCardActions>
        <CommonTilesActions onLoginClick={() => handleOnLoginClick(data)} data={data} detailsPathId={detailsPathId?.installLoan?.id} />
      </BasicCardActions>
    </BasicCard>
  );
}
