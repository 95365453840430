import React from 'react';

export default function TaxIcon({ width, height }) {
  return (
    <svg
      fill="currentColor"
      height={height || 64}
      viewBox="0 0 64 64"
      width={width || 64}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M53 43h-2v-2h-2v2h-2c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h6v4h-6v-2h-2v2c0 1.103.897 2 2 2h2v2h2v-2h2c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2h-6v-4h6v2h2v-2c0-1.103-.897-2-2-2z" />
      <path d="M53 37.363V10c0-1.654-1.346-3-3-3h-7V3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v4h-4V3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v4h-4V3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v4H4c-1.654 0-3 1.346-3 3v44c0 2.757 2.243 5 5 5h34.641c2.367 2.46 5.684 4 9.359 4 7.168 0 13-5.832 13-13 0-6.134-4.276-11.277-10-12.637zM37 3h4l.001 10H37zM25 3h4l.001 10H25zM13 3h4l.001 10H13zM4 9h7v4c0 1.103.897 2 2 2h4c1.103 0 2-.897 2-2V9h4v4c0 1.103.897 2 2 2h4c1.103 0 2-.897 2-2V9h4v4c0 1.103.897 2 2 2h4c1.103 0 2-.897 2-2V9h7c.552 0 1 .449 1 1v9H3v-9c0-.551.448-1 1-1zm2 48c-1.654 0-3-1.346-3-3V21h48v16.051c-.331-.026-.662-.051-1-.051s-.669.025-1 .051V25c0-1.103-.897-2-2-2H7c-1.103 0-2 .897-2 2v28c0 1.103.897 2 2 2h31.003c.293.702.654 1.367 1.06 2zm34.153-15.463L38.25 39 41 35.333V33h-2v1.667l-2 2.667-2-2.667V33h-2v2.333L35.75 39 33 42.667V45h2v-1.667l2-2.667 1.923 2.564A12.898 12.898 0 0 0 37 50c0 1.034.134 2.035.363 3H7V25h40v12.363a13.004 13.004 0 0 0-6.847 4.174zM50 61c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11z" />
      <path d="M25.219 33l-2.188 8.757L23 45h2v-2h4v2h2v-3l-2.219-9zm2 2l1.5 6h-3.438l1.5-6zM23 35v-2H13v2h4v10h2V35z" />
    </svg>
  );
}
