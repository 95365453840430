import React from 'react';

export default function VisaIcon({ width, height }) {
  return (
    <svg
      width={width || 54}
      height={height || 34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 54 34"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 31.112A2.883 2.883 0 0 0 2.88 34h48.24c1.59 0 2.88-1.293 2.88-2.888V2.888A2.883 2.883 0 0 0 51.12 0H2.88A2.884 2.884 0 0 0 0 2.888v28.224z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.354 24.202l2.228-13.56h3.563l-2.23 13.56h-3.561zM36.727 11.193c-.764-.266-1.962-.552-3.46-.552-3.813 0-6.5 1.782-6.523 4.336-.021 1.888 1.919 2.94 3.383 3.57 1.502.643 2.007 1.054 2 1.629-.01.88-1.2 1.283-2.31 1.283-1.544 0-2.365-.2-3.633-.69l-.497-.209-.542 2.941c.902.367 2.57.685 4.3.701 4.057 0 6.691-1.761 6.721-4.488.015-1.495-1.014-2.632-3.24-3.57-1.35-.607-2.176-1.013-2.167-1.628 0-.547.7-1.13 2.21-1.13a7.6 7.6 0 0 1 2.89.503l.345.151.523-2.847zM45.264 10.641h-2.908c-.9 0-1.575.242-1.97 1.126l-5.59 12.435h3.952s.647-1.672.793-2.039l4.819.006c.112.475.458 2.033.458 2.033h3.491l-3.045-13.56zm-4.64 8.746l1.499-3.793c-.023.036.309-.785.499-1.295l.254 1.17.871 3.918h-3.123zM15.876 10.641l-3.214 9.263-.343-1.883c-.598-2.17-2.462-4.52-4.547-5.697l2.94 11.878 3.473-.004 5.17-13.557h-3.479z"
        fill="#004F8B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.439 10.641H2.032l-.051.223c4.985.884 8.283 3.021 9.652 5.59l-1.393-4.91c-.241-.677-.939-.88-1.801-.903z"
        fill="#ED9F2D"
      />
    </svg>
  );
}
